/* eslint-disable no-underscore-dangle */
import axios from 'axios';

const env = n => (window.env || {})[n] || process.env[n];

const API_BASE = env('REACT_APP_API_BASE');
const API_USERNAME = env('REACT_APP_API_USERNAME');
const API_PASSWORD = env('REACT_APP_API_PASSWORD');

const customAxios = axios.create({
  baseURL: API_BASE,
});

let renewTokenInProcess = false;

const refreshAccessToken = async refreshToken => axios({
  method: 'post',
  url: `${API_BASE}/renew/`,
  data: { refresh_token: refreshToken },
  auth: { username: API_USERNAME, password: API_PASSWORD },
}).then(response => {
  localStorage.setItem('token', response.data.access_token);
  localStorage.setItem('refresh_token', response.data.refresh_token);
  renewTokenInProcess = false;
}).catch(error => {
  console.log(error);
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  renewTokenInProcess = false;
});

customAxios.interceptors.response.use(response => response, async error => {
  const originalRequest = error.config;
  if (error?.response?.status === 401 && !originalRequest._retry && renewTokenInProcess === false) {
    renewTokenInProcess = true;
    originalRequest._retry = true;
    const refreshToken = localStorage.getItem('refresh_token');
    const accessToken = await refreshAccessToken(refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken.response.data.access_token}`;
    return customAxios(originalRequest);
  }
  return Promise.reject(error);
});

export default customAxios;
