import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import NavItem from './NavItem';

const CustomBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: theme.palette.primary.main,
    top: '-10px',
    right: '-5px',
    fontSize: 11,
  },
}));

function ReturnsNavItem(props) {
  const {
    width,
    iconwidth,
    iconheight,
    marginright,
    boxshadow,
    colorhover,
    backgroundcolor,
    backgroundcolorhover,
    badgeContent,
  } = props;
  const { t } = useTranslation();
  return (
    <NavItem
      aria-label="returns"
      to="/returns"
      width={width}
      iconwidth={iconwidth}
      iconheight={iconheight}
      marginright={marginright}
      boxshadow={boxshadow}
      colorhover={colorhover}
      backgroundcolor={backgroundcolor}
      backgroundcolorhover={backgroundcolorhover}
      title={t('Returns')}
    >
      <CustomBadge badgeContent={badgeContent} color="secondary" max={badgeContent}>
        <ContentPasteGoIcon color="blue" />
      </CustomBadge>
    </NavItem>
  );
}

ReturnsNavItem.propTypes = {
  width: PropTypes.number,
  iconwidth: PropTypes.number,
  iconheight: PropTypes.number,
  boxshadow: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  colorhover: PropTypes.string,
  backgroundcolor: PropTypes.string,
  badgeContent: PropTypes.number,
  marginright: PropTypes.number,
};

ReturnsNavItem.defaultProps = {
  width: 0,
  iconwidth: 16,
  iconheight: 16,
  backgroundcolor: '',
  backgroundcolorhover: '#fff',
  colorhover: '',
  boxshadow: '',
  marginright: 10,
};

export default ReturnsNavItem;
