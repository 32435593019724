import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const deleteReminder = async reminders => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'POST',
    url: '/reminders/delete/',
    data: reminders,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useDeleteReminder = () => useMutation(reminders => deleteReminder(reminders));

export default useDeleteReminder;
