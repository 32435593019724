import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useAddSupportMessage from '../Hooks/support/useAddSupportMessage';
import UsersForm from '../Components/Forms/UsersForm';

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.blue.main,
  fontWeight: 600,
  clear: 'both',
}));

function Support() {
  const { t } = useTranslation();
  const { mutate: addSupportMessage, isLoading } = useAddSupportMessage();
  const [title, setTitle] = useState('');
  const [messageField, setMessageField] = useState('');
  const [isSent, setIsSent] = useState(false);
  const successMessage = t('Support message');
  const [disabledNotifyUserButton, setDisabledNotifyUserButton] = useState(true);
  const [maxMessageLength, setMaxMessageLength] = useState(false);
  const [maxTitleLength, setMaxTitleLength] = useState(false);
  const handleChange = event => {
    event.preventDefault();
    return addSupportMessage(({ title, message: messageField }), {
      onSuccess: async () => {
        setIsSent(true);
      },
      onError: async () => {
        setIsSent(false);
      },
    });
  };
  useEffect(() => {
    if (title && messageField) {
      setDisabledNotifyUserButton(false);
    } else {
      setDisabledNotifyUserButton(true);
    }
  }, [maxMessageLength, title, messageField, maxTitleLength]);
  return (
    <Grid item xs={12} md={10} lg={5.5}>
      <CustomTypography variant="h6" color="blue">{t('Help and support')}</CustomTypography>
      <UsersForm
        setTitle={setTitle}
        title={title}
        messagefield={messageField}
        setMessage={setMessageField}
        handleChange={handleChange}
        isSent={isSent}
        successMessage={successMessage}
        disabledNotifyUserButton={disabledNotifyUserButton}
        maxMessageLength={maxMessageLength}
        setMaxMessageLength={setMaxMessageLength}
        maxTitleLength={maxTitleLength}
        setMaxTitleLength={setMaxTitleLength}
        isLoading={isLoading}
        showCancelButton={false}
      />
    </Grid>
  );
}

export default Support;
