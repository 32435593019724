import { useMutation } from 'react-query';
import axios from 'axios';

const env = n => (window.env || {})[n] || process.env[n];

const API_BASE = env('REACT_APP_API_BASE');
const API_USERNAME = env('REACT_APP_API_USERNAME');
const API_PASSWORD = env('REACT_APP_API_PASSWORD');

const login = async (username, password) => axios({
  method: 'post',
  url: `${API_BASE}/login/`,
  data: { grant_type: 'password', username, password },
  auth: { username: API_USERNAME, password: API_PASSWORD },
});

const useLogin = () => useMutation(({ username, password }) => login(username, password));

export default useLogin;
