/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const notifyClients = async (title, message, accounts, promotionfile) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'post',
    data: {
      title, message, accounts, description: message, promotionfile,
    },
    url: '/clients/notification',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useNotifyClients = () => useMutation(({
  title, message, accounts, promotionfile,
}) => notifyClients(title, message, accounts, promotionfile));

export default useNotifyClients;
