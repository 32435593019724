import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getUsers = async () => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: '/supplier/users',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetUsers = () => useQuery(['users'], () => getUsers(), { refetchInactive: true });

export default useGetUsers;
