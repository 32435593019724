/* eslint-disable no-useless-escape */
const emailValidation = (email) => {
  let errorMessage = false;
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (email.match(mailformat)) {
    errorMessage = false;
  } else {
    errorMessage = true;
  }
  return errorMessage;
};

export default emailValidation;
