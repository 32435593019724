import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getClients = async param => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: `/clients/all/${param}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetClientsAll = param => useQuery(['clients', param], () => getClients(param), { enabled: true });

export default useGetClientsAll;
