import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

const CustomTextarea = styled.textarea`
    border-radius: ${props => props.borderradius}px;
    background-color: ${props => props.bgcolor};
    padding: ${props => props.padding};
    border: ${props => props.border};
    color: ${props => props.color};
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    font-family: Gilroy, Roboto;
    ::placeholder {
        position: absolute;
        font-size: ${props => props.placeholderfontsize};
        color: ${props => props.placeholdercolor};
        font-weight: ${props => props.placeholderfontweight};
        font-family: Gilroy, Roboto;
        opacity: 1;
    };
    &:focus {
        outline: none;
    }
`;

function MessageTextareaField({
  grid,
  borderradius,
  border,
  padding,
  bgcolor,
  placeholdercolor,
  placeholderfontweight,
  placeholderfontsize,
  fontsize,
  handleChange,
  defaultValue,
  color,
  setMaxMessageLength,
  maxMessageLength,
  characterNumber,
}) {
  const { t } = useTranslation();
  const [textAreaCount, setTextAreaCount] = useState(0);

  const handleMessageChange = value => {
    handleChange(value);
    setTextAreaCount(value.length);
    if (value.length === characterNumber) {
      setMaxMessageLength(true);
    } else {
      setMaxMessageLength(false);
    }
  };

  return (
    <Grid item xs={grid}>
      <CustomTextarea
        aria-label="message"
        rows="15"
        placeholder={t('Message', { number: characterNumber })}
        placeholdercolor={placeholdercolor}
        placeholderfontsize={placeholderfontsize}
        placeholderfontweight={placeholderfontweight}
        borderradius={borderradius}
        border={border}
        bgcolor={bgcolor}
        padding={padding}
        fontSize={fontsize}
        value={defaultValue}
        color={color}
        onChange={e => handleMessageChange(e.target.value)}
        maxLength={characterNumber}
      />
      <Box display="flex" justifyContent={maxMessageLength ? 'space-between' : 'end'}>
        {maxMessageLength
    && (
    <Typography fontSize={14} color="#fbb140" fontWeight={600}>
      {t('Character limit reached')}
    </Typography>
    )}
        <Typography textAlign="right" fontSize={12} color="#4e4e4e">
          {textAreaCount}
          /
          {characterNumber}
        </Typography>
      </Box>
    </Grid>
  );
}

MessageTextareaField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  bgcolor: PropTypes.string,
  border: PropTypes.string,
  placeholderfontweight: PropTypes.number,
  placeholdercolor: PropTypes.string,
  placeholderfontsize: PropTypes.string,
  fontsize: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  color: PropTypes.string,
  setMaxMessageLength: PropTypes.func,
  maxMessageLength: PropTypes.bool,
  characterNumber: PropTypes.number,
};

MessageTextareaField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  bgcolor: '#fff',
  border: '',
  placeholderfontweight: 400,
  placeholdercolor: '#ccc',
  placeholderfontsize: '14px',
  fontsize: 14,
  maxMessageLength: false,
  characterNumber: 1000,
};

export default MessageTextareaField;
