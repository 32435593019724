import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import useChangePassword from '../../../Hooks/settings/useChangePassword';
import PasswordInputField from '../../Forms/InputFields/RegisterFields/PasswordInputField';
import passwordValidation from '../../../Validations/password-validation';
import ModalComponent from '../ModalComponent';
import SupplierChangePasswordButton from './SupplierChangePasswordButton';

const CustomButtonConfirm = styled(Button)(() => ({
  background:
        'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  color: '#fff',
}));

const CustomMessageBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '95%',
  borderRadius: 20,
  backgroundColor: '#f56f4bab',
  border: '2px solid #F56F4B',
  padding: 10,
}));

function SupplierChangePasswordModal() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const { mutate: changePassword } = useChangePassword();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [confirmedPasswordErrorMessage, setConfirmedPasswordErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);

  const handleOpen = () => {
    setOpen(true);
    setOffsetTop('50%');
    setOffsetLeft('50%');
    setIsChanged(false);
  };

  useEffect(() => {
    if (oldPassword && newPassword && newPassword === confirmedPassword) {
      setDisabledSubmitButton(false);
    } else {
      setDisabledSubmitButton(true);
    }
  }, [confirmedPassword, oldPassword, newPassword]);

  const handleClose = () => setOpen(false);

  const handleChange = event => {
    event.preventDefault();
    const passwordError = passwordValidation(newPassword);
    if (!passwordError) {
      return changePassword(({ oldPassword, newPassword }), {
        onSuccess: async () => {
          setIsChanged(true);
          setOldPassword('');
          setNewPassword('');
          setConfirmedPassword('');
          setConfirmedPasswordErrorMessage('');
          setErrorMessage('');
        },
        onError: async response => {
          setIsChanged(false);
          setErrorMessage(response.response.data);
        },
      });
    }
    return setPasswordErrorMessage(t('Invalid password'));
  };

  const confirmPassword = value => {
    setConfirmedPassword(value);
    if (newPassword !== value) {
      setConfirmedPasswordErrorMessage(t('Confirmed password error'));
    } else {
      setConfirmedPasswordErrorMessage('');
    }
  };

  return (
    <>
      <SupplierChangePasswordButton
        handleClick={handleOpen}
        color="#F56F4B"
        margin="0"
        padding="5px 0"
        borderradius={16}
        textsize={10}
        bgcolor="#fff"
        boxshadow="-1px 3px 5px 1px #e5e3e3"
      />
      <ModalComponent
        open={open}
        handleClose={handleClose}
        offsetTop={offsetTop}
        offsetLeft={offsetLeft}
        width={450}
        padding="15px 25px 20px"
        backgroundcolor="#f8f8f8"
        titlemargin="10px 10px 20px"
        color="secondary"
        title={t('Change password')}
        buttonssize={3}
        issuppliermodal
        handleChange={handleChange}
        isChanged={isChanged}
        disabledSubmitButton={disabledSubmitButton}
      >
        {isChanged
          ? (
            <Box sx={{ margin: '30px 0 0', width: '100%' }}>
              <Typography color="secondary" sx={{ margin: '0 0 30px' }}>{t('Password changed')}</Typography>
              <CustomButtonConfirm
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                {t('Confirm')}
              </CustomButtonConfirm>
            </Box>
          )
          : (
            <Grid container spacing={1}>
              {errorMessage
                && (
                <Grid item xs={12}>
                  <CustomMessageBox>
                    <Typography color="primary">
                      {errorMessage}
                    </Typography>
                  </CustomMessageBox>
                </Grid>
                )}
              <PasswordInputField
                name="current-password"
                grid={12}
                placeholder={t('Current password')}
                label={t('Current password')}
                bgcolor="#fff"
                borderradius={20}
                border="1px solid #ded9d9"
                padding="5px 15px"
                placeholdercolor="#9c9c9c"
                handleChange={setOldPassword}
                password={oldPassword}
              />
              <PasswordInputField
                name="new-password"
                grid={6}
                placeholder={t('New password')}
                label={t('New password')}
                bgcolor="#fff"
                borderradius={20}
                border="1px solid #ded9d9"
                padding="5px 15px"
                placeholdercolor="#9c9c9c"
                handleChange={setNewPassword}
                password={newPassword}
                errorMessage={passwordErrorMessage}
              />
              <PasswordInputField
                name="confirm-password"
                grid={6}
                placeholder={t('Confirm password')}
                label={t('Confirm password')}
                bgcolor="#fff"
                borderradius={20}
                border="1px solid #ded9d9"
                padding="5px 15px"
                endAdornment=""
                placeholdercolor="#9c9c9c"
                handleChange={confirmPassword}
                password={confirmedPassword}
                errorMessage={confirmedPasswordErrorMessage}
              />
            </Grid>
          )}
      </ModalComponent>
    </>
  );
}

export default SupplierChangePasswordModal;
