/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const putReturnStatus = async (id, returnStatus, notice) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'put',
    data: { orderstatus: returnStatus, supplierordernote: notice },
    url: `/returns/${id}/`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const usePutReturnStatus = () => useMutation(({ id, returnStatus, notice }) => putReturnStatus(id, returnStatus, notice));

export default usePutReturnStatus;
