/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SelectField from './SelectField';

function CitiesSelectField({
  grid,
  borderradius,
  border,
  padding,
  bgcolor,
  required,
  requiredcolor,
  placeholdercolor,
  data,
  isFetched,
  handleChange,
  defaultValue,
  requiredError,
}) {
  const { t } = useTranslation();
  return (
    <SelectField
      name="city"
      grid={grid}
      required={required}
      type="text"
      placeholder={t('City')}
      label={t('City')}
      borderradius={borderradius}
      border={border}
      padding={padding}
      bgcolor={bgcolor}
      requiredcolor={requiredcolor}
      placeholdercolor={placeholdercolor}
      data={data?.data}
      isFetched={isFetched}
      handleChange={handleChange}
      defaultValue={defaultValue}
      requiredError={requiredError}
    />
  );
}

CitiesSelectField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  bgcolor: PropTypes.string,
  border: PropTypes.string,
  required: PropTypes.bool,
  placeholdercolor: PropTypes.string,
  requiredcolor: PropTypes.string,
  data: PropTypes.shape({}),
  isFetched: PropTypes.bool,
  handleChange: PropTypes.func,
  defaultValue: PropTypes.number,
  requiredError: PropTypes.bool,
};

CitiesSelectField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  bgcolor: '#fff',
  border: '',
  required: false,
  placeholdercolor: '#efefef',
  requiredcolor: '#fff',
  data: {},
  isFetched: false,
  requiredError: false,
};

export default CitiesSelectField;
