/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const addSupportMessage = async (title, message) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'post',
    data: { title, message },
    url: '/support/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useAddSupportMessage = () => useMutation(({ title, message }) => addSupportMessage(title, message));

export default useAddSupportMessage;
