import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';

function TableSkeleton({ height }) {
  return (
    <Table width="100%">
      <tbody>
        <tr>
          <td>
            <Skeleton animation="wave" width="100%" height={height} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton animation="wave" width="100%" height={height} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton animation="wave" width="100%" height={height} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton animation="wave" width="100%" height={height} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

TableSkeleton.propTypes = {
  height: PropTypes.string,
};

TableSkeleton.defaultProps = {
  height: '60px',
};

export default TableSkeleton;
