import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const CustomIconButton = styled(IconButton)((props) => ({
  borderRadius: '100%',
  backgroundColor: props.backgroundcolor,
  padding: 0,
  width: props.width,
  maxWidth: props.width,
  minWidth: props.width,
  height: props.height,
  marginRight: props.marginright,
  boxShadow: props.boxshadow,
  fontSize: props.fontSize,
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: props.backgroundcolorhover,
  },
  '&.active': {
    backgroundColor: props.backgroundcolorhover,
  },
}));

const CustomArrowIcon = styled(ArrowDropUpIcon)((props) => ({
  fill: props.iconcolor,
}));

function UpArrowIconButton({
  active,
  requestSort,
  width,
  height,
  marginright,
  boxshadow,
  backgroundcolor,
  backgroundcolorhover,
  colorhover,
  color,
  fontSize,
}) {
  return (
    <CustomIconButton
      className={`${active === 'ascending' ? 'active' : ''}`}
      onClick={requestSort}
      aria-label="up-arrow"
      width={width}
      height={height}
      marginright={marginright}
      boxshadow={boxshadow}
      fontSize={fontSize}
      backgroundcolor={backgroundcolor}
      backgroundcolorhover={backgroundcolorhover}
      colorhover={colorhover}
    >
      <CustomArrowIcon
        iconcolor={color}
      />
    </CustomIconButton>
  );
}

UpArrowIconButton.propTypes = {
  active: PropTypes.string,
  requestSort: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  marginright: PropTypes.number,
  boxshadow: PropTypes.string,
  backgroundcolor: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  color: PropTypes.string,
  colorhover: PropTypes.string,
  fontSize: PropTypes.number,
};
UpArrowIconButton.defaultProps = {
  active: '',
  width: 45,
  height: 45,
  marginright: 0,
  boxshadow: '',
  backgroundcolor: '#fff',
  backgroundcolorhover: '#fff',
  color: '#fff',
  colorhover: '#fff',
  fontSize: 14,
};

export default UpArrowIconButton;
