import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDeleteNotification from '../../../Hooks/notifications/useDeleteNotification';
import DeleteModal from '../DeleteModal';

function DeleteNotificationModal({ id, refetch }) {
  const { t } = useTranslation();
  const [isDeleted, setIsDeleted] = useState(false);
  const { mutate: deleteNotification } = useDeleteNotification();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setIsDeleted(false);
  };
  const handleClose = () => setOpen(false);

  const handleChange = event => {
    event.preventDefault();
    return deleteNotification((id), {
      onSuccess: async () => {
        setIsDeleted(true);
        refetch();
      },
      onError: async () => {
        setIsDeleted(false);
      },
    });
  };

  return (
    <DeleteModal
      deletemodaltitle={t('Delete notification')}
      deletemodaltext={t('Do you want to delete this notification')}
      handleChange={handleChange}
      isDeleted={isDeleted}
      setIsDeleted={setIsDeleted}
      deletedText={t('Notification deleted')}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
    />
  );
}

DeleteNotificationModal.propTypes = {
  id: PropTypes.number,
  refetch: PropTypes.func,
};

export default DeleteNotificationModal;
