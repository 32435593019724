import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useLogout from '../../Hooks/useLogout';
import ButtonCancel from '../Forms/Buttons/ButtonCancel';
import ButtonConfirm from '../Forms/Buttons/ButtonConfirm';
import LogoutButton from '../Auth/LogoutButton';

const CustomTypography = styled(Typography)(({ theme }) => ({
  margin: 10,
  fontSize: 16,
  fontWeight: 500,
  textAlign: 'left',
  color: theme.palette.secondary.main,
}));

const CustomModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#fff',
  borderRadius: 6,
  boxShadow: 24,
  padding: '25px',
  [theme.breakpoints.down('md')]: {
    width: '70%',
  },
}));

const LogoutModal = ({ setToken }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { mutate: logoutUser } = useLogout();

  const handleLogout = (event) => {
    event.preventDefault();
    const token = localStorage.getItem('refresh_token');
    return logoutUser(({ token }), {
      onSuccess: async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        setToken(false);
      },
      onError: async () => {
      },
    });
  };

  return (
    <Box width="100%">
      <LogoutButton handleOpen={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomModalBox
          component="form"
          noValidate
        >
          <CustomTypography>
            {t('Do you want to logout')}
          </CustomTypography>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: 'flex-end', mt: 1 }}
          >
            <Grid item xs={6} md={3}>
              <ButtonCancel
                text={t('Cancel')}
                color="#747474"
                borderradius={16}
                textsize={12}
                bgcolor="#fff"
                padding="5px 0"
                variant="contained"
                handleClose={handleClose}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <ButtonConfirm
                text={t('Confirm')}
                type="submit"
                color="#fff"
                borderradius={16}
                textsize={12}
                bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
                padding="5px 0"
                variant="contained"
                handleChange={handleLogout}
              />
            </Grid>
          </Grid>
        </CustomModalBox>
      </Modal>
    </Box>
  );
};

LogoutModal.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default LogoutModal;
