import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const deleteNotification = async notifications => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'POST',
    url: '/supplier/notification/delete/',
    data: notifications,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useDeleteNotification = () => useMutation(notifications => deleteNotification(notifications));

export default useDeleteNotification;
