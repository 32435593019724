import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CustomLogoutButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  borderRadius: 20,
  borderColor: theme.palette.secondary.main,
  border: '1px solid',
  textTransform: 'uppercase',
  fontSize: 10,
  fontWeight: 500,
  padding: 4,
  width: '100%',
  '&:hover': {
    background: 'linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
    color: theme.palette.primary.main,
  },
}));

function LogoutButton({ handleOpen }) {
  const { t } = useTranslation();

  return (
    <CustomLogoutButton
      onClick={handleOpen}
    >
      {t('Logout')}
    </CustomLogoutButton>
  );
}
LogoutButton.propTypes = {
  handleOpen: PropTypes.func.isRequired,
};

export default LogoutButton;
