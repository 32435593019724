/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const putReminder = async (id, accountid, companyname, remindername, reminderdate, reminderrepeat) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'put',
    data: {
      accountid, companyname, remindername, reminderdate, reminderrepeat,
    },
    url: `/reminders/${id}/`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const usePutReminder = () => useMutation(({
  id, accountid, companyname, remindername, reminderdate, reminderrepeat,
}) => putReminder(id, accountid, companyname, remindername, reminderdate, reminderrepeat));

export default usePutReminder;
