/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const deleteUserDeliveryAddress = async (deliveryaddressid, supplieruserid) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'delete',
    data: { deliveryaddressid, supplieruserid },
    url: '/supplier/user/delivery/address/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useDeleteUserDeliveryAddress = () => useMutation(({ deliveryaddressid, supplieruserid }) => deleteUserDeliveryAddress(deliveryaddressid, supplieruserid));

export default useDeleteUserDeliveryAddress;
