/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import SearchIconButton from './SearchIconButton';

function ViewClientButton(props) {
  const handleClick = () => {
    window.scrollTo({
      top: props.clientTabsRef?.current?.clientHeight + 100,
      behavior: 'smooth',
    });
    props.setClient(props.clientId);
  };

  return (
    <SearchIconButton color="#3384B9" handleClick={handleClick} />
  );
}

export default ViewClientButton;
