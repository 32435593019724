/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import EditIconButton from './EditIconButton';

const CustomIconButtonContainer = styled.div`
    border-radius: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${props => props.boxshadow};
    border: ${props => props.border};
    background: ${props => props.backgroundcolor};
    width: ${props => props.width};
    max-width: ${props => props.width};
    min-width: ${props => props.width};
    height: ${props => props.width};
    padding: ${props => props.padding};
    position: ${props => props.position};
    @media (max-width: 1199px) {
      position: inherit;
      z-index: 1;
    },
    & svg {
            width: ${props => props.iconsize};
            height: ${props => props.iconsize};
        & path {
            fill: ${props => props.color};
            stroke: none;
        }
    }
    &:hover {
        background: ${props => props.backgroundcolorhover};
        border: ${props => props.borderhover};
        & svg path {
            fill: ${props => props.colorhover};
        }
    }
`;

const StyledEditIconButton = forwardRef((props, ref) => (
  <CustomIconButtonContainer
    boxshadow={props.boxshadow}
    border={props.border}
    backgroundcolor={props.backgroundcolor}
    color={props.color}
    colorhover={props.colorhover}
    backgroundcolorhover={props.backgroundcolorhover}
    borderhover={props.borderhover}
    width={props.width}
    iconsize={props.iconsize}
    padding={props.padding}
    position={props.position}
  >
    <EditIconButton
      ref={ref}
      handleClick={props.handleClick}
    />
  </CustomIconButtonContainer>
));

export default StyledEditIconButton;
