import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const CustomIconButton = styled(IconButton)((props) => ({
  padding: props.padding,
  border: 'none',
  marginLeft: props.marginleft,
  width: props.width,
  marginTop: props.margintop,
  backgroundColor: props.backgroundcolor,
  borderRadius: props.borderradius,
  boxShadow: props.boxshadow,
  '&:hover': {
    backgroundColor: props.backgroundcolor,
  },
  '& svg': {
    width: 24,
    '& path': {
      fill: props.open ? '#3384B9' : '#fff',
    },
  },
}));

function HamburgerIconButton({
  marginleft,
  padding,
  width,
  margintop,
  open,
  boxshadow,
  backgroundcolor,
  toggleDrawer,
  borderradius,
  children,
}) {
  return (
    <CustomIconButton
      aria-label="hamburger"
      marginleft={marginleft}
      padding={padding}
      backgroundcolor={backgroundcolor}
      margintop={margintop}
      borderradius={borderradius}
      boxshadow={boxshadow}
      width={width}
      open={open}
      onClick={toggleDrawer(open)}
    >
      {children}
    </CustomIconButton>
  );
}

HamburgerIconButton.propTypes = {
  marginleft: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.number,
  borderradius: PropTypes.string,
  margintop: PropTypes.number,
  boxshadow: PropTypes.string,
  backgroundcolor: PropTypes.string,
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
HamburgerIconButton.defaultProps = {
  marginleft: '',
  padding: '',
  width: 45,
  borderradius: '',
  margintop: 0,
  boxshadow: '',
  backgroundcolor: '#fff',
  open: true,

};

export default HamburgerIconButton;
