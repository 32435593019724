import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getSupplier = async () => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: '/supplier',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetSupplier = () => useQuery('items', () => getSupplier());

export default useGetSupplier;
