import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';

const CustomTextarea = styled.textarea`
    border-radius: ${props => props.borderradius}px;
    background-color: ${props => props.bgcolor};
    padding: ${props => props.padding};
    border: ${props => props.border};
    color: ${props => props.placeholdercolor};
    width: -webkit-fill-available;
    ::placeholder {
        position: absolute;
        font-size: ${props => props.placeholderfontsize};
        color: ${props => props.placeholdercolor};
        font-weight: ${props => props.placeholderfontweight};
        font-family: Gilroy, Roboto;
    };
    &:focus {
        outline: none;
    }
`;
function CommentTextareaField({
  grid,
  borderradius,
  border,
  padding,
  bgcolor,
  placeholdercolor,
  placeholderfontweight,
  placeholderfontsize,
  fontsize,
  handleChange,
  supplierComment,
  placeholder,
}) {
  const { t } = useTranslation();
  return (
    <Grid item xs={grid}>
      <CustomTextarea
        aria-label="comment"
        rows="15"
        placeholder={placeholder || t('Comment (optional)')}
        placeholdercolor={placeholdercolor}
        placeholderfontsize={placeholderfontsize}
        placeholderfontweight={placeholderfontweight}
        borderradius={borderradius}
        border={border}
        bgcolor={bgcolor}
        padding={padding}
        fontSize={fontsize}
        defaultValue={supplierComment}
        onChange={e => handleChange(e.target.value)}
      />
    </Grid>
  );
}

CommentTextareaField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  bgcolor: PropTypes.string,
  border: PropTypes.string,
  placeholderfontweight: PropTypes.number,
  placeholdercolor: PropTypes.string,
  placeholderfontsize: PropTypes.string,
  fontsize: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  supplierComment: PropTypes.string,
  placeholder: PropTypes.string,
};

CommentTextareaField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  bgcolor: '#fff',
  border: '',
  placeholderfontweight: 400,
  placeholdercolor: '#ccc',
  placeholderfontsize: '14px',
  fontsize: 14,
  supplierComment: '',
};

export default CommentTextareaField;
