/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const postUser = async (username, fullname) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'post',
    data: { username, fullname },
    url: '/supplier/user/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const usePostUser = () => useMutation(({ username, fullname }) => postUser(username, fullname));

export default usePostUser;
