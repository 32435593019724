import { useQuery } from 'react-query';
import customAxios from '../../axios/custom-axios';

const getStats = async () => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: '/me/stats/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetStats = () => useQuery(['stats'], () => getStats(), { enabled: false });

export default useGetStats;
