import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CustomIconButton = styled(IconButton)((props) => ({
  borderRadius: '100%',
  backgroundColor: props.backgroundcolor,
  padding: 0,
  width: props.width,
  maxWidth: props.width,
  minWidth: props.width,
  height: props.height,
  marginRight: props.marginright,
  boxShadow: props.boxshadow,
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: props.backgroundcolorhover,
  },
  '&.active': {
    backgroundColor: props.backgroundcolorhover,
  },
}));
const CustomArrowIcon = styled(ArrowDropDownIcon)((props) => ({
  fill: props.iconcolor,
}));

function DownArrowIconButton({
  active,
  requestSort,
  width,
  height,
  marginright,
  boxshadow,
  backgroundcolor,
  backgroundcolorhover,
  colorhover,
  color,
}) {
  return (
    <CustomIconButton
      className={`${active === 'descending' ? 'active' : ''}`}
      onClick={requestSort}
      aria-label="down-arrow"
      width={width}
      height={height}
      marginright={marginright}
      boxshadow={boxshadow}
      backgroundcolor={backgroundcolor}
      backgroundcolorhover={backgroundcolorhover}
      colorhover={colorhover}
    >
      <CustomArrowIcon
        iconcolor={color}
      />
    </CustomIconButton>
  );
}

DownArrowIconButton.propTypes = {
  active: PropTypes.string,
  requestSort: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  marginright: PropTypes.number,
  boxshadow: PropTypes.string,
  backgroundcolor: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  color: PropTypes.string,
  colorhover: PropTypes.string,
};
DownArrowIconButton.defaultProps = {
  active: '',
  width: 45,
  height: 45,
  marginright: 0,
  boxshadow: '',
  backgroundcolor: '#fff',
  backgroundcolorhover: '#fff',
  color: '#fff',
  colorhover: '#fff',

};

export default DownArrowIconButton;
