/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 12,
  color: '#9c9c9c',
  '&.Mui-selected:not(.Mui-disabled)': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
  },
  '&.Mui-disabled': {
    display: 'none',
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
}));

const CustomSelect = styled(Select)(props => ({
  fontSize: '12px',
  color: '#9c9c9c',
  borderRadius: props.open ? '10px 10px 0 0' : '10px',
  border: '1px solid #ded9d9',
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

function RepeatReminder({ reminderrepeat, setReminderRepeat, reminderdate }) {
  const { t } = useTranslation();
  let reminderValue = [];
  const [repeatValue, setRepeatValue] = useState(reminderrepeat);
  const [remindersCheckbox, setRemindersCheckbox] = useState(null);
  const [open, setOpen] = useState(false);

  const handleChange = event => {
    setRepeatValue(event.target.value);
    setReminderRepeat(event.target.value);
  };

  const menuProps = {
    sx: {
      '& .MuiPaper-root': {
        boxShadow: 'none',
        borderColor: '#ded9d9',
        borderWidth: '0 1px 1px 1px',
        borderStyle: 'solid',
        borderRadius: '0 0 10px 10px',
      },
    },
  };

  const getDayName = (dateStr, locale) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: 'long' });
  };

  useEffect(() => {
    const selectedDay = getDayName(reminderdate, 'sr-Latn-RS');
    const selectedTime = reminderdate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
    const selectedDate = reminderdate.getDate();
    reminderValue = [
      { name: '1', label: t('No repeat') },
      { name: '2', label: t('Daily with time', { selectedTime }) },
      { name: '3', label: t('Weekly with time', { selectedDay, selectedTime }) },
      { name: '4', label: t('Monthly with time', { selectedDate, selectedTime }) },
    ];

    const remindersCheckboxEl = reminderValue.map(reminder => (
      <CustomMenuItem
        key={reminder.name}
        value={reminder.name}
      >
        {reminder.label}
      </CustomMenuItem>
    ));

    setRemindersCheckbox(remindersCheckboxEl);
  }, [reminderdate]);

  return (
    <CustomSelect
      value={repeatValue}
      onChange={handleChange}
      displayEmpty
      MenuProps={menuProps}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      open={open}
    >
      <CustomMenuItem value="" disabled>
        {t('Repeat reminder')}
      </CustomMenuItem>
      {remindersCheckbox}
    </CustomSelect>
  );
}

RepeatReminder.propTypes = {
  reminderrepeat: PropTypes.string,
  setReminderRepeat: PropTypes.func,
  reminderdate: PropTypes.instanceOf(Date),
};

export default RepeatReminder;
