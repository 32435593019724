/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { styled as mstyled } from '@mui/material/styles';
import ImageBox from '../ImageBox';

const CustomWrapperBox = styled.div`
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: -1px 3px 5px 1px #e5e3e3;
    clear: both;
`;

const CustomOrangeBox = styled.div`
    background: linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    padding: 40px 10px;
    margin-top: -40px;
`;

const CustomLabel = mstyled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
  letterSpacing: 0.5,
  color: theme.palette.blue.main,
}));

const CustomValue = mstyled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 12,
  letterSpacing: 0.5,
  color: '#6e6e6e',
  '&:nth-of-type(even)': {
    marginBottom: 10,
  },
}));

function SupplierDataComponent(props) {
  const { t } = useTranslation();
  const {
    supplier, image, suppliername, address, cityName,
  } = props;

  return (
    <CustomWrapperBox>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <CustomOrangeBox>
            <ImageBox
              width="50px"
              height="50px"
              padding="10px"
              backgroundcolor="#f8f8f8"
              borderradius={10}
              objectFit="contain"
              imgSrc={image}
            />
            <div>
              <Typography color="primary" sx={{ fontWeight: 600, letterSpacing: 1, marginLeft: 1 }}>
                {suppliername}
              </Typography>
              <Typography color="primary" sx={{ fontSize: 12, fontWeight: 400, marginLeft: 1 }}>
                {address}
              </Typography>
            </div>
          </CustomOrangeBox>
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomLabel>
            {t('Supplier')}
          </CustomLabel>
          <CustomValue>
            {suppliername}
          </CustomValue>
          <CustomLabel>
            {t('Email')}
          </CustomLabel>
          <CustomValue>
            {supplier.email}
          </CustomValue>
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomLabel>
            {t('Street and number')}
          </CustomLabel>
          <CustomValue>
            {address}
          </CustomValue>
          <CustomLabel>
            {t('City and state')}
          </CustomLabel>
          <CustomValue>
            {cityName}
            ,
            {' '}
            {supplier.country_name}
          </CustomValue>
        </Grid>
      </Grid>
    </CustomWrapperBox>
  );
}

export default SupplierDataComponent;
