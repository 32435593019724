import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import styled from 'styled-components';
import { styled as mstyled } from '@mui/material/styles';
import logo from '../../images/logoFinal.png';
import ListItemLink from '../ListLinkItem';
import HamburgerIconButton from '../IconButtons/HamburgerIconButton';
import ImageBox from '../ImageBox';
import SettingsNavItem from './Header/NavItems/SettingsNavItem';
import SupportNavItem from './Header/NavItems/SupportNavItem';

const SideBarWrapper = styled.div`
    margin-left: ${(props) => props.marginleft || '0'};
    margin-top: ${(props) => props.margintop || '0'};
    height: 100%;
    display: flex;
    width: 250px;
    flex-direction: ${(props) => props.flexdirection || 'column'};
    padding: 20px 0 0 20px;
`;

const VersionContainer = styled.div`
  position: absolute;
  bottom: 3px;
  right: 5px;
  font-size: 10px;
  color: #fff;
  text-align: right;
`;

const BottomIconContainer = styled.div`
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
`;

const Version = () => (
  <VersionContainer>
    v
    {process.env.REACT_APP_VERSION}
  </VersionContainer>
);

const CustomBox = mstyled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 0,
  paddingRight: 0,
  margin: '0 0 70px 30px',
}));

function Sidebar({
  open,
  toggleDrawer,
  sidebarColor,
  linkColor,
}) {
  const { t } = useTranslation();

  const list = (
    <List>
      <ListItemLink linkColor={linkColor} to="/orders" text={t('Orders')}>
        <AssignmentTurnedInIcon />
      </ListItemLink>
      <ListItemLink linkColor={linkColor} to="/returns" text={t('Returns')}>
        <ContentPasteGoIcon />
      </ListItemLink>
      <ListItemLink linkColor={linkColor} to="/items" text={t('List of items')}>
        <LibraryBooksIcon />
      </ListItemLink>
      <ListItemLink linkColor={linkColor} to="/clients" text={t('Clients')}>
        <PersonAddAltIcon />
      </ListItemLink>
      <ListItemLink linkColor={linkColor} to="/users" text={t('Users')}>
        <PeopleOutlineIcon />
      </ListItemLink>
      <ListItemLink linkColor={linkColor} to="/notification" text={t('Send notification')}>
        <MarkUnreadChatAltIcon />
      </ListItemLink>
      <ListItemLink linkColor={linkColor} to="/reminder" text={t('Send reminder')}>
        <NotificationsIcon />
      </ListItemLink>
    </List>
  );

  return (
    <>
      <Drawer
        open={open}
        anchor="left"
        variant="persistent"
        sx={{
          width: '271px',
          height: 'auto',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            border: 'none',
            width: '271px',
            padding: 0,
            background: sidebarColor,
          },
        }}
      >
        <SideBarWrapper role="presentation">
          <CustomBox>
            <ImageBox
              width="auto"
              height="auto"
              objectFit="contain"
              margin="0 10px 0 0"
              marginleft={0}
              imgSrc={logo}
            />
            <HamburgerIconButton
              color="#fff"
              padding="0 25px 0 0"
              backgroundcolor="transparent"
              open={false}
              toggleDrawer={toggleDrawer}
            >
              <MenuOpenIcon />
            </HamburgerIconButton>
          </CustomBox>
          {list}
          <BottomIconContainer>
            <SettingsNavItem
              marginright={8}
              boxshadow="none"
              backgroundcolor="transparent"
              backgroundcolorhover="#fff"
              colorhover={linkColor}
              iconwidth={30}
              iconheight={30}
              color="#fff"
              width={45}
            />
            <SupportNavItem
              marginright={8}
              boxshadow="none"
              backgroundcolor="transparent"
              backgroundcolorhover="#fff"
              colorhover={linkColor}
              iconwidth={25}
              iconheight={25}
              color="#fff"
              width={45}
            />
          </BottomIconContainer>
          <Version />
        </SideBarWrapper>
      </Drawer>
      {!open && (
        <HamburgerIconButton
          color="#3384B9"
          backgroundcolor="#F8F8F8"
          margintop={33}
          borderradius="0 6px 6px 0"
          boxshadow="-1px 2px 4px 1px #302f2f26"
          padding="10px"
          width={80}
          open
          toggleDrawer={toggleDrawer}
        >
          <MenuOpenIcon />
        </HamburgerIconButton>
      )}
    </>
  );
}

Sidebar.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool,
  sidebarColor: PropTypes.string,
  linkColor: PropTypes.string,
};

Sidebar.defaultProps = {
  open: true,
  sidebarColor: 'linear-gradient(180deg,rgba(63, 116, 166, 1) 0%, rgba(63, 116, 166, 1) 0%, rgba(49, 167, 203, 1) 100%)',
  linkColor: '#3384B9',
};

export default Sidebar;
