import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getListOfReminders = async param => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: `/reminders/${param}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetListOfReminders = param => useQuery(['reminders', param], () => getListOfReminders(param), { enabled: true });

export default useGetListOfReminders;
