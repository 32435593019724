import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';

const CustomIconButton = styled(NavLink)((props) => ({
  borderRadius: '100%',
  backgroundColor: props.backgroundcolor,
  padding: 0,
  width: props.width,
  maxWidth: props.width,
  minWidth: props.width,
  height: props.width,
  marginRight: props.marginright,
  boxShadow: props.boxshadow,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    width: props.iconwidth,
    height: props.iconheight,
  },
  '&:hover': {
    backgroundColor: props.backgroundcolorhover,
    '& svg path': {
      fill: props.colorhover,
      stroke: props.colorhover,
    },
  },
  '&.active': {
    backgroundColor: props.backgroundcolorhover,
    '& svg path': {
      fill: props.colorhover,
      stroke: props.colorhover,
    },
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color,
    boxShadow: theme.shadows[5],
    fontSize: 11,
  },
}));

function NavItem(props) {
  const {
    width,
    iconwidth,
    iconheight,
    marginright,
    boxshadow,
    colorhover,
    backgroundcolor,
    backgroundcolorhover,
    color,
    children,
    to,
    ariaLabled,
    title,
  } = props;
  return (
    <CustomTooltip
      title={title}
      position="top"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      color={colorhover}
    >
      <CustomIconButton
        to={to}
        aria-label={ariaLabled}
        width={width}
        iconwidth={iconwidth}
        iconheight={iconheight}
        color={color}
        marginright={marginright}
        boxshadow={boxshadow}
        backgroundcolor={backgroundcolor}
        backgroundcolorhover={backgroundcolorhover}
        colorhover={colorhover}
      >
        {children}
      </CustomIconButton>
    </CustomTooltip>
  );
}

NavItem.propTypes = {
  width: PropTypes.number,
  iconwidth: PropTypes.number,
  iconheight: PropTypes.number,
  boxshadow: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  colorhover: PropTypes.string,
  backgroundcolor: PropTypes.string,
  marginright: PropTypes.number,
  color: PropTypes.string,
  ariaLabled: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

NavItem.defaultProps = {
  width: 0,
  iconwidth: 16,
  iconheight: 16,
  backgroundcolor: '',
  backgroundcolorhover: '#fff',
  colorhover: '',
  boxshadow: '',
  marginright: 10,
  color: '',
  ariaLabled: '',
  to: '',
  title: '',
};

export default NavItem;
