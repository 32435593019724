/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useGetClientsAll from '../../Hooks/clients/useGetClientsAll';
import SearchBar from './SearchBar/SearchBar';
import LeftArrowIconButton from '../IconButtons/LeftArrowIconButton';
import RightArrowIconButton from '../IconButtons/RightArrowIconButton';
import TableSkeleton from '../Skeletons/TableSkeleton';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.secondary.main,
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  fontSize: 12,
  color: '#9c9c9c',
}));

const CustomGridItem = styled(Grid)(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: props.justifyContent,
  flexDirection: props.flexDirection,
}));

const CustomBox = styled(Box)(() => ({
  width: '100%',
  border: '1px solid #ded9d9',
  borderRadius: 10,
  padding: '15px 20px',
  margin: '0',
  height: 'fit-content',
  minHeight: '120px',
}));

function SearchClients({
  searchplaceholder, selectAll,
  setSelectAll, selectedClients, setSelectedClients, isReminder, isNotified,
}) {
  const { t } = useTranslation();
  const [searchedTerm, setSearchedTerm] = useState('');
  const [param, setParam] = useState('');
  const [sliceValueFrom, setSliceValueFrom] = useState(0);
  const [sliceValueTo, setSliceValueTo] = useState(10);
  const [nextButtonDisable, setNextButtonDisable] = useState(false);
  const [backButtonDisable, setBackButtonDisable] = useState(true);
  const {
    status,
    data,
    isFetched,
  } = useGetClientsAll(param);
  let clients = [];

  useEffect(() => {
    if (searchedTerm) {
      setParam(`?companyname=${searchedTerm}`);
    } else {
      setParam('');
      setSearchedTerm('');
    }
  }, [searchedTerm]);

  useEffect(() => {
    if (isNotified) {
      setSearchedTerm('');
    }
  }, [isNotified]);

  if (isFetched) {
    clients = data && data?.data && data.data.slice(sliceValueFrom, sliceValueTo);
  }
  const onChangeSelectedCients = value => {
    let clientsIdArray = selectedClients;
    if (!isReminder) {
      if (value === 'selectAllClients') {
        setSelectAll(!selectAll);
        clientsIdArray = selectAll ? [] : data && data?.data && data.data.map(client => client.id);
        setSelectedClients(clientsIdArray);
      } else if (selectedClients.includes(value)) {
        setSelectedClients(selectedClients.filter(client => client !== value));
      } else {
        clientsIdArray.push(value);
        setSelectedClients([...clientsIdArray]);
      }
    } else if (selectedClients.includes(value)) {
      setSelectedClients(selectedClients.filter(client => client !== value));
    } else {
      clientsIdArray.push(value);
      setSelectedClients([...clientsIdArray]);
    }
  };
  const selectall = { label: t('Select all'), name: 'select_all', value: false };

  const clientsCheckbox = clients && clients.map(client => (
    <CustomFormControlLabel
      key={client.id}
      control={
        <CustomCheckbox size="small" checked={selectedClients.includes(client.id)} disabled={!!(isReminder && selectedClients[0] && !selectedClients.includes(client.id))} onChange={() => onChangeSelectedCients(client.id)} name={client.companyname} />
            }
      label={client.companyname}
    />
  ));

  useEffect(() => {
    if (data?.data.length - sliceValueFrom <= 10) {
      setNextButtonDisable(true);
    } else {
      setNextButtonDisable(false);
    }
    if (sliceValueTo <= 10) {
      setBackButtonDisable(true);
    }
    if (data?.data.length <= 10) {
      setBackButtonDisable(true);
      setNextButtonDisable(true);
    }
  }, [sliceValueTo, sliceValueFrom, data?.data.length]);

  const handleChange = value => {
    if (value === 'next' && (data?.data.length - sliceValueFrom) > 10) {
      setSliceValueFrom(sliceValueFrom + 10);
      setSliceValueTo(sliceValueTo + 10);
      setNextButtonDisable(false);
      setBackButtonDisable(false);
    } else if (value === 'next' && (data?.data.length - sliceValueFrom) < 10) {
      setNextButtonDisable(true);
    }
    if (value === 'back' && sliceValueTo > 10) {
      setSliceValueFrom(sliceValueFrom - 10);
      setSliceValueTo(sliceValueTo - 10);
      setBackButtonDisable(false);
      setNextButtonDisable(false);
    } else if (value === 'back' && sliceValueFrom < 10) {
      setBackButtonDisable(true);
    }
  };

  return (
    <Grid container display="flex" flexDirection="column">
      <CustomGridItem item xs={12} flexDirection="row" sx={{ flexDirection: 'row', justifyContent: { xs: 'space-between', sm: 'start' } }}>
        <Grid item xs={isReminder ? 12 : 8.5}>
          <SearchBar
            name="search-clients"
            placeholder={searchplaceholder}
            placeholdercolor="#9c9c9c"
            borderradius="10px"
            padding="5px 17px 5px 22px"
            margin="15px 0 15px"
            backgroundcolor="#fdfdfd"
            border="1px solid #ded9d9"
            display="flex"
            direction="row-reverse"
            justifycontent="flex-end"
            marginicon="0 10px 0 0"
            grid={12}
            setSearchedTerm={term => {
              setSliceValueFrom(0);
              setSliceValueTo(10);
              setSearchedTerm(term);
            }}
            searchedTerm={searchedTerm}
            mobileWidth="90%"
          />
        </Grid>
        {!isReminder
        && (
        <Grid item xs={3}>
          <CustomFormControlLabel
            sx={{ marginLeft: 0, marginRight: 0 }}
            control={(
              <CustomCheckbox
                checked={selectAll}
                onChange={() => onChangeSelectedCients('selectAllClients')}
                name={selectall.name}
              />
            )}
            label={selectall.label}
          />
        </Grid>
        )}
      </CustomGridItem>
      <CustomGridItem
        item
        xs={11.5}
        flexDirection="row"
        position="relative"
        justifyContent="space-evenly"
      >
        <CustomBox
          display="flex"
          flexDirection="row"
          justifyContent="normal"
          flexWrap="wrap"
        >
          { status === 'loading'
            ? (
              <TableSkeleton height="10px" />
            )
            : (
              <>
                {clientsCheckbox && clientsCheckbox}
                {clientsCheckbox.length === 0
          && (
          <Typography color="secondary" fontSize={14}>
            {t('No results')}
          </Typography>
          )}

              </>
            )}
        </CustomBox>
        <Box
          display="flex"
          position="absolute"
          left="100%"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <LeftArrowIconButton
            backgroundcolor="#3384B9"
            backgroundcolorhover="#3384B9"
            padding="0 0 0 5px"
            margin={5}
            color="#fff"
            width={20}
            height={20}
            iconsize={12}
            handleChange={handleChange}
            backButtonDisable={backButtonDisable}
          />
          <RightArrowIconButton
            backgroundcolor="#3384B9"
            backgroundcolorhover="#3384B9"
            padding="0 0 0 2px"
            margin={5}
            color="#fff"
            width={20}
            height={20}
            iconsize={12}
            handleChange={handleChange}
            nextButtonDisable={nextButtonDisable}
          />
        </Box>
      </CustomGridItem>
    </Grid>
  );
}

SearchClients.propTypes = {
  searchplaceholder: PropTypes.string,
  setSelectedClients: PropTypes.func,
  selectedClients: PropTypes.arrayOf(PropTypes.number),
  selectall: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
  }),
  isReminder: PropTypes.bool,
  setSelectAll: PropTypes.func,
  selectAll: PropTypes.bool,
  isNotified: PropTypes.bool,
};

SearchClients.defaultProps = {
  searchplaceholder: '',
  selectAll: false,
  isReminder: false,
  isNotified: false,
};

export default SearchClients;
