/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const addItem = async (name, code) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'post',
    data: { name, code },
    url: '/products/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useAddItem = () => useMutation(({ name, code }) => addItem(name, code));

export default useAddItem;
