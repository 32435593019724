/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import useSortableData from '../../Hooks/useSortableData';
import PaginationComponent from '../Pagination/Pagination';
import TableComponent from './TableComponent';
import TableSkeleton from '../Skeletons/TableSkeleton';

function ClientsTable({
  headCells,
  rows,
  setClient,
  refetch,
  clientTabsRef,
  handleChangePage, page, count, statusLoad,
}) {
  const { t } = useTranslation();
  const { items, requestSort, sortConfig } = useSortableData(rows);
  return (
    <>
      { statusLoad === 'loading'
        ? (
          <TableSkeleton />
        )
        : items.length === 0
          ? (
            <Typography color="secondary" fontSize={20}>
              {t('No results')}
            </Typography>
          )
          : (
            <TableComponent
              headCells={headCells}
              rows={items}
              direction={sortConfig?.direction}
              margin="5px"
              boxshadowtable="0px 3px 10px 4px #f6f6f6"
              boxshadowtablehead="0px 3px 3px 0px #f6f6f6"
              paddingcellhead="14px 0"
              borderradiustable={16}
              labelfontsize={14}
              labelcolor="#3384B9"
              labelfontweight={500}
              border="1px solid #f6f6f678"
              backgroundcoloroddrow="#F9F9F9"
              colorcell="#888888"
              paddingcell="12px 0"
              requestSort={requestSort}
              setClient={setClient}
              refetch={refetch}
              clientTabsRef={clientTabsRef}
            />
          )}
      {items.length !== 0
          && (
          <PaginationComponent
            handleChangePage={handleChangePage}
            count={count}
            pageNum={page}
          />
          )}
    </>
  );
}

ClientsTable.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    label: PropTypes.string,
    sortable: PropTypes.bool,
  })),
  rows: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
  })),
  setClient: PropTypes.func,
  refetch: PropTypes.func,
  clientTabsRef: PropTypes.node,
  handleChangePage: PropTypes.func,
  page: PropTypes.number,
  count: PropTypes.number,
  statusLoad: PropTypes.string,
};

ClientsTable.defaultProps = {
  headCells: [],
  rows: [],
  setClient: null,
};

export default ClientsTable;
