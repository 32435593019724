import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDeleteUser from '../../../Hooks/users/useDeleteUser';
import DeleteModal from '../DeleteModal';

function DeleteUser({ userid, users, setUsers }) {
  const { t } = useTranslation();
  const [isDeleted, setIsDeleted] = useState(false);
  const { mutate: deleteUser } = useDeleteUser();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setIsDeleted(false);
  };
  const handleClose = () => {
    setOpen(false);
    if (isDeleted) {
      const updatedUsers = users.filter(user => user.id !== userid);
      setTimeout(() => {
        setUsers(updatedUsers);
      }, [10]);
    }
  };

  const handleRemoveUser = event => {
    event.preventDefault();
    return deleteUser(({ userid }), {
      onSuccess: async () => {
        setIsDeleted(true);
      },
      onError: async () => {
        setIsDeleted(false);
      },
    });
  };

  return (
    <DeleteModal
      deletemodaltitle={t('Delete user')}
      deletemodaltext={t('Do you want to delete this user')}
      handleChange={handleRemoveUser}
      isDeleted={isDeleted}
      setIsDeleted={setIsDeleted}
      buttonColor="#fff"
      colorHover="#fff"
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      deletedText={t('User deleted')}
    />
  );
}

DeleteUser.propTypes = {
  userid: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape()),
  setUsers: PropTypes.func,
};

export default DeleteUser;
