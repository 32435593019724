/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

const EditIconButton = forwardRef((props, ref) => (
  <IconButton
    ref={ref}
    aria-label="edit-item"
    size="small"
    onClick={() => props.handleClick()}
  >
    <EditIcon />
  </IconButton>
));

export default EditIconButton;
