import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled as mstyled } from '@mui/material/styles';
import styled from 'styled-components';
import useLogin from '../Hooks/useLogin';
import emailValidation from '../Validations/email-validation';
import passwordValidation from '../Validations/password-validation';
import logo from '../images/logo.png';
import ButtonConfirm from '../Components/Forms/Buttons/ButtonConfirm';
import PasswordReset from '../Components/Modals/PasswordReset';
import ImageBox from '../Components/ImageBox';
import EmailInputField from '../Components/Forms/InputFields/RegisterFields/EmailInputField';
import PasswordInputField from '../Components/Forms/InputFields/RegisterFields/PasswordInputField';
import LanguageSwitcher from '../Components/LanguageSwitcher';

const PageWrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(63, 116, 166, 1) 0%,
    rgba(63, 116, 166, 1) 0%,
    rgba(49, 167, 203, 1) 100%
  );
  height: 100vh;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 340px) {
    height: 100%;
  }
`;

const CustomModalBox = mstyled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
}));

const CustomMessageBox = mstyled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto 50px',
  width: '85%',
  borderRadius: 20,
  backgroundColor: '#f56f4bab',
  border: '2px solid #F56F4B',
  padding: 10,
}));

const CustomTypography = mstyled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '16px',
  textAlign: 'center',
  fontWeight: 500,
  backgroundColor: '#ffffffc4',
  borderRadius: '10px',
  border: `2px solid ${theme.palette.primary.main}`,
  padding: '5px 10px',
}));

function Login({ setToken, language, setLanguage }) {
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { mutate: loginUser } = useLogin();
  const [error, setError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [passwordResetMessage, setPasswordResetMessage] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    const emailError = emailValidation(username);
    const passwordError = passwordValidation(password);
    if (!emailError && !passwordError) {
      setEmailErrorMessage('');
      return loginUser(({ username, password }), {
        onSuccess: async data => {
          localStorage.setItem('token', data.data.access_token);
          localStorage.setItem('refresh_token', data.data.refresh_token);
          localStorage.setItem('lang', data.data.lang);
          setToken(true);
          setError(false);
          i18n.changeLanguage(data.data.lang);
        },
        onError: async () => {
          setToken(false);
          return setError(true);
        },
      });
    }
    if (passwordError) {
      setPasswordErrorMessage(t('Invalid password'));
    }
    return setEmailErrorMessage(t('Invalid email address'));
  };

  return (
    <PageWrapper>
      <LanguageSwitcher
        language={language}
        setLanguage={setLanguage}
      />
      <Container component="main" maxWidth="xs">
        <CustomModalBox>
          <ImageBox
            width="180px"
            height="120px"
            objectFit="contain"
            margin="50px"
            imgSrc={logo}
          />
          {error
           && (
           <CustomMessageBox>
             <Typography color="primary">
               {t('Login error message')}
             </Typography>
           </CustomMessageBox>
           )}
          <Box
            component="form"
            noValidate
          >
            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
              <EmailInputField
                grid={10}
                bgcolor="#fff"
                required
                borderradius={20}
                border="1px solid #ded9d9"
                padding="5px 15px"
                placeholdercolor="#3a78ac"
                handleChange={setUsername}
                email={username}
                errorMessage={emailErrorMessage}
              />
              <PasswordInputField
                grid={10}
                required
                bgcolor="#fff"
                borderradius={20}
                border="1px solid #ded9d9"
                padding="5px 15px"
                placeholdercolor="#3a78ac"
                label={t('Password')}
                endAdornment
                handleChange={setPassword}
                password={password}
                errorMessage={passwordErrorMessage}
              />
              <Grid item xs={6}>
                <ButtonConfirm
                  disabled={!username || !password}
                  type="submit"
                  color="#fff"
                  margin="15px 0 10px 0"
                  padding="7px 0"
                  borderradius={20}
                  textsize={16}
                  textweight={400}
                  bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
                  variant="contained"
                  text={t('Log In')}
                  handleChange={handleSubmit}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" sx={{ mt: 1 }}>
              <PasswordReset setPasswordResetMessage={setPasswordResetMessage} />
            </Grid>
            { passwordResetMessage
            && <CustomTypography color="primary">{passwordResetMessage}</CustomTypography>}
          </Box>
          <Grid item marginTop={1}>
            <Link href="/register" variant="body2">
              {t('Register message')}
            </Link>
          </Grid>
        </CustomModalBox>
      </Container>
    </PageWrapper>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  language: PropTypes.string,
  setLanguage: PropTypes.func.isRequired,
};

export default Login;
