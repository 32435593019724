import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getReturnItems = async param => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: `/orders/${param}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetReturnItems = param => useQuery(['items', param], () => getReturnItems(param), { enabled: false });

export default useGetReturnItems;
