import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import linkImage from '../images/sidebar.png';

const CustomListItem = styled(NavLink)(({ theme, linkcolor }) => ({
  color: theme.palette.primary.main,
  borderRadius: '50px 0 0 50px',
  backgroundColor: 'transparent',
  margin: '3px 0',
  padding: '6px 26px',
  display: 'flex',
  textDecoration: 'none',
  fontSize: 14,
  justifyContent: 'space-around',
  alignItems: 'center',
  '& svg': {
    zIndex: 2,
    width: 20,
    marginRight: 15,
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  '&:hover': {
    color: linkcolor,
    position: 'relative',
    '&:before': {
      content: '""',
      backgroundImage: `url(${linkImage})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: '-18px',
      left: 1,
      width: 250,
      height: 80,
    },
    '& svg path': {
      fill: linkcolor,
    },
  },
  '&.active': {
    color: linkcolor,
    position: 'relative',
    '&:before': {
      content: '""',
      backgroundImage: `url(${linkImage})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: '-18px',
      left: 1,
      width: 250,
      height: 80,
    },
    '& svg path': {
      fill: linkcolor,
    },
  },
}));

function ListItemLink(props) {
  const {
    text, to, children, linkColor,
  } = props;

  return (
    <CustomListItem to={to} linkcolor={linkColor}>
      {children}
      <ListItemText primary={text} sx={{ zIndex: 999 }} />
    </CustomListItem>

  );
}

ListItemLink.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  linkColor: PropTypes.string,
};
ListItemLink.defaultProps = {
  text: '',
  to: '',
  linkColor: '#3384B9',
};

export default ListItemLink;
