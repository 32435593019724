/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomTabPanel = styled.div`
    padding: 35px 0;
`;

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <CustomTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {children}
    </CustomTabPanel>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number,
  index: PropTypes.number,
};

TabPanel.defaultProps = {
  value: 0,
  index: 0,
};

export default TabPanel;
