/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useAddClient from '../../../Hooks/clients/useAddClient';
import useGetCities from '../../../Hooks/useGetCities';
import ContactPersonInputField from '../../Forms/InputFields/RegisterFields/ContactPersonInputField';
import AddModal from '../AddModal';
import SupplierInputField from '../../Forms/InputFields/RegisterFields/SupplierInputField';
import TinInputField from '../../Forms/InputFields/RegisterFields/TinInputField';
import AddressInputField from '../../Forms/InputFields/RegisterFields/AddressInputField';
import PasswordInputField from '../../Forms/InputFields/RegisterFields/PasswordInputField';
import EmailInputField from '../../Forms/InputFields/RegisterFields/EmailInputField';
import CitiesSelectField from '../../Forms/SelectFields/CitiesSelectField';
import pibValidation from '../../../Validations/pib-validation';
import emailValidation from '../../../Validations/email-validation';
import passwordValidation from '../../../Validations/password-validation';

const CustomButtonConfirm = styled(Button)(() => ({
  background:
        'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  color: '#fff',
}));

function AddNewClientModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { mutate: addClient } = useAddClient();
  const [email, setEmail] = useState('');
  const [companyname, setCompanyname] = useState('');
  const [password, setPassword] = useState('');
  const [pib, setPib] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState(undefined);
  const [contactPerson, setContactPerson] = useState('');
  const [isAdded, setIsAdded] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [pibErrorMessage, setPibErrorMessage] = useState('');
  const [message, setMessage] = useState('');

  const {
    data,
    isFetched,
    refetch,
  } = useGetCities();

  const handleOpen = () => {
    refetch();
    setOpen(true);
    setIsAdded(false);
  };

  useEffect(() => {
    if (email && companyname && password && pib && city
      && contactPerson && !pibErrorMessage && !emailErrorMessage && !passwordErrorMessage) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    email, companyname, password, pib,
    address, city, contactPerson,
    pibErrorMessage, emailErrorMessage, passwordErrorMessage]);

  const handleClose = () => setOpen(false);

  const handleChange = event => {
    event.preventDefault();
    return addClient(({
      email, companyname, password, pib, address, city, contactPerson,
    }), {
      onSuccess: async () => {
        setIsAdded(true);
        setCity('');
        setAddress('');
        setEmail('');
        setPassword('');
        setPib('');
        setCompanyname('');
        setContactPerson('');
        setMessage('');
        props.refetch();
      },
      onError: async res => {
        setIsAdded(false);
        if (res.response.data.length !== 0) {
          const errorKeys = Object.keys(res.response.data);
          const errorMessage = [];
          errorKeys.forEach(key => {
            if (key === 'pib') {
              errorMessage.push(t('Pib error message'));
            } else {
              errorMessage.push(res.response.data[key]);
            }
          });
          setMessage(errorMessage.toString());
        }
      },
    });
  };
  const handlePib = value => {
    const pibError = pibValidation(value);
    if (pibError) {
      setPibErrorMessage(t('Invalid pib'));
    } else {
      setPibErrorMessage(t(''));
    }
    setPib(value);
  };
  const handleEmail = value => {
    const emailError = emailValidation(value);
    if (emailError) {
      setEmailErrorMessage(t('Invalid email address'));
    } else {
      setEmailErrorMessage(t(''));
    }
    setEmail(value);
  };
  const handlePassword = value => {
    const passwordError = passwordValidation(value);
    if (passwordError) {
      setPasswordErrorMessage(t('Invalid password'));
    } else {
      setPasswordErrorMessage(t(''));
    }
    setPassword(value);
  };

  return (
    <div>
      <AddModal
        titlemargin="0 0 20px"
        width={400}
        bgcolor="#fff"
        padding="30px 25px 30px 40px"
        modaltitle={t('Add new client')}
        buttonssize={2.5}
        isChanged={isAdded}
        handleChange={handleChange}
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        disabledSubmitButton={isButtonDisabled}
        message={message}
      >
        {
          isAdded
            ? (
              <Box sx={{ margin: '30px 0 0', width: '100%' }}>
                <Typography color="secondary" sx={{ margin: '0 0 30px' }}>{t('Client added')}</Typography>
                <CustomButtonConfirm
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  {t('Close')}
                </CustomButtonConfirm>
              </Box>
            )
            : (
              <Grid container spacing={1.3}>
                <SupplierInputField
                  grid={12}
                  required
                  bgcolor="#fff"
                  borderradius={20}
                  border="1px solid #ded9d9"
                  padding="5px 15px"
                  requiredcolor="#a9a9a9"
                  placeholdercolor="#ded9d9"
                  handleChange={setCompanyname}
                  companyname={companyname}
                />
                <EmailInputField
                  grid={12}
                  bgcolor="#fff"
                  required
                  borderradius={20}
                  border="1px solid #ded9d9"
                  padding="5px 15px"
                  requiredcolor="#a9a9a9"
                  placeholdercolor="#ded9d9"
                  handleChange={handleEmail}
                  margin={emailErrorMessage ? '15px' : '0px'}
                  email={email}
                  errorMessage={emailErrorMessage}
                />
                <PasswordInputField
                  grid={12}
                  required
                  bgcolor="#fff"
                  borderradius={20}
                  border="1px solid #ded9d9"
                  padding="5px 15px"
                  endAdornment
                  requiredcolor="#a9a9a9"
                  placeholdercolor="#ded9d9"
                  handleChange={handlePassword}
                  password={password}
                  label={t('Password')}
                  margin={passwordErrorMessage ? '30px' : '0px'}
                  errorMessage={passwordErrorMessage}
                />
                <TinInputField
                  grid={12}
                  required
                  bgcolor="#fff"
                  borderradius={20}
                  border="1px solid #ded9d9"
                  padding="5px 15px"
                  requiredcolor="#a9a9a9"
                  placeholdercolor="#ded9d9"
                  handleChange={handlePib}
                  pib={pib}
                  errorMessage={pibErrorMessage}
                  margin={pibErrorMessage ? '15px' : '0px'}
                />
                <AddressInputField
                  grid={12}
                  required
                  bgcolor="#fff"
                  borderradius={20}
                  border="1px solid #ded9d9"
                  padding="5px 15px"
                  requiredcolor="#a9a9a9"
                  placeholdercolor="#ded9d9"
                  handleChange={setAddress}
                  address={address}
                />
                <CitiesSelectField
                  grid={12}
                  required
                  bgcolor="#fff"
                  borderradius={20}
                  padding="10px 15px"
                  requiredcolor="#a9a9a9"
                  placeholdercolor="#ded9d9"
                  data={data}
                  isFetched={isFetched}
                  handleChange={setCity}
                  defaultValue={city}
                />
                <ContactPersonInputField
                  grid={12}
                  required
                  bgcolor="#fff"
                  borderradius={20}
                  border="1px solid #ded9d9"
                  padding="5px 15px"
                  requiredcolor="#a9a9a9"
                  placeholdercolor="#ded9d9"
                  handleChange={setContactPerson}
                  contactPerson={contactPerson}
                />
              </Grid>
            )
        }
      </AddModal>
    </div>
  );
}

export default AddNewClientModal;
