import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputField from '../InputField';

function WebsiteInputField({
  grid,
  borderradius,
  border,
  padding,
  bgcolor,
  placeholdercolor,
  handleChange,
  defaultValue,
}) {
  const { t } = useTranslation();
  return (
    <InputField
      name="website"
      grid={grid}
      type="text"
      placeholder={t('Website')}
      label={t('Website')}
      borderradius={borderradius}
      border={border}
      padding={padding}
      bgcolor={bgcolor}
      placeholdercolor={placeholdercolor}
      labelcolor="#fff"
      defaultValue={defaultValue}
      handleChange={handleChange}
    />
  );
}

WebsiteInputField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  bgcolor: PropTypes.string,
  placeholdercolor: PropTypes.string,
  border: PropTypes.string,
  defaultValue: PropTypes.string,
  handleChange: PropTypes.func,
};

WebsiteInputField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  bgcolor: '#fff',
  placeholdercolor: '#efefef',
  border: '',
  defaultValue: '',
};

export default WebsiteInputField;
