/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import useGetOrders from '../Hooks/orders/useGetOrders';
import Tabs from '../Components/Tabs/Tabs';
import SearchBar from '../Components/Forms/SearchBar/SearchBar';
import OrdersTable from '../Components/Tables/Orders/OrdersTable';
import OrderCalendar from '../Components/Calendar/OrderCalendar';

function CreateData(client, address, date, id, isseen, review = null, editOrder = null, viewOrder = null) {
  if (review) {
    return {
      client,
      address,
      date,
      id,
      isseen,
      review,
      viewOrder,
    };
  }
  return {
    client,
    address,
    id,
    isseen,
    date,
    editOrder,
  };
}

const CustomGrid = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  top: '45px',
  width: '100%',
  right: 0,
  [theme.breakpoints.down('md')]: {
    left: 0,
    top: 0,
  },
}));

function Orders({ setIsSeenOrder }) {
  const { t } = useTranslation();
  const tabsnames = [t('Unprocessed orders'), t('Processed orders')];
  const [searchedTerm, setSearchedTerm] = useState('');
  const [tab, setTab] = useState('new');
  const [date, setDate] = useState(null);
  const [param, setParam] = useState('?orderstatus=new');
  const [page, setPage] = useState(1);
  const [refetch, setRefetch] = useState(false);
  let count = 0;
  let limit = 10;
  const newOrdersRows = [];
  const completedOrdersRows = [];
  const {
    status,
    data,
    isFetched,
  } = useGetOrders(param);

  useEffect(() => {
    const day = date && date?.getDate();
    const month = date && date.getMonth() + 1;
    const year = date && date?.getFullYear();
    if (searchedTerm || date) {
      const filters = searchedTerm && !date ? `&company=${searchedTerm}&page=${page}`
        : !searchedTerm && date ? `&date_from=${year}${month}${day}&date_to=${year}${month}${day}&page=${page}`
          : `&company=${searchedTerm}&date_from=${year}${month}${day}&date_to=${year}${month}${day}&page=${page}`;
      setParam(`?orderstatus=${tab}${filters}`);
    } else if (refetch) {
      if (date) {
        setParam(`?orderstatus=${tab}&company=${searchedTerm}&date_from=${year}${month}${day}&date_to=${year}${month}${day}&page=${page}`);
      } else {
        setParam(`?orderstatus=${tab}&company=${searchedTerm}&page=${page}`);
      }
      setRefetch(false);
    } else {
      setParam(`?orderstatus=${tab}&page=${page}`);
    }
  }, [tab, searchedTerm, date, refetch, page]);

  if (isFetched) {
    const orders = data && data?.data && data.data.slice(0, 10);
    const ordersNumber = data && data.headers['x-pagination'].split(', ')[0].match(/\d/g).join('');
    limit = data && data.headers['x-pagination'].split(', ')[1].match(/\d/g).join('');
    count = Math.ceil(+ordersNumber / +limit);
    orders?.map(order => {
      let orderDated = '';
      if (order.date) {
        const ordate = new Date(order?.date);
        const day = ordate.getDate();
        const month = ordate.getMonth() + 1;
        const year = ordate.getFullYear();
        if (ordate && day && month && year) {
          orderDated = day + '.' + month + '.' + year;
        }
      }
      const orderAddress = order.customer && order.customer.address + ', ' + order.customer.city;
      if (order.status === 'created') {
        return newOrdersRows?.push(CreateData(order?.customer?.name, orderAddress, orderDated, order?.id, order?.isseen, false, true));
      }
      return completedOrdersRows?.push(CreateData(order?.customer?.name, orderAddress, orderDated, order?.id, true, order?.status, true));
    });
  }

  const newOrdersHeadCells = [
    {
      id: 'client',
      numeric: true,
      disablePadding: false,
      label: t('Client'),
      sortable: true,
    },
    {
      id: 'delivery-address',
      numeric: false,
      disablePadding: false,
      label: t('Delivery address'),
      sortable: false,
    },
    {
      id: 'delivery-date',
      numeric: true,
      disablePadding: false,
      label: t('Deliery date'),
      sortable: false,
    },
    {
      id: 'edit-order',
      numeric: false,
      disablePadding: false,
      label: t('Edit'),
      sortable: false,
    },
  ];

  const completedOrdersHeadCells = [
    {
      id: 'client',
      numeric: true,
      disablePadding: false,
      label: t('Client'),
      sortable: true,
    },
    {
      id: 'delivery-address',
      numeric: false,
      disablePadding: false,
      label: t('Delivery address'),
      sortable: false,
    },
    {
      id: 'delivery-date',
      numeric: true,
      disablePadding: false,
      label: t('Deliery date'),
      sortable: false,
    },
    {
      id: 'review',
      numeric: false,
      disablePadding: false,
      label: t('Review'),
      sortable: false,
    },
    {
      id: 'view-order',
      numeric: false,
      disablePadding: false,
      label: t('View more'),
      sortable: false,
    },
  ];
  return (
    <Box marginTop="50px">
      <Grid item xs={6} sm={4} md={3.2} position="absolute" top="12px" width="100%">
        <SearchBar
          name="search-orders"
          placeholder={t('Search by company name')}
          borderradius="30px"
          padding="10px 16px 10px 20px"
          margin="15px 0 15px"
          backgroundcolor="#fff"
          border="1px solid #ded9d9"
          display="flex"
          direction="row"
          justifycontent="space-between"
          placeholdercolor="#9c9c9c"
          setSearchedTerm={term => {
            if (page !== 1) {
              setPage(1);
            }
            setSearchedTerm(term);
          }}
          searchedTerm={searchedTerm}
        />
      </Grid>
      <Box position="relative">
        <Tabs tabsnames={tabsnames} setTab={setTab} setPage={setPage}>
          <Grid item xs={12}>
            <OrdersTable
              rows={newOrdersRows}
              headCells={newOrdersHeadCells}
              handleChangePage={setPage}
              refetch={setRefetch}
              statusLoad={status}
              count={count}
              page={page}
              setIsSeenOrder={setIsSeenOrder}
            />
          </Grid>
          <Grid item xs={12}>
            <OrdersTable
              rows={completedOrdersRows}
              headCells={completedOrdersHeadCells}
              handleChangePage={setPage}
              refetch={setRefetch}
              statusLoad={status}
              count={count}
              page={page}
              setIsSeenOrder={setIsSeenOrder}
            />
          </Grid>
        </Tabs>
        <CustomGrid item xs={12} md={4} lg={3} xl={2.2}>
          <OrderCalendar
            date={date}
            setDate={dateValue => {
              if (page !== 1) {
                setPage(1);
              }
              setDate(dateValue);
            }}
          />
        </CustomGrid>
      </Box>
    </Box>
  );
}

Orders.propTypes = {
  setIsSeenOrder: PropTypes.func,
};

export default Orders;
