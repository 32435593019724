import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputField from '../InputField';

function FullNameField({
  grid,
  borderradius,
  border,
  padding,
  bgcolor,
  fontsize,
  required,
  placeholdercolor,
  requiredcolor,
  handleChange,
  fullname,
}) {
  const { t } = useTranslation();

  return (
    <InputField
      name="full-name"
      type="text"
      grid={grid}
      label={t('Full name')}
      placeholder={t('Full name')}
      borderradius={borderradius}
      border={border}
      padding={padding}
      bgcolor={bgcolor}
      fontsize={fontsize}
      endAdornment={false}
      placeholdercolor={placeholdercolor}
      handleChange={handleChange}
      defaultValue={fullname}
      requiredcolor={requiredcolor}
      required={required}
    />
  );
}

FullNameField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  fontsize: PropTypes.number,
  bgcolor: PropTypes.string,
  border: PropTypes.string,
  placeholdercolor: PropTypes.string,
  handleChange: PropTypes.func,
  fullname: PropTypes.string,
  required: PropTypes.bool,
  requiredcolor: PropTypes.string,
};

FullNameField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  fontsize: 12,
  bgcolor: '#fff',
  border: '',
  placeholdercolor: '#efefef',
  required: false,
  requiredcolor: '#fff',
};

export default FullNameField;
