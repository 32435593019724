import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDeleteUserDeliveryAddress from '../../../Hooks/users/useDeleteUserDeliveryAddress';
import DeleteModal from '../DeleteModal';

function DeleteAssignedClientModal({ deliveryaddressid, supplieruserid, setDeletedAddress }) {
  const { t } = useTranslation();
  const [isDeleted, setIsDeleted] = useState(false);
  const { mutate: deleteUserDeliveryAddress } = useDeleteUserDeliveryAddress();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setIsDeleted(false);
  };
  const handleClose = () => setOpen(false);

  const handleRemoveDeliveryAddress = event => {
    event.preventDefault();
    return deleteUserDeliveryAddress(({ deliveryaddressid, supplieruserid }), {
      onSuccess: async () => {
        setIsDeleted(true);
        setDeletedAddress(deliveryaddressid);
      },
      onError: async () => {
        setIsDeleted(false);
      },
    });
  };

  return (
    <DeleteModal
      deletemodaltitle={t('Delete address')}
      deletemodaltext={t('Do you want to delete this address')}
      handleChange={handleRemoveDeliveryAddress}
      isDeleted={isDeleted}
      setIsDeleted={setIsDeleted}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
    />
  );
}

DeleteAssignedClientModal.propTypes = {
  deliveryaddressid: PropTypes.string,
  supplieruserid: PropTypes.string,
  setDeletedAddress: PropTypes.func,
};

export default DeleteAssignedClientModal;
