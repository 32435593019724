/* eslint-disable no-unused-expressions */
import { useState, useEffect } from 'react';

const useInViewport = (element, refVisible) => {
  const [offsetHorizontal, setOffsetHorizontal] = useState(0);
  const [offsetVertical, setOffsetVertical] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  useEffect(() => {
    if (refVisible && (element.current.scrollWidth > element.current.clientWidth)) {
      const value = element.current.scrollWidth - element.current.clientWidth;
      setOffsetHorizontal(value);
    }
    if (refVisible && (element.current.scrollHeight > element.current.clientHeight)) {
      const value = element.current.scrollHeight - element.current.clientHeight;
      setOffsetVertical(value);
      setClientHeight(element.current.clientHeight);
    }
  }, [refVisible, element]);

  return {
    offsetHorizontal,
    offsetVertical,
    clientHeight,
  };
};

export default useInViewport;
