import { useMutation } from 'react-query';
import axios from 'axios';

const env = n => (window.env || {})[n] || process.env[n];

const API_BASE = env('REACT_APP_API_BASE');
const API_USERNAME = env('REACT_APP_API_USERNAME');
const API_PASSWORD = env('REACT_APP_API_PASSWORD');

const logout = async (token) => axios({
  method: 'post',
  url: `${API_BASE}/logout/`,
  data: { token },
  auth: { username: API_USERNAME, password: API_PASSWORD },
});

const useLogout = () => useMutation(({ token }) => logout(token));

export default useLogout;
