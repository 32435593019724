/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/InputBase';
import styled from 'styled-components';
import { styled as mstyled } from '@mui/material/styles';

const CustomInput = mstyled(OutlinedInput)(props => ({
  borderRadius: props.borderradius,
  backgroundColor: props.bgcolor,
  padding: props.padding,
  border: props.requiredError ? '2px solid #F26B29' : props.border,
  fontSize: props.fontSize,
  '&.MuiInputBase-root.Mui-focused': {
    '& input': {
      caretColor: 'grey',
    },
  },
  '& input': {
    '&::placeholder': {
      fontSize: props.placeholderfontsize,
      color: props.requiredError ? '#F26B29' : props.placeholdercolor,
      opacity: 1,
      fontFamily: 'Gilroy, Roboto',
    },
  },
}));

const RequiredNotice = styled.div`
  font-size: 14px;
  color: ${props => props.requiredcolor || '#fff'};
  margin-bottom: 5px;
  display: flex;
`;

function InputField({
  grid,
  borderradius,
  border,
  padding,
  bgcolor,
  placeholdercolor,
  required,
  placeholderfontsize,
  fontsize,
  name,
  defaultValue,
  placeholder,
  type,
  endAdornment,
  requiredcolor,
  handleChange,
  label,
  labelcolor,
  errorMessage,
  maxLength,
  requiredError,
  margin,
}) {
  return (
    <Grid item xs={grid} sx={{ marginBottom: margin }}>
      {required && (
        <RequiredNotice requiredcolor={requiredcolor}>
          *
          {' '}
          <Typography fontSize="12px" marginLeft="2px">{label}</Typography>
        </RequiredNotice>
      )}
      {!required
        && (
        <Typography color={labelcolor} fontSize="12px">
          {label}
        </Typography>
        )}
      <CustomInput
        name={name}
        value={defaultValue}
        label={placeholder}
        fullWidth
        size="large"
        placeholdercolor={placeholdercolor}
        placeholderfontsize={placeholderfontsize || 12}
        borderradius={borderradius}
        border={border}
        bgcolor={bgcolor}
        padding={padding}
        id={name}
        type={type}
        requiredError={requiredError}
        placeholder={placeholder}
        endAdornment={endAdornment}
        fontSize={fontsize}
        inputProps={{ maxLength }}
        onChange={e => handleChange(e.target.value)}
      />
      {errorMessage
       && (
       <Typography color="#fbb140" fontSize="13px" fontWeight={600} position="absolute">
         {errorMessage}
       </Typography>
       )}
    </Grid>
  );
}

InputField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  bgcolor: PropTypes.string,
  border: PropTypes.string,
  required: PropTypes.bool,
  placeholdercolor: PropTypes.string,
  placeholderfontsize: PropTypes.number,
  fontsize: PropTypes.number,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  endAdornment: PropTypes.node,
  requiredcolor: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  labelcolor: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.string,
  requiredError: PropTypes.bool,
  margin: PropTypes.string,
};

InputField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  bgcolor: 'transparent',
  border: '',
  required: false,
  placeholdercolor: '#ccc',
  placeholderfontsize: 14,
  fontsize: 14,
  name: '',
  defaultValue: '',
  placeholder: '',
  type: 'text',
  endAdornment: null,
  requiredcolor: '#fff',
  label: '',
  labelcolor: '#a9a9a9',
  errorMessage: '',
  maxLength: '9999999',
  requiredError: false,
  margin: '0px',
};
export default InputField;
