/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ItemsTableComponent from '../ItemsTableComponent';
import PaginationComponent from '../../Pagination/Pagination';
import useSortableData from '../../../Hooks/useSortableData';
import TableSkeleton from '../../Skeletons/TableSkeleton';

function OrdersItemsTable({ ...props }) {
  const { t } = useTranslation();
  const [supplierComment, setSupplierComment] = useState({ orderId: '', comment: '' });
  const { items, requestSort, sortConfig } = useSortableData(props.rows);

  return (
    <>
      { props.statusLoad === 'loading'
        ? (
          <TableSkeleton />
        )
        : items.length === 0
          ? (
            <Typography color="secondary" fontSize={20}>
              {t('No items')}
            </Typography>
          )
          : (
            <ItemsTableComponent
              headCells={props.headCells}
              rows={items}
              direction={sortConfig?.direction}
              margin="5px"
              boxshadowtable="0px 3px 10px 4px #f6f6f6"
              boxshadowtablehead="0px 3px 3px 0px #f6f6f6"
              paddingcellhead="14px 0"
              borderradiustable={16}
              labelfontsize={14}
              labelcolor="#3384B9"
              labelfontweight={500}
              border="1px solid #f6f6f678"
              backgroundcoloroddrow="#F9F9F9"
              colorcell="#888888"
              paddingcell="12px 0"
              lasttypewidth="14vw"
              secondlasttypewidth="9vw"
              requestSort={requestSort}
              supplierComment={supplierComment}
              setSupplierComment={setSupplierComment}
            />
          )}
      {items.length !== 0
          && (
          <PaginationComponent
            handleChangePage={props.setPage}
            page={props.page}
            count={props.count}
          />
          )}
    </>
  );
}

export default OrdersItemsTable;
