import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const deleteUser = async (userid) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'delete',
    data: { userid },
    url: `/supplier/users/${userid}/`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useDeleteUser = () => useMutation(({ userid }) => deleteUser(userid));

export default useDeleteUser;
