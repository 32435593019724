/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import InputField from '../../Forms/InputFields/InputField';
import EditModal from '../EditModal';
import ReminderCalendar from '../../Calendar/ReminderCalendar/ReminderCalendar';
import RepeatReminder from '../../Reminder/RepeatReminderComponent';
import usePutReminder from '../../../Hooks/reminders/usePutReminder';

const CustomButtonConfirm = styled(Button)(() => ({
  background:
        'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  color: '#fff',
}));

function EditReminderModal({
  backgroundcoloropen,
  backgroundcolor,
  border,
  boxshadow,
  color,
  coloropen,
  backgroundcolorhover,
  colorhover,
  clientReminder,
  reminder,
  clientId,
  refetchReminders,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { mutate: putReminder } = usePutReminder();
  const [reminderRepeat, setReminderRepeat] = useState(null);
  const [reminderDate, setReminderDate] = useState(null);
  const [reminderName, setReminderName] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setIsEdited(false);
  };

  useEffect(() => {
    if (reminderRepeat && reminderDate && reminderName) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [reminderRepeat, reminderDate, reminderName]);

  const handleOpen = () => {
    setOpen(true);
    setReminderRepeat(reminder.reminderrepeat);
    setReminderDate(reminder.reminderdate);
    setReminderName(reminder.remindername);
  };

  const handleChange = event => {
    event.preventDefault();
    const data = {
      id: reminder.id,
      accountid: clientId,
      remindername: reminderName,
      reminderdate: reminderDate,
      reminderrepeat: reminderRepeat,
    };
    return putReminder(({ ...data }), {
      onSuccess: async () => {
        setIsEdited(true);
        refetchReminders();
      },
      onError: async () => {
      },
    });
  };

  return (
    <EditModal
      modaltitle={t('Edit reminder')}
      titlemargin="0 0 10px -10px"
      width={600}
      bgcolor="#fff"
      padding="30px 25px 30px 40px"
      buttonssize={1.8}
      isreminder
      backgroundcoloropen={backgroundcoloropen}
      backgroundcolor={backgroundcolor}
      backgroundcolorhover={backgroundcolorhover}
      border={border}
      boxshadow={boxshadow}
      color={color}
      coloropen={coloropen}
      colorhover={colorhover}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      handleChange={handleChange}
      isChanged={isEdited}
      disabledSubmitButton={isButtonDisabled}
    >
      {isEdited
        ? (
          <Box sx={{ margin: '30px 0 0', width: '100%' }}>
            <Typography color="secondary" sx={{ margin: '0 0 30px' }}>{t('Reminder edited')}</Typography>
            <CustomButtonConfirm
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              {t('Close')}
            </CustomButtonConfirm>
          </Box>
        )
        : (
          <Grid container spacing={1.2}>
            {!clientReminder
        && (
        <Grid item xs={12} display="flex" alignItems="center">
          <Typography
            sx={{ margin: '10px 10px 0 0', fontSize: '12px', color: '#a9a9a9' }}
          >
            Clients name:
          </Typography>
          <Typography sx={{
            margin: '10px 0 0', fontSize: '13px', color: '#3384B9', fontWeight: 500,
          }}
          >
            {reminder.companyname}
          </Typography>
        </Grid>
        )}
            <InputField
              grid={12}
              bgcolor="#fff"
              name="reminder-name"
              placeholder={t('Reminder name')}
              label={t('Reminder name')}
              borderradius={6}
              border="1px solid #ded9d9"
              padding="10px 18px"
              fontsize={12}
              defaultValue={reminderName}
              handleChange={setReminderName}
            />
            <Grid item xs={6}>
              <ReminderCalendar
                reminderdate={new Date(reminderDate)}
                setReminderDate={setReminderDate}
              />
            </Grid>
            <Grid item xs={6}>
              <RepeatReminder
                reminderrepeat={reminder.reminderrepeat}
                reminderdate={new Date(reminderDate)}
                setReminderRepeat={setReminderRepeat}
              />
            </Grid>
          </Grid>
        )}
    </EditModal>
  );
}

EditReminderModal.propTypes = {
  backgroundcoloropen: PropTypes.string,
  backgroundcolor: PropTypes.string,
  color: PropTypes.string,
  coloropen: PropTypes.string,
  border: PropTypes.string,
  boxshadow: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  colorhover: PropTypes.string,
  clientReminder: PropTypes.bool,
  reminder: PropTypes.shape({}),
  clientId: PropTypes.number,
  refetchReminders: PropTypes.func,
};

EditReminderModal.defaultProps = {
  backgroundcoloropen: '',
  backgroundcolor: '',
  color: '',
  coloropen: '',
  border: '',
  boxshadow: '',
  backgroundcolorhover: '',
  colorhover: '',
  clientReminder: false,
  reminder: {},
};

export default EditReminderModal;
