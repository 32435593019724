/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import SupplierInputField from '../../Forms/InputFields/RegisterFields/SupplierInputField';
import AddressInputField from '../../Forms/InputFields/RegisterFields/AddressInputField';
import CitiesSelectField from '../../Forms/SelectFields/CitiesSelectField';
import HiddenInput from '../../Forms/InputFields/HiddenInputField';
import ImageBox from '../../ImageBox';
import SettingsEditDataButton from './SupplierEditDataButton';
import ModalComponent from '../ModalComponent';

const CustomButtonConfirm = styled(Button)(() => ({
  background:
        'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  color: '#fff',
}));

function SupplierEditDataModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  const {
    setSuppliername, setCity, city, image, imageChanged, suppliername, setAddress,
    address, handleSubmitSupplierData, isChanged, refetch, data, isFetched, setIsChanged,
  } = props;

  useEffect(() => {
    if (city && image && suppliername && address) {
      setDisabledSubmitButton(false);
    } else {
      setDisabledSubmitButton(true);
    }
  }, [city, image, suppliername, address]);

  const handleOpen = () => {
    setOpen(true);
    setOffsetTop('50%');
    setOffsetLeft('50%');
    refetch();
    setIsChanged(false);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <SettingsEditDataButton
        handleClick={handleOpen}
        color="#fff"
        margin="0"
        padding="5px 0"
        borderradius={20}
        textsize={10}
        texttransform="uppercase"
        textweight={400}
        bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
      />
      <ModalComponent
        open={open}
        handleClose={handleClose}
        offsetTop={offsetTop}
        offsetLeft={offsetLeft}
        width={400}
        padding="15px 25px 20px"
        backgroundcolor="#f8f8f8"
        titlemargin="10px 10px 20px"
        color="secondary"
        title={t('Edit data')}
        buttonssize={3}
        issuppliermodal
        handleChange={handleSubmitSupplierData}
        isChanged={isChanged}
        disabledSubmitButton={disabledSubmitButton}
      >
        {isChanged
          ? (
            <Box sx={{ margin: '30px 0 0', width: '100%' }}>
              <Typography color="secondary" sx={{ margin: '0 0 30px' }}>{t('Supplier data changed')}</Typography>
              <CustomButtonConfirm
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                {t('Confirm')}
              </CustomButtonConfirm>
            </Box>
          )
          : (
            <Grid container spacing={1}>
              <SupplierInputField
                grid={6}
                bgcolor="#fff"
                borderradius={20}
                border="1px solid #ded9d9"
                padding="5px 15px"
                placeholdercolor="#9c9c9c"
                companyname={suppliername}
                handleChange={setSuppliername}
              />
              <AddressInputField
                grid={6}
                bgcolor="#fff"
                borderradius={20}
                border="1px solid #ded9d9"
                padding="5px 15px"
                placeholdercolor="#9c9c9c"
                address={address}
                handleChange={setAddress}
              />
              <CitiesSelectField
                grid={6}
                required
                bgcolor="#fff"
                borderradius={20}
                padding="10px 15px"
                requiredcolor="#a9a9a9"
                placeholdercolor="#ded9d9"
                data={data}
                isFetched={isFetched}
                handleChange={setCity}
                defaultValue={city}
              />
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={6}
                >
                  <label htmlFor="icon-button-file">
                    <HiddenInput
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      handleChange={imageChanged}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      sx={{
                        textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                      }}
                    >
                      <AddAPhotoIcon color="secondary" />
                      <Typography color="secondary" sx={{ fontSize: 14, marginLeft: 1 }}>
                        {t('Change logo')}
                      </Typography>
                    </IconButton>
                  </label>
                </Grid>
                <Grid
                  item
                  xs={6}
                  justifyContent="center"
                >
                  {image && (
                  <ImageBox
                    width="90px"
                    height="90px"
                    border="4px double #efefef"
                    borderradius={10}
                    margin="0 auto"
                    padding="0 12px"
                    objectFit="contain"
                    imgSrc={image}
                  />
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
      </ModalComponent>
    </>
  );
}

export default SupplierEditDataModal;
