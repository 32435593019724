/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import DeleteIconButton from './DeleteIconButton';

const CustomIconButtonContainer = styled.div`
    border-radius: 100%;
    z-index: ${props => props.zindex};
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${props => props.boxshadow};
    border: ${props => props.border};
    background: ${props => props.backgroundcolor};
    width: ${props => props.width};
    max-width: ${props => props.width};
    min-width: ${props => props.width};
    height: ${props => props.width};
    padding: ${props => props.padding};
    position: ${props => props.position};
    & svg {
        width: ${props => props.iconsize};
        height: ${props => props.iconsize};
        color: ${props => props.color};
        &:hover {
          color: ${props => (props.colorHover ? props.colorHover : props.color)};
        }
    }
     &:hover {
        background: ${props => props.backgroundcolorhover};
        border: ${props => props.borderhover};
        & svg path {
            stroke: ${props => props.colorhover};
            fill: ${props => props.colorhover};
        }
    }
  @media (max-width: 1024px) {
    position: inherit;
    z-index: 1;
  }
`;

const StyledDeleteIconButton = forwardRef((props, ref) => (
  <CustomIconButtonContainer
    boxshadow={props.boxshadow}
    border={props.border}
    backgroundcolor={props.backgroundcolor}
    width={props.width}
    padding={props.padding}
    colorhover={props.colorhover}
    backgroundcolorhover={props.backgroundcolorhover}
    borderhover={props.borderhover}
    color={props.color}
    iconsize={props.iconsize}
    zindex={props.zindex}
    position={props.position}
  >
    <DeleteIconButton
      ref={ref}
      handleClick={props.handleClick}
    />
  </CustomIconButtonContainer>
));

export default StyledDeleteIconButton;
