import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const CustomIconButton = styled(IconButton)((props) => ({
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: props.disable === 'true' ? '#ccc' : props.backgroundcolor,
  width: props.width,
  maxWidth: props.width,
  minWidth: props.width,
  height: props.height,
  boxShadow: props.boxshadow,
  fontSize: props.fontSize,
  padding: props.padding,
  margin: props.margin,
  cursor: props.disable === 'true' ? 'not-allowed' : 'pointer',
  '&:hover': {
    backgroundColor: props.disable === 'true' ? '#ccc' : props.backgroundcolorhover,
  },
}));

const CustomArrowIcon = styled(ArrowBackIosIcon)((props) => ({
  fill: props.iconcolor,
  fontSize: props.iconsize,
}));

function LeftArrowIconButton({
  width,
  height,
  margin,
  boxshadow,
  backgroundcolor,
  backgroundcolorhover,
  colorhover,
  color,
  padding,
  fontSize,
  iconsize,
  handleChange,
  backButtonDisable,
}) {
  return (
    <CustomIconButton
      aria-label="left-arrow"
      width={width}
      height={height}
      boxshadow={boxshadow}
      padding={padding}
      margin={margin}
      fontSize={fontSize}
      backgroundcolor={backgroundcolor}
      backgroundcolorhover={backgroundcolorhover}
      colorhover={colorhover}
      onClick={() => handleChange('back')}
      disable={backButtonDisable.toString()}
    >
      <CustomArrowIcon
        iconcolor={color}
        iconsize={iconsize}
      />
    </CustomIconButton>
  );
}

LeftArrowIconButton.propTypes = {
  handleChange: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.number,
  boxshadow: PropTypes.string,
  backgroundcolor: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  color: PropTypes.string,
  colorhover: PropTypes.string,
  padding: PropTypes.string,
  fontSize: PropTypes.number,
  iconsize: PropTypes.number,
  backButtonDisable: PropTypes.bool,
};
LeftArrowIconButton.defaultProps = {
  width: 45,
  height: 45,
  margin: 0,
  boxshadow: '',
  backgroundcolor: '#fff',
  backgroundcolorhover: '#fff',
  color: '#fff',
  colorhover: '#fff',
  padding: '',
  fontSize: 14,
  iconsize: 24,
  backButtonDisable: false,
};

export default LeftArrowIconButton;
