/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const putOrderStatus = async (id, orderStatus, notice) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'put',
    data: { orderstatus: orderStatus, supplierordernote: notice },
    url: `/orders/${id}/`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const usePutOrderStatus = () => useMutation(({ id, orderStatus, notice }) => putOrderStatus(id, orderStatus, notice));

export default usePutOrderStatus;
