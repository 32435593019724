/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const changeSupplierData = async (suppliername, address, city, image) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'put',
    data: {
      suppliername, address, city, logo_image: image,
    },
    url: '/supplier/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useChangeSupplierData = () => useMutation(({
  suppliername, address, city, image,
}) => changeSupplierData(suppliername, address, city, image));

export default useChangeSupplierData;
