/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import SortButtons from './SortButtons';

const CustomLabel = styled(TableSortLabel)(props => ({
  color: props.color,
  fontsize: props.fontSize,
  display: 'flex',
  alignItems: 'center',
  cursor: 'default',
  '& .MuiTableSortLabel-icon': {
    display: 'none',
  },
  '&:hover': {
    color: props.color,
  },
}));

const CustomTableCell = styled(TableCell)(props => ({
  padding: props.paddingcell,
  border: 'transparent',
  color: props.colorcell,
  cursor: 'default',
  width: props.width,
  '&:first-of-type': {
    paddingLeft: 20,
  },
  '&.table-head': {
    maxWidth: '200px',
    minWidth: '200px',
    '&:nth-last-of-type(1)': {
      maxWidth: '60px',
      minWidth: '60px',
    },
    '&:nth-last-of-type(2)': {
      maxWidth: props.customwidth ? props.customwidth : '200px',
      minWidth: props.customwidth ? props.customwidth : '200px',
    },
  },
  '&.confirm-quantity': {
    maxWidth: '150px',
    minWidth: '150px',
  },
}));

const CustomTableRow = styled(TableRow)(props => ({
  '&:nth-of-type(odd)': {
    backgroundColor: props.backgroundcolor,
    borderBottom: props.border,
  },
  '&:last-of-type': {
    borderRadius: '0 0 12px 12px',
  },
}));

const CustomHeadTableRow = styled(TableRow)(props => ({
  borderBottom: props.border,
  boxShadow: props.boxshadow,
}));

const CustomTableContainer = styled(TableContainer)(({
  boxshadow, borderradius,
}) => ({
  boxShadow: boxshadow,
  borderRadius: borderradius,
  margin: 'auto',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
  },
}));

function ItemsTableComponent(props) {
  const {
    margin,
    boxshadowtable,
    borderradiustable,
    boxshadowtablehead,
    border,
    labelcolor,
    headCells,
    paddingcellhead,
    labelfontweight,
    labelfontsize,
    direction,
    requestSort,
    rows,
    backgroundcoloroddrow,
    paddingcell,
  } = props;

  return (
    <CustomTableContainer
      margin={margin}
      boxshadow={boxshadowtable}
      borderradius={borderradiustable}
    >
      <Table>
        <TableHead>
          <CustomHeadTableRow
            boxshadow={boxshadowtablehead}
            border={border}
            color={labelcolor}
          >
            {headCells.map(
              cell => (
                <CustomTableCell
                  className="table-head"
                  paddingcell={paddingcellhead}
                  key={cell.id}
                  align="left"
                  padding={cell ? 'none' : 'normal'}
                  customwidth={cell.width}
                >
                  <CustomLabel
                    color={labelcolor}
                    fontWeight={labelfontweight}
                    fontSize={labelfontsize}
                  >
                    {cell.label}
                    {cell.sortable && (
                      <SortButtons
                        id={cell.id}
                        direction={direction}
                        requestSort={requestSort}
                      />
                    )}
                  </CustomLabel>
                </CustomTableCell>
              ),
            )}
          </CustomHeadTableRow>
        </TableHead>
        <TableBody>
          {
            rows && rows.map((row, index) => {
              const cellNames = Object.keys(row);
              return (
                <CustomTableRow
                  key={index}
                  border={border}
                  color={labelcolor}
                  backgroundcolor={backgroundcoloroddrow}
                >
                  {cellNames && cellNames.map((el, index) => (el === 'id'
                    ? false
                    : (
                      <CustomTableCell
                        key={index}
                        paddingcell={paddingcell}
                      >
                        {row[el]}
                      </CustomTableCell>
                    )
                  ))}
                </CustomTableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
}

ItemsTableComponent.propTypes = {
  requestSort: PropTypes.func.isRequired,
  direction: PropTypes.string,
  margin: PropTypes.string,
  boxshadowtable: PropTypes.string,
  borderradiustable: PropTypes.number,
  boxshadowtablehead: PropTypes.string,
  border: PropTypes.string,
  labelcolor: PropTypes.string,
  headCells: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    label: PropTypes.string,
    sortable: PropTypes.bool,
    width: PropTypes.string,
  })),
  paddingcellhead: PropTypes.string,
  labelfontweight: PropTypes.number,
  labelfontsize: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.shape()),
  backgroundcoloroddrow: PropTypes.string,
  paddingcell: PropTypes.string,
};

ItemsTableComponent.defaultProps = {
  direction: '',
  margin: '',
  boxshadowtable: '',
  borderradiustable: 6,
  boxshadowtablehead: '',
  border: '',
  labelcolor: '',
  headCells: [],
  paddingcellhead: '',
  labelfontweight: 400,
  labelfontsize: 12,
  rows: [],
  backgroundcoloroddrow: '',
  paddingcell: '',
};

export default ItemsTableComponent;
