/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const changePassword = async (oldPassword, newPassword) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'post',
    data: { old_password: oldPassword, password: newPassword },
    url: '/supplier/user/change/password/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useChangePassword = () => useMutation(({ oldPassword, newPassword }) => changePassword(oldPassword, newPassword));

export default useChangePassword;
