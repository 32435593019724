import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import StyledEditIconButton from '../IconButtons/EditIconButton/StyledEditIconButton';
import ModalComponent from './ModalComponent';

function EditModal({
  children,
  width,
  padding,
  bgcolor,
  titlemargin,
  modaltitle,
  buttonssize,
  backgroundcoloropen,
  backgroundcolor,
  color,
  coloropen,
  border,
  boxshadow,
  isreminder,
  handleChange,
  open,
  isChanged,
  handleClose,
  handleOpen,
  disabledSubmitButton,
  isLoading,
}) {
  const [offsetTop, setOffsetTop] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);

  return (
    <div>
      <StyledEditIconButton
        ref={(el) => {
          if (!el) return;
          setOffsetTop(el.getBoundingClientRect().top);
          setOffsetLeft(el.getBoundingClientRect().left);
        }}
        handleClick={open ? handleClose : handleOpen}
        width="30px"
        color={open ? coloropen : color}
        border={open ? 'none' : border}
        boxshadow={open ? 'none' : boxshadow}
        backgroundcolor={open ? backgroundcoloropen : backgroundcolor}
        backgroundcolorhover={backgroundcoloropen}
        borderhover="none"
        boxshadowhover="none"
        colorhover={open ? '#fff' : coloropen}
        iconsize="23px"
        position={open ? 'relative' : 'inherit'}
        padding="12px 10px"
      />
      <ModalComponent
        open={open}
        handleClose={handleClose}
        offsetTop={offsetTop}
        offsetLeft={offsetLeft}
        width={width || 270}
        padding={padding}
        backgroundcolor={bgcolor}
        titlemargin={titlemargin}
        color="secondary"
        isreminder={isreminder}
        buttonssize={buttonssize}
        title={modaltitle}
        handleChange={handleChange}
        isChanged={isChanged}
        disabledSubmitButton={disabledSubmitButton}
        isLoading={isLoading}
      >
        <Grid container spacing={1.1}>
          {children}
        </Grid>
      </ModalComponent>
    </div>
  );
}

EditModal.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  padding: PropTypes.string,
  bgcolor: PropTypes.string,
  titlemargin: PropTypes.string,
  modaltitle: PropTypes.string,
  buttonssize: PropTypes.number,
  backgroundcoloropen: PropTypes.string,
  backgroundcolor: PropTypes.string,
  color: PropTypes.string,
  coloropen: PropTypes.string,
  border: PropTypes.string,
  boxshadow: PropTypes.string,
  isreminder: PropTypes.bool,
  handleChange: PropTypes.func,
  isChanged: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  open: PropTypes.bool,
  disabledSubmitButton: PropTypes.bool,
  isLoading: PropTypes.bool,
};

EditModal.defaultProps = {
  width: 350,
  padding: '15px 25px 20px',
  bgcolor: '#f8f8f8',
  titlemargin: '0 10px 10px',
  modaltitle: '',
  buttonssize: 2,
  backgroundcoloropen: 'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  backgroundcolor: '#F9F9F9',
  color: '#3384B9',
  coloropen: '#fff',
  border: '1px solid #f6f6f6',
  boxshadow: '-3px 2px 3px 0px #e5e3e3',
  isreminder: false,
  isChanged: false,
  open: false.valueOf,
  disabledSubmitButton: false,
  isLoading: false,
};

export default EditModal;
