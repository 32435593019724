import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { styled as mstyled } from '@mui/material/styles';

const CustomImageBox = mstyled(Box)((props) => ({
  width: props.width,
  height: props.height,
  objectFit: props.objectFit,
  margin: props.margin,
  padding: props.padding,
  border: props.border,
  borderRadius: props.borderradius,
  verticalAlign: 'middle',
  display: 'flex',
  backgroundColor: props.backgroundcolor,
}));
const ImageStyled = styled.img`
  margin: auto;
`;

function ImageBox(props) {
  const {
    width,
    height,
    objectFit,
    border,
    padding,
    borderradius,
    margin,
    backgroundcolor,
    imgSrc,
  } = props;
  return (
    <CustomImageBox
      width={width}
      height={height}
      objectFit={objectFit}
      border={border}
      padding={padding}
      borderradius={borderradius}
      margin={margin}
      backgroundcolor={backgroundcolor}
    >
      <ImageStyled alt="Order Engine" width="100%" height="auto" src={imgSrc} />
    </CustomImageBox>
  );
}

ImageBox.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  objectFit: PropTypes.string,
  border: PropTypes.string,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  margin: PropTypes.string,
  backgroundcolor: PropTypes.string,
  imgSrc: PropTypes.string,
};

ImageBox.defaultProps = {
  width: '60px',
  height: '60px',
  objectFit: 'contain',
  border: 'none',
  padding: '',
  borderradius: 0,
  margin: '',
  backgroundcolor: 'transparent',
  imgSrc: '',
};

export default ImageBox;
