import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import DeleteEmailNoticeModal from '../../Modals/EmailNotice/DeleteEmailNoticeModal';

const CustomTypographyHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.blue.main,
  fontWeight: 600,
  fontSize: 16,
}));

const CustomTypographyText = styled(Typography)(() => ({
  color: '#6e6e6e',
  fontWeight: 400,
  fontSize: 12,
  margin: '10px 0 0',
}));

const CustomGridContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: '1px solid #ded9d9',
  margin: '70px 0 20px',
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '13px',
  fontWeight: 600,
  backgroundColor: '#429dd9',
  borderRadius: '20px',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: theme.palette.blue.main,
  padding: '5px 10px',
  marginBottom: '10px',
  textAlign: 'center',
}));

const CustomMailNoticed = styled(Box)(() => ({
  color: '#9c9c9c',
  fontSize: '13px',
  fontWeight: 600,
  border: '1px solid #ded9d9',
  padding: '0px 5px 0 10px',
  textAlign: 'center',
  borderRadius: 50,
  display: 'flex',
  alignItems: 'center',
  margin: '10px 2px',
}));

function MailNotice({
  handleChange, emailNotice, message,
  emailsNoticed, emailErrorMessage, setEmailsNotice, refetchEmailsData,
  setMessage, setEmailsNoticeArr,
}) {
  const { t } = useTranslation();
  const emailsNoticedArr = emailsNoticed?.map((noticed, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <CustomMailNoticed key={index}>
      {noticed}
      <DeleteEmailNoticeModal
        value={noticed}
        emailsNoticed={emailsNoticed}
        refetch={refetchEmailsData}
        setMessage={setMessage}
        setEmailsNoticeArr={setEmailsNoticeArr}
      />
    </CustomMailNoticed>
  ));

  return (
    <CustomGridContainer container spacing={2}>
      <Grid item xs={12}>
        <CustomTypographyHeading>{t('Set mail notice')}</CustomTypographyHeading>
        <CustomTypographyText>{t('An e-mail notification of each new order will be sent to the users in the following field: ')}</CustomTypographyText>
      </Grid>
      <Grid item xs={12}>
        <Box
          component="form"
          borderBottom="1px solid #ded9d9"
          display="flex"
          flexWrap="wrap"
        >
          {emailsNoticedArr}
          <Box display="flex" position="relative" width="100%">
            <Input
              sx={{
                width: 'fit-content',
                padding: '0 10px',
                minWidth: '10px',
                '& input': {
                  caretColor: 'grey',
                },
              }}
              variant="standard"
              value={emailNotice}
              placeholder={t('Add new email')}
              onChange={e => setEmailsNotice(e.target.value)}
              autoFocus
              disableUnderline
              endAdornment={!emailErrorMessage && emailNotice ? (
                <InputAdornment position="end" sx={{ px: 2 }}>
                  <IconButton
                    aria-label="delete-item"
                    onClick={handleChange}
                    edge="end"
                  >
                    <CheckCircleOutlinedIcon color="blue" />
                  </IconButton>
                </InputAdornment>
              ) : false}
            />
            {emailErrorMessage
            && (
            <Typography color="#fbb140" fontSize="13px" fontWeight={600} position="absolute" bottom="-30px">
              {emailErrorMessage}
            </Typography>
            )}
          </Box>
        </Box>
      </Grid>
      { message
            && (
            <Grid item xs={12} marginTop={2}>
              <CustomTypography>{message}</CustomTypography>
            </Grid>
            )}
    </CustomGridContainer>
  );
}

MailNotice.propTypes = {
  emailNotice: PropTypes.string,
  message: PropTypes.string,
  handleChange: PropTypes.func,
  emailsNoticed: PropTypes.arrayOf(PropTypes.string),
  emailErrorMessage: PropTypes.string,
  setEmailsNotice: PropTypes.func,
  refetchEmailsData: PropTypes.func,
  setMessage: PropTypes.func,
  setEmailsNoticeArr: PropTypes.func,
};

export default MailNotice;
