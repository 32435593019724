import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const CustomHiddenInput = styled('input')({
  display: 'none',
});

function HiddenInput({
  accept,
  id,
  type,
  handleChange,
}) {
  return (
    <CustomHiddenInput
      accept={accept}
      id={id}
      type={type}
      onChange={(e) => handleChange(e)}
    />
  );
}

HiddenInput.propTypes = {
  accept: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  handleChange: PropTypes.func,
};

HiddenInput.defaultProps = {
  accept: '',
  id: '',
  type: '',
};

export default HiddenInput;
