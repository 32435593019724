import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled as mstyled } from '@mui/material/styles';
import styled from 'styled-components';
import useGetDeliveryAddresses from '../../Hooks/users/useGetDeliveryAddresses';
import usePostUserDeliveryAddress from '../../Hooks/users/usePostUserDeliveryAddress';
import AssignClientForm from '../Forms/Users/AssignClientForm';
import ImageBox from '../ImageBox';
import DeleteAssignedClientModal from '../Modals/UsersModal/DeleteAssignedClient';
import DeleteUser from '../Modals/UsersModal/DeleteUser';

const CustomWrapperBox = styled.div`
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: -1px 3px 5px 1px #e5e3e3;
    clear: both;
    margin: 100px 0;
`;

const CustomOrangeBox = styled.div`
    background: linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    padding: 30px;
    margin: -40px 0 30px;
`;

const CustomModalBox = styled.div`
    position: absolute;
    bottom: calc(100% - 50px);
    right: 20px;
    width: 60px;
    height: 60px;
`;

const CustomAddressesBox = mstyled(Box)(() => ({
  fontWeight: 400,
  fontSize: 14,
  color: '#6e6e6e',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  fontFamily: 'Gilroy',
  padding: '0 10px',
  height: 'fit-content',
}));

const UserComponent = ({
  id, username, fullname, deliveryaddresses, logourl, users, setUsers,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [deletedAddress, setDeletedAddress] = useState(null);
  const [addresses, setAddresses] = useState(deliveryaddresses);
  const [disabledAddNewUserButton, setDisabledAddNewUserButton] = useState(true);
  const {
    data,
    isFetched,
  } = useGetDeliveryAddresses();
  const { mutate: postUserDeliveryAddress } = usePostUserDeliveryAddress();

  useEffect(() => {
    const addressesArr = addresses.filter(addressEl => addressEl.id !== deletedAddress);
    setAddresses(addressesArr);
  }, [deletedAddress]);

  useEffect(() => {
    if (address) {
      setDisabledAddNewUserButton(false);
    } else {
      setDisabledAddNewUserButton(true);
    }
  }, [address]);

  const handleAddUser = event => {
    event.preventDefault();
    const supplieruserid = id;
    const deliveryaddressid = address;
    return postUserDeliveryAddress(({ deliveryaddressid, supplieruserid }), {
      onSuccess: async () => {
        const addedAddress = data.data.addresses.find(addressEl => addressEl.id === address);
        addresses.push(addedAddress);
        setAddress('');
      },
      onError: async () => {
      },
    });
  };

  const clients = addresses.map(addressEl => (
    <CustomAddressesBox key={addressEl.id}>
      <DeleteAssignedClientModal
        deliveryaddressid={addressEl.id}
        supplieruserid={id}
        setDeletedAddress={setDeletedAddress}
      />
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 500,
          textAlign: 'left',
        }}
      >
        {addressEl.address}
      </Typography>
    </CustomAddressesBox>
  ));

  return (
    <CustomWrapperBox>
      <CustomOrangeBox>
        <ImageBox
          width="50px"
          height="50px"
          padding="10px"
          backgroundcolor="#f8f8f8"
          borderradius={10}
          objectFit="contain"
          imgSrc={logourl}
        />
        <Box marginLeft={2}>
          <Typography color="primary" sx={{ fontWeight: 600, letterSpacing: 1, textAlign: 'left' }}>
            {fullname}
          </Typography>
          <Typography
            color="primary"
            sx={{
              fontSize: 12,
              fontWeight: 400,
              textAlign: 'left',
              '& a': { color: '#fff', marginLeft: 1 },
            }}
          >
            {t('Email')}
            :
            <a href={`mailto:${username}`}>{username}</a>
          </Typography>
          <CustomModalBox>
            <DeleteUser userid={id} users={users} setUsers={setUsers} />
          </CustomModalBox>
        </Box>
      </CustomOrangeBox>
      {clients}
      <AssignClientForm
        data={data}
        open={open}
        isFetched={isFetched}
        setOpen={setOpen}
        handleChange={setAddress}
        defaultValue={address}
        handleAddUser={handleAddUser}
        disabledAddNewUserButton={disabledAddNewUserButton}
      />
    </CustomWrapperBox>
  );
};

UserComponent.propTypes = {
  deliveryaddresses: PropTypes.arrayOf(PropTypes.shape()),
  fullname: PropTypes.string,
  username: PropTypes.string,
  id: PropTypes.string,
  logourl: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape()),
  setUsers: PropTypes.func,
};

UserComponent.defaultProps = {
  deliveryaddresses: [],
  fullname: '',
  username: '',
};

export default UserComponent;
