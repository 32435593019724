import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Tooltip from '@mui/material/Tooltip';

const CustomIconButton = styled(IconButton)(props => ({
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: props.backgroundcolor,
  width: props.width,
  maxWidth: props.width,
  minWidth: props.width,
  height: props.height,
  boxShadow: props.boxshadow,
  fontSize: props.fontSize,
  padding: props.padding,
  margin: props.margin,
  '&:hover': {
    backgroundColor: props.backgroundcolorhover,
  },
}));

const CustomArrowIcon = styled(AutorenewIcon)(props => ({
  fill: props.iconcolor,
  fontSize: props.iconsize,
}));

function RefreshIconButton({
  handleClick,
  width,
  height,
  margin,
  boxshadow,
  backgroundcolor,
  backgroundcolorhover,
  colorhover,
  color,
  padding,
  fontSize,
  iconsize,
}) {
  const { t } = useTranslation();

  return (
    <Tooltip variant="solid" title={t('Show all')} arrow>
      <CustomIconButton
        onClick={handleClick}
        aria-label="right-arrow"
        width={width}
        height={height}
        boxshadow={boxshadow}
        padding={padding}
        margin={margin}
        fontSize={fontSize}
        backgroundcolor={backgroundcolor}
        backgroundcolorhover={backgroundcolorhover}
        colorhover={colorhover}
      >
        <CustomArrowIcon
          iconcolor={color}
          iconsize={iconsize}
        />
      </CustomIconButton>

    </Tooltip>
  );
}

RefreshIconButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.number,
  boxshadow: PropTypes.string,
  backgroundcolor: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  color: PropTypes.string,
  colorhover: PropTypes.string,
  padding: PropTypes.string,
  fontSize: PropTypes.number,
  iconsize: PropTypes.number,
};
RefreshIconButton.defaultProps = {
  width: 45,
  height: 45,
  margin: 0,
  boxshadow: '',
  backgroundcolor: '#fff',
  backgroundcolorhover: '#fff',
  color: '#fff',
  colorhover: '#fff',
  padding: '',
  fontSize: 14,
  iconsize: 24,
};

export default RefreshIconButton;
