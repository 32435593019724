import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getOrderItems = async param => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: `/orders/${param}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetOrderItems = param => useQuery(['items', param], () => getOrderItems(param), { enabled: false });

export default useGetOrderItems;
