/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const sendEmailNotice = async (emails) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'put',
    data: emails,
    url: '/supplier/notifymails/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useSendEmailNotice = () => useMutation(({ emails }) => sendEmailNotice(emails));

export default useSendEmailNotice;
