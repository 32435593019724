import { useMutation } from 'react-query';
import axios from 'axios';
import { API_BASE, buff } from '../../utils/custom-authentication';

const forgotPassword = async email => axios({
  method: 'post',
  url: `${API_BASE}/supplier/forgot/password/`,
  data: { email },
  headers: {
    'X-AppAuth': buff,
  },
});

const useForgotPassword = () => useMutation(({ email }) => forgotPassword(email));

export default useForgotPassword;
