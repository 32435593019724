/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { forwardRef } from 'react';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const DeleteIconButton = forwardRef((props, ref) => (
  <IconButton
    ref={ref}
    aria-label="delete-item"
    sx={{ padding: 0 }}
    onClick={() => props.handleClick()}
  >
    <HighlightOffIcon />
  </IconButton>
));

export default DeleteIconButton;
