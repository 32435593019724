/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import useGetOrderItems from '../../../Hooks/orders/useGetOrderItems';
import useGetOrder from '../../../Hooks/orders/useGetOrder';
import useGetOrderItemsXls from '../../../Hooks/orders/useGetOrderItemsXls';
import useGetOrderItemsPdf from '../../../Hooks/orders/useGetOrderItemsPdf';
import usePutOrderStatus from '../../../Hooks/orders/usePutOrderStatus';
import usePutIsseenOrder from '../../../Hooks/orders/usePutIsseenOrder';
import ImageBox from '../../ImageBox';
import PdfIconButton from '../../IconButtons/PdfIconButton';
import ExcelIconButton from '../../IconButtons/ExcelIconButton';
import OrdersItemsTable from '../../Tables/Orders/OrdersItemsTable';
import CommentModal from './CommentModal';
import OrderDetailsSkeleton from '../../Skeletons/OrderDetailsSkeleton';

const CustomModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  backgroundColor: 'transparent',
  borderRadius: 6,
  boxShadow: 24,
  padding: '35px 25px',
  maxHeight: '90vh',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
  },
  [theme.breakpoints.up('md')]: {
    width: '90%',
  },
}));

const CustomLeftGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 6,
  boxShadow: 24,
  padding: '35px 25px',
  width: '78%',
  marginRight: '15px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const CustomRightGrid = styled(Grid)(({ theme }) => ({
  background: 'linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 6,
  boxShadow: 24,
  padding: '50px 25px',
  width: '20%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
}));

const CustomContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& .MuiTypography-root': {
    textAlign: 'center',
  },
  '& .order-info': {
    margin: '15px',
  },
  '& .buttons-container': {
    paddingTop: '160px',
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiTypography-root': {
      textAlign: 'left',
    },
    '& .order-info': {
      margin: 0,
    },
    '& .buttons-container': {
      paddingTop: '20px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
  },
}));

const CustomTypography = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 14,
  letterSpacing: 1.5,
  textAlign: 'center',
}));

const CustomIconBox = styled(Box)(() => ({
  backgroundColor: '#fff',
  width: 35,
  height: 35,
  padding: 0,
  borderRadius: '50%',
  boxShadow: '-7px 3px 15px -6px #302f2f',
  border: '1px solid #EAEAEA',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function CreateData(code, name, quantity, id) {
  return {
    code,
    name,
    quantity,
    id,
  };
}

function EditOrderModal({ ...props }) {
  const [open, setOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const { mutate: putIsseenOrder } = usePutIsseenOrder();
  const handleOpen = () => {
    setOpen(true);
    setIsConfirmed(false);
    setIsRejected(false);
    putIsseenOrder({ id: props.orderId });
  };
  const handleClose = () => {
    setOpen(false);
    props.refetchOrders(true);
  };
  const [comment, setComment] = useState('');
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [param, setParam] = useState(`${props.orderId}/items?page=${page}`);
  let count = 0;
  let limit = 10;
  const {
    data,
    isFetched,
    status: itemsStatus,
    refetch,
  } = useGetOrderItems(param, {
    refetchOnWindowFocus: false,
    enabled: false, // disable this query from automatically running
  });
  useEffect(() => {
    setParam(`${props.orderId}/items?page=${page}`);
    setTimeout(() => {
      if (open) {
        refetch();
      }
    }, [10]);
  }, [page]);
  const {
    data: dataOrder,
    isFetched: isFetchedOrder,
    status: getOrderStatus,
    refetch: refetchOrder,
  } = useGetOrder(props.orderId);
  const {
    refetch: refetchOrderItemsXls,
  } = useGetOrderItemsXls(props.orderId);
  const {
    refetch: refetchOrderItemsPdf,
  } = useGetOrderItemsPdf(props.orderId);
  const { mutate: putOrderStatus } = usePutOrderStatus();
  const rows = [];
  let order = {};

  if (isFetched) {
    const orderItems = data && data?.data && data.data.slice(0, 10);
    const ordersNumber = data && data.headers['x-pagination'].split(', ')[0].match(/\d/g).join('');
    limit = data && data.headers['x-pagination'].split(', ')[1].match(/\d/g).join('');
    count = Math.ceil(+ordersNumber / +limit);
    orderItems?.map(item => rows.push(
      CreateData(item.item_code, item.name, item.amount, item.id),
    ));
  }

  if (isFetchedOrder && dataOrder && dataOrder?.data) {
    order = dataOrder.data;
    if (!props.seen) {
      props.setIsSeenOrder(true);
    } else {
      props.setIsSeenOrder(false);
    }
  }

  const headCells = [
    {
      id: 'code',
      numeric: true,
      disablePadding: false,
      label: t('Item code'),
      sortable: false,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('Item name'),
      sortable: false,
    },
    {
      id: 'quantity',
      numeric: true,
      disablePadding: false,
      label: t('Quantity'),
      sortable: false,
    },
  ];

  const handleSubmit = (event, status) => {
    event.preventDefault();
    const orderStatus = status;
    const id = props.orderId;
    const notice = comment;
    return putOrderStatus(({ id, orderStatus, notice }), {
      onSuccess: async () => {
        if (orderStatus === 'accepted') {
          setIsConfirmed(true);
        } else if (orderStatus === 'rejected') {
          setIsRejected(true);
        }
      },
      onError: async () => {
        setIsConfirmed(false);
        setIsRejected(false);
        props.refetchOrders(true);
      },
    });
  };
  const returnArticles = order?.returnarticles ? t('Yes') : t('No');

  const returnPackage = order?.returnpackage ? t('Yes') : t('No');

  return (
    <>
      <Button
        onClick={() => {
          handleOpen();
          refetchOrder();
          refetch();
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: 14,
          textTransform: 'none',
        }}
      >
        {props.children}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomModalBox
          component="form"
          noValidate
          // eslint-disable-next-line react/destructuring-assignment
          onSubmit={props.handleSubmitPassword}
        >
          <CustomGridContainer
            container
            spacing={2}
          >
            <CustomLeftGrid>
              <OrdersItemsTable
                rows={rows}
                headCells={headCells}
                statusLoad={itemsStatus}
                setPage={setPage}
                count={count}
                page={page}
              />
            </CustomLeftGrid>
            <CustomRightGrid>
              <Button
                onClick={handleClose}
                sx={{ position: 'absolute', top: -20, right: -25 }}
              >
                <CustomIconBox>
                  <CloseIcon color="secondary" sx={{ fontSize: 25 }} />
                </CustomIconBox>
              </Button>
              { getOrderStatus !== 'loading'
                ? (
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <ImageBox
                      width="70px"
                      height="70px"
                      padding="10px"
                      margin="35px 0 20px"
                      backgroundcolor="#f8f8f8"
                      borderradius={10}
                      objectFit="contain"
                      imgSrc={order?.logo}
                    />
                    <CustomContentContainer>
                      <Typography color="primary" fontWeight={600} fontSize={18} letterSpacing={1}>
                        {order?.customer?.name}
                      </Typography>
                      <CustomTypography color="primary">
                        {order?.customer?.address}
                        ,
                        {order?.customer?.city}
                      </CustomTypography>
                      <CustomTypography color="primary">
                        {t('Tin')}
                        :
                        {order?.customer?.pib}
                      </CustomTypography>
                      <Box className="order-info" margin={2}>
                        <CustomTypography color="primary">
                          {t('Payment type')}
                          :
                          {order?.paymenttype}
                        </CustomTypography>
                        <CustomTypography color="primary">
                          {t('Item return')}
                          :
                          {returnArticles}
                        </CustomTypography>
                        <CustomTypography color="primary">
                          {t('Packaging return')}
                          :
                          {returnPackage}
                        </CustomTypography>
                        {order?.userordernote && (
                        <CustomTypography color="primary">
                          {t('Comment')}
                          :
                          {order?.userordernote}
                        </CustomTypography>
                        )}
                      </Box>
                      <CustomTypography color="primary" marginBottom={2}>
                        {t('Download the order')}
                        :
                      </CustomTypography>
                    </CustomContentContainer>
                  </Box>
                )
                : (
                  <OrderDetailsSkeleton />
                )}
              <CustomContentContainer>
                <Box display="flex" justifyContent="center">
                  <PdfIconButton marginright={15} handleClick={refetchOrderItemsPdf} />
                  <ExcelIconButton handleClick={refetchOrderItemsXls} />
                </Box>
                {props.editOrder
                  && (
                  <Box className="buttons-container" display="flex" justifyContent="center">
                    <Grid
                      container
                      spacing={2}
                      sx={{ justifyContent: 'center' }}
                    >
                      <Grid item xs={6} md={3} lg={4}>
                        <CommentModal
                          buttontitle={t('Reject')}
                          modaltitle={t('Rejecting Order')}
                          color="#747474"
                          borderradius={16}
                          textsize={10}
                          padding="5px 25px"
                          margin="0"
                          bgcolor="#fff"
                          handleCloseParentModal={handleClose}
                          handleChange={setComment}
                          comment={comment}
                          status="rejected"
                          handleSubmit={handleSubmit}
                          isRejected={isRejected}
                          refetch={props.refetchOrders}
                          confirmedText={t('Order rejected')}
                        />
                      </Grid>
                      <Grid item xs={6} md={3} lg={4}>
                        <CommentModal
                          buttontitle={t('Accept')}
                          modaltitle={t('Accepting Order')}
                          color="#F56F4B"
                          borderradius={16}
                          textsize={10}
                          padding="5px 25px"
                          margin="0"
                          bgcolor="#fff"
                          variant="contained"
                          handleCloseParentModal={handleClose}
                          handleChange={setComment}
                          comment={comment}
                          status="accepted"
                          handleSubmit={handleSubmit}
                          isConfirmed={isConfirmed}
                          refetch={props.refetchOrders}
                          confirmedText={t('Order confirmed')}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  )}
              </CustomContentContainer>
            </CustomRightGrid>
          </CustomGridContainer>
        </CustomModalBox>
      </Modal>
    </>
  );
}

export default EditOrderModal;
