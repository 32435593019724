import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getReturns = async param => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: `/returns/${param}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetReturns = param => useQuery(['returns', param], () => getReturns(param));

export default useGetReturns;
