import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const deleteItem = async (id) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'delete',
    url: `/products/${id}/`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useDeleteItem = () => useMutation(({ id }) => deleteItem(id));

export default useDeleteItem;
