/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import styled from 'styled-components';
import { styled as mstyled } from '@mui/material/styles';
import ImageBox from '../ImageBox';
import logo from '../../images/logo.png';

const CustomWrapperBox = styled.div`
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: -1px 3px 5px 1px #e5e3e3;
    clear: both;
`;

const CustomOrangeBox = styled.div`
    background: linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    padding: 40px 10px;
    margin-top: -40px;
`;

const CustomLabel = mstyled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  letterSpacing: 0.5,
  color: theme.palette.secondary.main,
  textAlign: 'center',
  margin: '20px 0 10px',
}));

const CustomAddressesBox = mstyled(Box)(() => ({
  fontWeight: 400,
  fontSize: 14,
  color: '#6e6e6e',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '0 10px',
}));

function ClientDataComponent({ client }) {
  const { t } = useTranslation();
  const { addresses, companyname, pib } = client;

  const addressesArray = addresses && addresses.map((address, index) => (
    <CustomAddressesBox key={index}>
      <PinDropOutlinedIcon color="blue" sx={{ marginRight: 2 }} />
      <Typography fontSize="14px">
        {address.address}
        ,
        {address.objectName}
      </Typography>
    </CustomAddressesBox>
  ));

  return (
    <CustomWrapperBox>
      <CustomOrangeBox>
        <ImageBox
          width="50px"
          height="50px"
          padding="10px"
          backgroundcolor="#f8f8f8"
          borderradius={10}
          objectFit="contain"
          imgSrc={logo}
        />
        <div>
          <Typography color="primary" sx={{ fontWeight: 600, letterSpacing: 1, textAlign: 'center' }}>
            {companyname}
          </Typography>
          <Typography color="primary" sx={{ fontSize: 12, fontWeight: 400, textAlign: 'center' }}>
            {t('Tin')}
            :
            {pib}
          </Typography>
        </div>
      </CustomOrangeBox>
      <CustomLabel>
        {t('Address')}
      </CustomLabel>
      {addressesArray}
      {addresses?.length === 0
          && (
          <Typography color="secondary" fontSize={14} textAlign="center">
            {t('No addresses')}
          </Typography>
          )}
    </CustomWrapperBox>
  );
}

ClientDataComponent.propTypes = {
  client: PropTypes.PropTypes.shape({}),
};

ClientDataComponent.defaultProps = {
  client: {},
};

export default ClientDataComponent;
