/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const putIsseenOrder = async (id) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'put',
    url: `/orders/seen/${id}/`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const usePutIsseenOrder = () => useMutation(({ id }) => putIsseenOrder(id));

export default usePutIsseenOrder;
