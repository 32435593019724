/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const addClient = async (companyname, email, password, pib, address, city, contactPerson) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'post',
    data: {
      companyname, email, password, pib, address, city, contact_person: contactPerson,
    },
    url: '/clients/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useAddClient = () => useMutation(({
  companyname, email, password, pib, address, city, contactPerson,
}) => addClient(companyname, email, password, pib, address, city, contactPerson));

export default useAddClient;
