/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import useGetReturns from '../Hooks/returns/useGetReturns';
import Tabs from '../Components/Tabs/Tabs';
import SearchBar from '../Components/Forms/SearchBar/SearchBar';
import ReturnsTable from '../Components/Tables/ReturnsTable';
import OrderCalendar from '../Components/Calendar/OrderCalendar';

function CreateData(client, address, date, id, isseen, review = null, editReturn = null, viewReturn = null) {
  if (review) {
    return {
      client,
      address,
      date,
      id,
      isseen,
      review,
      viewReturn,
    };
  }
  return {
    client,
    address,
    id,
    isseen,
    date,
    editReturn,
  };
}

const CustomGrid = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  top: '45px',
  right: 0,
  width: '100%',
  [theme.breakpoints.down('md')]: {
    left: 0,
    top: 0,
  },
}));

function Returns({ setIsSeenOrder }) {
  const { t } = useTranslation();
  const tabsNames = [t('Unprocessed returns'), t('Processed returns')];
  const [searchedTerm, setSearchedTerm] = useState('');
  const [tab, setTab] = useState('new');
  const [date, setDate] = useState(null);
  const [param, setParam] = useState('?orderstatus=new');
  const [page, setPage] = useState(1);
  const [refetch, setRefetch] = useState(false);
  let count = 0;
  let limit = 10;
  const newReturnsRows = [];
  const completedReturnsRows = [];
  const {
    status,
    data,
    isFetched,
  } = useGetReturns(param);

  useEffect(() => {
    const day = date && date?.getDate();
    const month = date && date.getMonth() + 1;
    const year = date && date?.getFullYear();
    if (searchedTerm || date) {
      const filters = searchedTerm && !date ? `&company=${searchedTerm}&page=${page}`
        : !searchedTerm && date ? `&date_from=${year}${month}${day}&date_to=${year}${month}${day}&page=${page}`
          : `&company=${searchedTerm}&date_from=${year}${month}${day}&date_to=${year}${month}${day}&page=${page}`;
      setParam(`?orderstatus=${tab}${filters}`);
    } else if (refetch) {
      if (date) {
        setParam(`?orderstatus=${tab}&company=${searchedTerm}&date_from=${year}${month}${day}&date_to=${year}${month}${day}&page=${page}`);
      } else {
        setParam(`?orderstatus=${tab}&company=${searchedTerm}&page=${page}`);
      }
      setRefetch(false);
    } else {
      setParam(`?orderstatus=${tab}&page=${page}`);
    }
  }, [tab, searchedTerm, date, refetch, page]);

  const newReturnsHeadCells = [
    {
      id: 'client',
      numeric: true,
      disablePadding: false,
      label: t('Client'),
      sortable: true,
    },
    {
      id: 'delivery-address',
      numeric: false,
      disablePadding: false,
      label: t('Delivery address'),
      sortable: false,
    },
    {
      id: 'delivery-date',
      numeric: true,
      disablePadding: false,
      label: t('Deliery date'),
      sortable: false,
    },
    {
      id: 'edit-order',
      numeric: false,
      disablePadding: false,
      label: t('Edit'),
      sortable: false,
    },
  ];

  const completedReturnsHeadCells = [
    {
      id: 'client',
      numeric: true,
      disablePadding: false,
      label: t('Client'),
      sortable: true,
    },
    {
      id: 'delivery-address',
      numeric: false,
      disablePadding: false,
      label: t('Delivery address'),
      sortable: false,
    },
    {
      id: 'delivery-date',
      numeric: true,
      disablePadding: false,
      label: t('Deliery date'),
      sortable: false,
    },
    {
      id: 'review',
      numeric: false,
      disablePadding: false,
      label: t('Review'),
      sortable: false,
    },
    {
      id: 'view-return',
      numeric: false,
      disablePadding: false,
      label: t('View more'),
      sortable: false,
    },
  ];

  if (isFetched) {
    const returns = data && data?.data && data.data.slice(0, 10);
    const ordersNumber = data && data.headers['x-pagination'].split(', ')[0].match(/\d/g).join('');
    limit = data && data.headers['x-pagination'].split(', ')[1].match(/\d/g).join('');
    count = Math.ceil(+ordersNumber / +limit);
    returns?.map(returnEl => {
      let returnDated = '';
      if (returnEl.date) {
        const redate = new Date(returnEl?.date);
        const day = redate.getDate();
        const month = redate.getMonth() + 1;
        const year = redate.getFullYear();
        if (redate && day && month && year) {
          returnDated = day + '.' + month + '.' + year;
        }
      }
      const returnAddress = returnEl.customer && returnEl.customer.address + ', ' + returnEl.customer.city;
      if (returnEl.status === 'created') {
        return newReturnsRows?.push(CreateData(returnEl?.customer?.name, returnAddress, returnDated, returnEl?.id, returnEl?.isseen, false, true));
      }
      return completedReturnsRows?.push(CreateData(returnEl?.customer?.name, returnAddress, returnDated, returnEl?.id, true, returnEl?.status, true));
    });
  }

  return (
    <Box marginTop="50px">
      <Grid item xs={5.7} sm={4} md={3.2} position="absolute" top="12px" width="100%">
        <SearchBar
          name="search-orders"
          placeholder={t('Search by company name')}
          borderradius="30px"
          padding="10px 16px 10px 20px"
          margin="15px 0 15px"
          backgroundcolor="#fff"
          border="1px solid #ded9d9"
          display="flex"
          direction="row"
          justifycontent="space-between"
          setSearchedTerm={term => {
            if (page !== 1) {
              setPage(1);
            }
            setSearchedTerm(term);
          }}
          searchedTerm={searchedTerm}
        />
      </Grid>
      <Box position="relative">
        <Tabs tabsnames={tabsNames} setTab={setTab} setPage={setPage}>
          <Grid item xs={12}>
            <ReturnsTable
              rows={newReturnsRows}
              headCells={newReturnsHeadCells}
              handleChangePage={setPage}
              refetch={setRefetch}
              statusLoad={status}
              count={count}
              page={page}
              setIsSeenOrder={setIsSeenOrder}
            />
          </Grid>
          <Grid item xs={12}>
            <ReturnsTable
              rows={completedReturnsRows}
              headCells={completedReturnsHeadCells}
              handleChangePage={setPage}
              refetch={setRefetch}
              statusLoad={status}
              count={count}
              page={page}
              setIsSeenOrder={setIsSeenOrder}
            />
          </Grid>
        </Tabs>
        <CustomGrid item xs={12} md={4} lg={3} xl={2.2}>
          <OrderCalendar
            date={date}
            setDate={dateValue => {
              if (page !== 1) {
                setPage(1);
              }
              setDate(dateValue);
            }}
          />
        </CustomGrid>
      </Box>
    </Box>
  );
}

Returns.propTypes = {
  setIsSeenOrder: PropTypes.func,
};

export default Returns;
