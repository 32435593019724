import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useNotifyClients from '../Hooks/notifyClients/useNotifyClients';
import UsersForm from '../Components/Forms/UsersForm';
import SearchClients from '../Components/Forms/SearchClients';

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.blue.main,
  fontWeight: 600,
  clear: 'both',
  marginBottom: 15,
}));

const CustomMessageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '85%',
  borderRadius: 20,
  border: `2px solid ${theme.palette.blue.main}`,
  padding: 10,
  marginTop: '20px',
  backgroundColor: '#429dd9',
}));

function NotifyUsers() {
  const { t } = useTranslation();
  const [selectedClients, setSelectedClients] = useState([]);
  const { mutate: notifyClients, isLoading } = useNotifyClients();
  const [title, setTitle] = useState('');
  const [messageField, setMessageField] = useState('');
  const [isSentNotifications, setIsSentNotifications] = useState(false);
  const [promotionfile, setPromotionfile] = useState({
    name: '',
    base64: '',
  });
  const [disabledNotifyUserButton, setDisabledNotifyUserButton] = useState(true);
  const [maxMessageLength, setMaxMessageLength] = useState(false);
  const [maxTitleLength, setMaxTitleLength] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isNotified, setIsNotified] = useState(false);
  const handleChange = event => {
    event.preventDefault();
    const accounts = selectedClients;
    return notifyClients(({
      title, message: messageField, accounts, promotionfile,
    }), {
      onSuccess: async () => {
        setIsSentNotifications(true);
        setTitle('');
        setMessageField('');
        setSelectedClients([]);
        setSelectAll(false);
        setMaxMessageLength('');
        setMaxTitleLength('');
        setPromotionfile({
          name: '',
          base64: '',
        });
        setIsNotified(true);
      },
      onError: async () => {
        setIsSentNotifications(false);
        setIsNotified(false);
      },
    });
  };

  useEffect(() => {
    if (title && messageField
      && selectedClients.length > 0) {
      setDisabledNotifyUserButton(false);
    } else {
      setDisabledNotifyUserButton(true);
    }
  }, [maxMessageLength, title, messageField, selectedClients, maxTitleLength, promotionfile]);

  const handleInputChange = event => {
    event.preventDefault();
    const { files } = event.target;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = e => {
      setPromotionfile({
        name: files[0].name,
        base64: e.target.result,
      });
    };
  };

  const handleCancel = event => {
    event.preventDefault();
    setTitle('');
    setMessageField('');
    setSelectedClients([]);
    setPromotionfile({
      name: '',
      base64: '',
    });
  };

  return (
    <Grid item xs={12} md={10} lg={7} xl={5.5}>
      <CustomTypography variant="h6" color="blue">{t('Send notification')}</CustomTypography>
      {isSentNotifications
        && (
        <CustomMessageBox>
          <Typography color="primary" fontWeight={600}>
            {t('Notifications sent')}
          </Typography>
        </CustomMessageBox>
        )}
      <SearchClients
        setSelectedClients={setSelectedClients}
        selectedClients={selectedClients}
        searchplaceholder={t('Search by company name')}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        isNotified={isNotified}
      />
      <UsersForm
        setTitle={setTitle}
        title={title}
        messagefield={messageField}
        setMessage={setMessageField}
        handleChange={handleChange}
        isSent={false}
        setIsSentNotifications={setIsSentNotifications}
        isSentNotifications={isSentNotifications}
        isNotifyUsers
        setFile={handleInputChange}
        file={promotionfile?.name}
        disabledNotifyUserButton={disabledNotifyUserButton}
        maxMessageLength={maxMessageLength}
        setMaxMessageLength={setMaxMessageLength}
        maxTitleLength={maxTitleLength}
        setMaxTitleLength={setMaxTitleLength}
        handleCancel={handleCancel}
        isLoading={isLoading}
        messageCharacterNumber={255}
      />
    </Grid>
  );
}

const mapDispatchToProps = () => ({
});

const mapStateToProps = (state, ownProps = {}) => ({
  ...ownProps,
});

export { NotifyUsers };
export default connect(mapStateToProps, mapDispatchToProps)(NotifyUsers);
