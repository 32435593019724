import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getDeliveryAddresses = async () => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: '/account/delivery/addresses/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetDeliveryAddresses = () => useQuery(['deliveryaddresses'], () => getDeliveryAddresses());

export default useGetDeliveryAddresses;
