import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import MessageTitleInputField from './InputFields/SupportInputFields/MessageTitleInputField';
import MessageTextareaField from './InputFields/SupportInputFields/MessageTextareaField';
import ButtonConfirm from './Buttons/ButtonConfirm';
import ButtonCancel from './Buttons/ButtonCancel';
import ChooseFile from './Buttons/ChooseFile';

const CustomMessageBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '85%',
  borderRadius: 20,
  border: '2px solid #3E79A9',
  padding: 10,
  marginTop: '50px',
}));

function UsersForm({
  handleChange, isSent, title,
  messagefield, setTitle, setMessage, successMessage, isNotifyUsers, setFile, file,
  disabledNotifyUserButton, setMaxMessageLength, maxMessageLength,
  maxTitleLength, setMaxTitleLength, handleCancel,
  isLoading, messageCharacterNumber, showCancelButton,
}) {
  const { t } = useTranslation();

  return (
    <Box component="form">
      {!isSent
        ? (
          <>
            <Grid container spacing={2}>
              <MessageTitleInputField
                grid={11.5}
                bgcolor="#fff"
                borderradius={10}
                border="1px solid #ded9d9"
                padding="5px 15px"
                placeholdercolor="#9c9c9c"
                placeholderfontsize={12}
                defaultValue={title}
                handleChange={setTitle}
                maxTitleLength={maxTitleLength}
                setMaxTitleLength={setMaxTitleLength}
              />
              <MessageTextareaField
                grid={11.5}
                bgcolor="#fff"
                borderradius={10}
                border="1px solid #ded9d9"
                padding="15px"
                color="#494949"
                placeholdercolor="#9c9c9c"
                placeholderfontsize="12px"
                placeholderfontweight={400}
                defaultValue={messagefield}
                handleChange={setMessage}
                setMaxMessageLength={setMaxMessageLength}
                maxMessageLength={maxMessageLength}
                characterNumber={messageCharacterNumber}
              />
              {isNotifyUsers
              && (
              <Grid item xs={11.5} sm={5} md={6}>
                <ChooseFile
                  texttypography={file || t('No file chosen')}
                  textinput={t('Choose file')}
                  setFile={setFile}
                  accept="image/*,application/pdf"
                />
              </Grid>
              )}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: showCancelButton ? '25px' : '20px',
                marginTop: { xs: '10px', sm: isNotifyUsers ? '-30px' : '20px' },
              }}
            >
              {showCancelButton
              && (
              <Grid item xs={6} sm={3} md={2} marginRight={1}>
                <ButtonCancel
                  text={t('Cancel')}
                  color="#747474"
                  margin="0"
                  padding="5px 0"
                  borderradius={16}
                  textsize={10}
                  bgcolor="#fff"
                  variant="contained"
                  handleClose={handleCancel}
                />
              </Grid>
              )}
              <Grid item xs={showCancelButton ? 6 : 12} sm={3} md={2}>
                <ButtonConfirm
                  text={t('Send')}
                  type="submit"
                  color="#fff"
                  margin="0"
                  padding="5px 0"
                  borderradius={16}
                  textsize={10}
                  bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
                  variant="contained"
                  handleChange={handleChange}
                  disabled={disabledNotifyUserButton}
                  isLoading={isLoading}
                />
              </Grid>
            </Box>
          </>
        ) : (
          <CustomMessageBox>
            <Typography color="#3E79A9" fontWeight={600}>
              {successMessage}
            </Typography>
          </CustomMessageBox>
        )}
    </Box>
  );
}

UsersForm.propTypes = {
  handleChange: PropTypes.func,
  title: PropTypes.string,
  messagefield: PropTypes.string,
  setTitle: PropTypes.func,
  setMessage: PropTypes.func,
  isSent: PropTypes.bool,
  successMessage: PropTypes.string,
  isNotifyUsers: PropTypes.bool,
  setFile: PropTypes.func,
  file: PropTypes.string,
  disabledNotifyUserButton: PropTypes.bool,
  setMaxMessageLength: PropTypes.func,
  maxMessageLength: PropTypes.bool,
  setMaxTitleLength: PropTypes.func,
  maxTitleLength: PropTypes.bool,
  handleCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  messageCharacterNumber: PropTypes.number,
  showCancelButton: PropTypes.bool,
};

UsersForm.defaultProps = {
  title: '',
  messagefield: '',
  isSent: false,
  successMessage: '',
  isNotifyUsers: false,
  disabledNotifyUserButton: true,
  messageCharacterNumber: 1000,
  showCancelButton: true,
};

export default UsersForm;
