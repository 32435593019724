import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputField from '../InputField';

function MessageTitleInputField({
  grid,
  borderradius,
  border,
  padding,
  bgcolor,
  placeholdercolor,
  placeholderfontsize,
  handleChange,
  defaultValue,
  setMaxTitleLength,
  maxTitleLength,
}) {
  const { t } = useTranslation();

  const handleTitleChange = value => {
    handleChange(value);
    if (value.length === 50) {
      setMaxTitleLength(true);
    } else {
      setMaxTitleLength(false);
    }
  };

  return (
    <InputField
      name="message-title"
      grid={grid}
      required
      type="text"
      placeholder={t('Message title')}
      borderradius={borderradius}
      border={border}
      padding={padding}
      bgcolor={bgcolor}
      placeholdercolor={placeholdercolor}
      placeholderfontsize={placeholderfontsize}
      handleChange={handleTitleChange}
      defaultValue={defaultValue}
      errorMessage={maxTitleLength && t('Character limit reached')}
      maxLength="50"
    />
  );
}

MessageTitleInputField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  bgcolor: PropTypes.string,
  border: PropTypes.string,
  placeholdercolor: PropTypes.string,
  placeholderfontsize: PropTypes.number,
  handleChange: PropTypes.func,
  defaultValue: PropTypes.string,
  setMaxTitleLength: PropTypes.func,
  maxTitleLength: PropTypes.bool,
};

MessageTitleInputField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  bgcolor: '#fff',
  border: '',
  placeholdercolor: '#ccc',
  placeholderfontsize: 14,
  defaultValue: '',
};

export default MessageTitleInputField;
