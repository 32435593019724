/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../InputField';

function ContactPersonInputField(props) {
  const { t } = useTranslation();
  const {
    // eslint-disable-next-line max-len
    grid, required, borderradius, border, padding, bgcolor, requiredcolor, contactPerson, handleChange,
  } = props;

  return (
    <InputField
      name="contact-person"
      grid={grid}
      required={required}
      type="text"
      placeholder={t('Contact person')}
      label={t('Contact person')}
      borderradius={borderradius}
      border={border}
      padding={padding}
      bgcolor={bgcolor}
      requiredcolor={requiredcolor}
      defaultValue={contactPerson}
      handleChange={handleChange}
    />
  );
}

export default ContactPersonInputField;
