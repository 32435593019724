import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getClient = async (clientId) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: `/clients/${clientId}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetClient = (clientId) => useQuery(['client', clientId], () => getClient(clientId), { enabled: false });

export default useGetClient;
