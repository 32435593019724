import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputField from '../InputField';

function EmailInputField({
  grid,
  borderradius,
  border,
  padding,
  bgcolor,
  required,
  placeholdercolor,
  requiredcolor,
  handleChange,
  email,
  errorMessage,
  requiredError,
  margin,
}) {
  const { t } = useTranslation();

  return (
    <InputField
      name="email"
      grid={grid}
      required={required}
      type="email"
      placeholder={t('Email')}
      label={t('Email')}
      borderradius={borderradius}
      border={border}
      padding={padding}
      bgcolor={bgcolor}
      placeholdercolor={placeholdercolor}
      requiredcolor={requiredcolor}
      handleChange={handleChange}
      defaultValue={email}
      errorMessage={errorMessage}
      requiredError={requiredError}
      margin={margin}
    />
  );
}

EmailInputField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  bgcolor: PropTypes.string,
  border: PropTypes.string,
  required: PropTypes.bool,
  placeholdercolor: PropTypes.string,
  requiredcolor: PropTypes.string,
  handleChange: PropTypes.func,
  email: PropTypes.string,
  errorMessage: PropTypes.string,
  requiredError: PropTypes.bool,
  margin: PropTypes.string,
};

EmailInputField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  bgcolor: '#fff',
  border: '',
  required: false,
  placeholdercolor: '#efefef',
  requiredcolor: '#fff',
  email: '',
  errorMessage: '',
  requiredError: false,
  margin: '0px',
};

export default EmailInputField;
