import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import Gilroy from './fonts/Gilroy-Regular.ttf';

const gilroy = {
  fontFamily: 'Gilroy',
  fontDisplay: 'swap',
  src: `
   local('Gilroy'),
   local('Gilroy'),
   url(${Gilroy}) format('ttf')
 `,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: ['Gilroy', 'Roboto'].join(','),
    },
    overrides: {
      CssBaseline: {
        '@global': {
          '@font-face': [gilroy],
        },
      },
    },
    palette: {
      primary: {
        main: '#fff',
      },
      secondary: {
        main: '#F56F4B',
      },
      blue: {
        main: '#3384B9',
        dark: '#3B79AC',
      },
      grey: {
        main: '#ebe6e6',
      },
    },
    transitions: {
      duration: {
        enteringScreen: 700,
        leavingScreen: 700,
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#3384B9',
            '& .MuiTooltip-arrow': {
              color: '#3384B9',
            },
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            justifyContent: 'end',
            margin: '15px 0px',
          },
        },
      },
      MuiPaginationItem: {
        defaultProps: {
          // @ts-expect-error Material-UI issue
          components: { previous: FastRewindIcon, next: FastForwardIcon },
        },
        styleOverrides: {
          root: {
            backgroundColor: 'rgb(237, 237, 237)',
            color: 'rgb(62, 73, 84)',
            borderRadius: '3px',
            padding: '8px 12px',
            fontSize: '14px',
            margin: '0px 2px',
            border: 0,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&.Mui-selected': {
              backgroundColor: 'rgb(59, 121, 172)',
              color: 'rgb(255, 255, 255)',
              '&:hover': {
                backgroundColor: 'rgb(59, 121, 172)',
                color: 'rgb(255, 255, 255)',
              },
            },
            '&.MuiPaginationItem-previousNext': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: 'rgb(59, 121, 172)',
              color: 'rgb(255, 255, 255)',
            },
            '&.MuiPaginationItem-ellipsis': {
              backgroundColor: 'transparent',
              minWidth: '10px',
              padding: 0,
            },
            '& svg': {
              fill: 'rgb(59, 121, 172)',
              fontSize: '14px',
            },
            '&.Mui-disabled': {
              '& svg': {
                fill: 'rgb(111, 162, 203)',
                fontSize: '14px',
              },
            },
          },
        },
      },
    },
  }),
);

export default theme;
