import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

const CustomButton = styled(LoadingButton)(props => ({
  margin: props.margin,
  padding: props.padding,
  color: props.fontcolor,
  borderRadius: props.borderradius,
  fontSize: props.textsize,
  fontWeight: props.textweight,
  background: props.bgcolor,
  textTransform: props.texttransform,
  pointerEvents: props.pointerEvents,
}));

function ButtonConfirm({
  type,
  margin,
  padding,
  color,
  borderradius,
  textsize,
  textweight,
  bgcolor,
  variant,
  texttransform,
  text,
  disabled,
  handleChange,
  pointerEvents,
  isLoading,
}) {
  return (
    <CustomButton
      type={type}
      fullWidth
      texttransform={texttransform}
      margin={margin}
      padding={padding}
      fontcolor={color}
      borderradius={borderradius}
      textsize={textsize}
      textweight={textweight}
      bgcolor={bgcolor}
      variant={variant}
      disabled={disabled}
      loading={isLoading}
      pointerEvents={pointerEvents}
      onClick={e => handleChange(e)}
    >
      {text}
    </CustomButton>
  );
}

ButtonConfirm.propTypes = {
  type: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string,
  borderradius: PropTypes.number,
  textsize: PropTypes.number,
  textweight: PropTypes.number,
  bgcolor: PropTypes.string,
  variant: PropTypes.string,
  texttransform: PropTypes.string,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  pointerEvents: PropTypes.string,
  isLoading: PropTypes.bool,
};

ButtonConfirm.defaultProps = {
  type: 'submit',
  margin: '0',
  padding: '0',
  color: '#ccc',
  borderradius: 20,
  textsize: 14,
  textweight: 400,
  bgcolor: '#fff',
  variant: 'contained',
  texttransform: '',
  disabled: false,
  pointerEvents: 'auto',
  isLoading: false,
};

export default ButtonConfirm;
