import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';
import OrdersNavItem from './NavItems/OrdersNavItem';
import ReturnsNavItem from './NavItems/ReturnsNavItem';
import MobileMenu from './MobileMenu';
import useGetStats from '../../../Hooks/useGetStats';

const CustomAppBar = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: fit-content;
    position: relative;
    float: right;
    padding: 0 0 50px;
`;

const MenuWrapper = styled.div`
  width: fit-content;
  margin: 0;
  display: flex;
  flex-direction: ${props => (props.anchorEl ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  @media (max-width: 900px) {
    display: none;
  }
`;
const NavigationLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => (props.anchorEl ? '15px' : '0')}
`;

function Header({ isSeenOrder }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const {
    data,
    refetch,
  } = useGetStats();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isSeenOrder) {
      refetch();
    }
  }, [isSeenOrder]);

  const toggleMenu = e => {
    setAnchorEl(e.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuWrapperElements = (
    <MenuWrapper anchorEl={anchorEl}>
      <NavigationLinksWrapper anchorEl={anchorEl}>
        <OrdersNavItem
          badgeContent={data?.data?.orders_not_seen}
          marginright={8}
          boxshadow="-1px 2px 4px 1px #302f2f26"
          backgroundcolor="#F8F8F8"
          backgroundcolorhover="#F8F8F8"
          colorhover="#F56F4B"
          iconwidth={20}
          iconheight={20}
          color="blue"
          width={44}
        />
        <ReturnsNavItem
          badgeContent={data?.data?.returns_not_seen}
          marginright={8}
          boxshadow="-1px 2px 4px 1px #302f2f26"
          backgroundcolor="#F8F8F8"
          backgroundcolorhover="#F8F8F8"
          colorhover="#F56F4B"
          iconwidth={20}
          iconheight={20}
          color="blue"
          width={44}
        />
      </NavigationLinksWrapper>
    </MenuWrapper>
  );

  return (
    <CustomAppBar sx={{
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
    }}
    >
      <MobileMenu toggleMenu={toggleMenu} handleClose={handleClose} anchorEl={anchorEl}>
        {menuWrapperElements}
      </MobileMenu>
      {menuWrapperElements}
    </CustomAppBar>
  );
}

Header.propTypes = {
  isSeenOrder: PropTypes.bool,
};

export default Header;
