/* eslint-disable react/destructuring-assignment */
import React, { forwardRef } from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

const AddIconButton = forwardRef((props, ref) => (
  <IconButton
    ref={ref}
    aria-label="add-article"
    // eslint-disable-next-line react/prop-types
    onClick={(e) => props.handleClick(e)}
  >
    <AddIcon sx={{ fontSize: 20 }} />
  </IconButton>
));

export default AddIconButton;
