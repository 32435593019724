/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import useGetClients from '../Hooks/clients/useGetClients';
import useGetClient from '../Hooks/clients/useGetClient';
import Tabs from '../Components/Tabs/Tabs';
import SearchBar from '../Components/Forms/SearchBar/SearchBar';
import ClientsTable from '../Components/Tables/ClientsTable';
import ClientDataComponent from '../Components/Clients/ClientDataComponent';
import AllRemindersComponent from '../Components/Clients/AllReminders';
import AllNotificationsComponent from '../Components/Clients/AllNotifications';
import AddNewClientModal from '../Components/Modals/Clients/AddNewClient';

function CreateData(name, tin, view, id, deleteClient = true) {
  return {
    name,
    tin,
    view,
    id,
    deleteClient,
  };
}

function Clients() {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [client, setClient] = useState(null);
  const [clientId, setClientId] = useState(null);
  const tabsNames = [t('Informations'), t('Reminders'), t('Notifications')];
  const [searchedTerm, setSearchedTerm] = useState('');
  const [page, setPage] = useState(1);
  const [param, setParam] = useState(`?page=${page}`);
  let count = 0;
  let limit = 10;
  const rows = [];
  const {
    refetch: refetchClient,
    data: dataClient,
    isFetched: isFetchedClient,
    isRefetching,
  } = useGetClient(clientId);

  useEffect(() => {
    if (clientId) {
      refetchClient();
    }
  }, [clientId]);

  useEffect(() => {
    if (isFetchedClient || isRefetching) {
      setClient(dataClient?.data);
    }
  }, [isFetchedClient, isRefetching]);
  const {
    status,
    data,
    isFetched,
    refetch,
  } = useGetClients(param);

  useEffect(() => {
    if (searchedTerm) {
      setParam(`?companyname=${searchedTerm}&page=${page}`);
    } else {
      setParam(`?page=${page}`);
    }
  }, [searchedTerm, page]);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('Supplier'),
      sortable: true,
    },
    {
      id: 'tin',
      numeric: true,
      disablePadding: false,
      label: t('Tin'),
      sortable: false,
    },
    {
      id: 'edit',
      numeric: false,
      disablePadding: false,
      label: t('View more'),
      sortable: false,
    },
    {
      id: 'delete',
      numeric: false,
      disablePadding: false,
      label: t('Remove client'),
      sortable: false,
    },
  ];

  if (isFetched) {
    const clientsData = data && data?.data && data.data.slice(0, 10);
    const ordersNumber = data && data.headers['x-pagination'].split(', ')[0].match(/\d/g).join('');
    limit = data && data.headers['x-pagination'].split(', ')[1].match(/\d/g).join('');
    count = Math.ceil(+ordersNumber / +limit);
    clientsData?.map(clientEl => rows.push(CreateData(clientEl?.companyname, clientEl.pib, true, clientEl.id)));
  }

  return (
    <Grid container display="flex" flexDirection="column">
      <Grid item xs={5.7} sm={4} md={3.2} position="absolute" top="12px" width="100%">
        <SearchBar
          name="search-orders"
          placeholder={t('Search by company name')}
          borderradius="30px"
          padding="10px 16px 10px 20px"
          margin="15px 0 15px"
          backgroundcolor="#fff"
          border="1px solid #ded9d9"
          display="flex"
          direction="row"
          justifycontent="space-between"
          placeholdercolor="#9c9c9c"
          setSearchedTerm={term => {
            if (page !== 1) {
              setPage(1);
            }
            setSearchedTerm(term);
          }}
          searchedTerm={searchedTerm}
        />
      </Grid>
      <Grid marginBottom={3}>
        <AddNewClientModal refetch={refetch} />
      </Grid>
      <Grid item xs={12}>
        <div ref={ref}>
          <ClientsTable
            headCells={headCells}
            rows={rows}
            handleChangePage={setPage}
            setClient={setClientId}
            clientTabsRef={ref}
            refetch={refetch}
            statusLoad={status}
            count={count}
            page={page}
          />
        </div>
      </Grid>
      {
        client
      && (
      <Tabs tabsnames={tabsNames}>
        <Grid id="client-data" item xs={12} sm={10} md={6} lg={6} xl={5} marginTop={3}>
          <ClientDataComponent client={client} />
        </Grid>
        <Grid item xs={12} sm={10} md={6} lg={6} xl={5} marginTop={3}>
          <AllRemindersComponent client={client} refetch={refetchClient} />
        </Grid>
        <Grid item xs={12} sm={10} md={6} lg={6} xl={5} marginTop={3}>
          <AllNotificationsComponent notifications={client.notifications} refetch={refetchClient} />
        </Grid>
      </Tabs>
      )
      }
    </Grid>
  );
}

const mapDispatchToProps = () => ({
});

const mapStateToProps = (state, ownProps = {}) => ({
  ...ownProps,
});

export { Clients };
export default connect(mapStateToProps, mapDispatchToProps)(Clients);
