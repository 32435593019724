import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DownArrowIconButton from '../IconButtons/DownArrowIconButton';
import UpArrowIconButton from '../IconButtons/UpArrowIconButton';

const ButtonDiv = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function SortButtons({
  id,
  direction,
  requestSort,
}) {
  const [active, setActive] = useState('');
  useEffect(() => {
    if (direction) {
      setActive(direction);
    }
  }, [direction]);
  return (
    <ButtonDiv>
      <UpArrowIconButton
        requestSort={() => requestSort(id, 'ascending')}
        active={active}
        height={7}
        color={direction === 'ascending' ? '#3384B9' : '#ebe6e6'}
        colorhover="#3384B9"
      />
      <DownArrowIconButton
        requestSort={() => requestSort(id, 'descending')}
        active={active}
        height={7}
        color={direction === 'descending' ? '#3384B9' : '#ebe6e6'}
        colorhover="#3384B9"
      />
    </ButtonDiv>
  );
}

SortButtons.propTypes = {
  requestSort: PropTypes.func,
  id: PropTypes.string,
  direction: PropTypes.string,
};

SortButtons.defaultProps = {
  id: '',
  direction: '',
};

export default SortButtons;
