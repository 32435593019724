/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled as mstyled } from '@mui/material/styles';
import HiddenInput from '../InputFields/HiddenInputField';
import ButtonConfirm from './ButtonConfirm';

const CustomTypography = mstyled(Typography)(props => ({
  fontSize: props.fontSize,
  color: props.color,
  fontWeight: props.fontWeight,
  margin: props.margin,
  wordBreak: 'break-all',
  paddingRight: '10px',

}));

function ChooseFile({
  textinput,
  texttypography,
  setFile,
  accept,
}) {
  return (
    <Box sx={{
      border: '1px solid #ded9d9',
      borderRadius: '16px',
      display: 'flex',
      alignItems: 'center',
    }}
    >
      <label className="button-file" htmlFor="button-file">
        <HiddenInput
          accept={accept}
          id="button-file"
          type="file"
          handleChange={setFile}
        />
        <Grid item xs={4} md={3.7}>
          <ButtonConfirm
            type="submit"
            pointerEvents="none"
            color="#fff"
            margin="0"
            padding="5px 0"
            borderradius={20}
            textsize={10}
            texttransform="capitalize"
            textweight={400}
            bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
            variant="contained"
            text={textinput}
          />
        </Grid>
      </label>
      <Grid item>
        <CustomTypography fontSize={12} color="#7f7f7f" fontWeight={400} margin="0 0 0 10px">{texttypography}</CustomTypography>
      </Grid>
    </Box>
  );
}

ChooseFile.propTypes = {
  texttypography: PropTypes.string,
  textinput: PropTypes.string,
  setFile: PropTypes.func,
  accept: PropTypes.string,
};

ChooseFile.defaultProps = {
  texttypography: '',
  textinput: '',
  accept: 'image/*',
};

export default ChooseFile;
