import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getOrder = async (orderId) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: `/orders/${orderId}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetOrder = (orderId) => useQuery(['order', orderId], () => getOrder(orderId), { enabled: false });

export default useGetOrder;
