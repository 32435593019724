import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDeleteItem from '../../../Hooks/items/useDeleteItem';
import DeleteModal from '../DeleteModal';

function DeleteItemModal({ id, refetch }) {
  const { t } = useTranslation();
  const [isDeleted, setIsDeleted] = useState(false);
  const { mutate: deleteItem } = useDeleteItem();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setIsDeleted(false);
  };
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    event.preventDefault();
    return deleteItem(({ id }), {
      onSuccess: async () => {
        setIsDeleted(true);
        refetch();
      },
      onError: async () => {
        setIsDeleted(false);
      },
    });
  };

  return (
    <DeleteModal
      deletemodaltitle={t('Delete item')}
      deletemodaltext={t('Do you want to delete this item')}
      handleChange={handleChange}
      isDeleted={isDeleted}
      setIsDeleted={setIsDeleted}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      deletedText={t('Item deleted')}
    />
  );
}

DeleteItemModal.propTypes = {
  id: PropTypes.number,
  refetch: PropTypes.func,
};

DeleteItemModal.defaultProps = {
  id: '',
};

export default DeleteItemModal;
