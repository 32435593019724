import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Typography from '@mui/material/Typography';
import ImageBox from '../ImageBox';
import EmailInputField from './InputFields/RegisterFields/EmailInputField';
import PasswordInputField from './InputFields/RegisterFields/PasswordInputField';
import SupplierInputField from './InputFields/RegisterFields/SupplierInputField';
import TinInputField from './InputFields/RegisterFields/TinInputField';
import AddressInputField from './InputFields/RegisterFields/AddressInputField';
import CitiesSelectField from './SelectFields/CitiesSelectField';
import WebsiteInputField from './InputFields/RegisterFields/WebsiteInputField';
import ButtonConfirm from './Buttons/ButtonConfirm';
import HiddenInput from './InputFields/HiddenInputField';

function RegisterForm({
  handleSubmit, setSuppliername,
  suppliername, setCity, city, imageChanged, imageUrl,
  setAddress, address, data, isFetched,
  email, setEmail, password, setPassword, pib, setPib,
  setWebsiteurl, websiteurl, emailErrorMessage, passwordErrorMessage, pibErrorMessage,
  requiredFieldsErros, disableSubmit,
  confirmPassword, confirmedPassword, confirmedPasswordErrorMessage,
}) {
  const { t } = useTranslation();

  const fields = (
    <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
      <EmailInputField
        grid={10}
        bgcolor="#fff"
        required
        borderradius={20}
        border="1px solid #efefef"
        padding="5px 15px"
        placeholdercolor="#3a78ac"
        handleChange={setEmail}
        email={email}
        errorMessage={emailErrorMessage}
        requiredError={requiredFieldsErros.includes('email')}
      />
      <PasswordInputField
        grid={10}
        required
        bgcolor="#fff"
        borderradius={20}
        border="1px solid #efefef"
        padding="5px 15px"
        placeholdercolor="#3a78ac"
        endAdornment
        placeholder={t('Password')}
        label={t('Password')}
        handleChange={setPassword}
        password={password}
        errorMessage={passwordErrorMessage}
        requiredError={requiredFieldsErros.includes('password')}
      />
      <PasswordInputField
        grid={10}
        name="confirm-password"
        required
        placeholder={t('Confirm password')}
        label={t('Confirm password')}
        bgcolor="#fff"
        borderradius={20}
        border="1px solid #efefef"
        padding="5px 15px"
        placeholdercolor="#3a78ac"
        endAdornment
        handleChange={confirmPassword}
        password={confirmedPassword}
        errorMessage={confirmedPasswordErrorMessage}
      />
      <SupplierInputField
        grid={10}
        required
        bgcolor="#fff"
        borderradius={20}
        border="1px solid #efefef"
        padding="5px 15px"
        placeholdercolor="#3a78ac"
        companyname={suppliername}
        handleChange={setSuppliername}
        requiredError={requiredFieldsErros.includes('suppliername')}
      />
      <TinInputField
        grid={10}
        required
        bgcolor="#fff"
        borderradius={20}
        border="1px solid #efefef"
        padding="5px 15px"
        placeholdercolor="#3a78ac"
        handleChange={setPib}
        pib={pib}
        errorMessage={pibErrorMessage}
        requiredError={requiredFieldsErros.includes('pib')}
      />
      <AddressInputField
        grid={10}
        required
        bgcolor="#fff"
        borderradius={20}
        border="1px solid #efefef"
        padding="5px 15px"
        placeholdercolor="#3a78ac"
        address={address}
        handleChange={setAddress}
        requiredError={requiredFieldsErros.includes('address')}
      />
      <CitiesSelectField
        grid={10}
        required
        bgcolor="#fff"
        borderradius={20}
        padding="10px 15px"
        requiredcolor="#fff"
        border="1px solid #efefef"
        placeholdercolor="#3a78ac"
        data={data}
        isFetched={isFetched}
        handleChange={setCity}
        defaultValue={city}
        requiredError={requiredFieldsErros.includes('city_id')}
      />
      <Grid item xs={10} sx={{ mt: 1 }}>
        <WebsiteInputField
          grid={12}
          bgcolor="#fff"
          borderradius={20}
          border="1px solid #efefef"
          padding="5px 15px"
          placeholdercolor="#3a78ac"
          handleChange={setWebsiteurl}
          defaultValue={websiteurl}
        />
      </Grid>
      <Grid
        item
        xs={10}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <label htmlFor="icon-button-file">
          <HiddenInput
            accept="image/*"
            id="icon-button-file"
            type="file"
            handleChange={imageChanged}
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            sx={{ textAlign: 'center' }}
          >
            <PhotoCamera />
          </IconButton>
          <Typography color="primary" sx={{ fontSize: 14 }}>
            {t('Upload Logo')}
          </Typography>
          {requiredFieldsErros.includes('logo_image')
                && <Typography color="secondary" fontSize={12}>{t('This field is required')}</Typography>}
        </label>
        {imageUrl && (
          <ImageBox width="90px" height="90px" objectFit="contain" imgSrc={imageUrl} />
        )}
      </Grid>
      <Grid item xs={6}>
        <ButtonConfirm
          type="submit"
          color="#fff"
          margin="15px 0 10px 0"
          padding="7px 0"
          borderradius={20}
          textsize={16}
          textweight={400}
          bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
          variant="contained"
          text={t('Register')}
          handleChange={handleSubmit}
          disabled={disableSubmit}
        />
      </Grid>
    </Grid>
  );
  return (
    <Box>
      {fields}
    </Box>
  );
}

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setSuppliername: PropTypes.func,
  suppliername: PropTypes.string,
  setCity: PropTypes.func,
  city: PropTypes.number,
  imageChanged: PropTypes.func,
  imageUrl: PropTypes.string,
  setAddress: PropTypes.func,
  address: PropTypes.string,
  data: PropTypes.shape(),
  isFetched: PropTypes.bool,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  pib: PropTypes.string,
  setPib: PropTypes.func,
  setWebsiteurl: PropTypes.func,
  websiteurl: PropTypes.string,
  emailErrorMessage: PropTypes.string,
  passwordErrorMessage: PropTypes.string,
  pibErrorMessage: PropTypes.string,
  requiredFieldsErros: PropTypes.arrayOf(PropTypes.string),
  disableSubmit: PropTypes.bool,
  confirmedPasswordErrorMessage: PropTypes.string,
  confirmedPassword: PropTypes.string,
  confirmPassword: PropTypes.func,
};
RegisterForm.defaultProps = {
  disableSubmit: false,
};

export default RegisterForm;
