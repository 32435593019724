/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const updateListDocument = async (formData) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'post',
    data: formData,
    url: '/products/file',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const useUpdateListDocument = () => useMutation(({ formData }) => updateListDocument(formData));

export default useUpdateListDocument;
