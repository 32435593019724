import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useInViewport from '../../Hooks/useInViewport';

const CustomModalBox = styled(Box)(({
  theme, backgroundcolor, padding, width, offsetTop, offsetLeft, issuppliermodal,
}) => ({
  position: 'absolute',
  top: issuppliermodal ? '50%' : `calc(${offsetTop}px + 15px)`,
  left: issuppliermodal ? '50%' : `calc(${offsetLeft}px + 15px)`,
  transform: issuppliermodal ? 'translate(-50%, -50%)' : 'none',
  width,
  backgroundColor: backgroundcolor,
  borderRadius: 6,
  padding,
  maxHeight: 'fit-content',
  '@media (max-height: 800px)': {
    maxHeight: '40vh',
  },
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    borderRadius: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(245,111,75,.6)',
    borderRadius: '6px',
  },
  [theme.breakpoints.down('lg')]: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
  },
  [theme.breakpoints.down('md')]: {
    width: '60%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '80%',
  },
}));

const CustomButtonGiveUp = styled(Button)(() => ({
  color: '#747474',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  marginTop: '10px',
  '&:hover': { backgroundColor: 'transparent' },
}));

const CustomButtonConfirm = styled(Button)(() => ({
  background:
        'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  color: '#fff',
  marginTop: '10px',
}));

const CustomMessageBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '10px auto 10px',
  width: '94%',
  borderRadius: 20,
  backgroundColor: '#f56f4bab',
  border: '2px solid #F56F4B',
  padding: 10,
}));

const CustomTypography = styled(Typography)(props => ({
  margin: props.margin,
}));

function ModalComponent({
  children,
  width,
  padding,
  titlemargin,
  buttonssize,
  open,
  handleClose,
  backgroundcolor,
  color,
  title,
  offsetTop,
  offsetLeft,
  isreminder,
  issuppliermodal,
  handleChange,
  isChanged,
  disabledSubmitButton,
  message,
  isLoading,
}) {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [refVisible, setRefVisible] = useState(false);
  const isInViewPort = useInViewport(inputRef, refVisible);
  let left = offsetLeft;
  let top = offsetTop;

  if (isInViewPort.offsetHorizontal > 0) {
    left = offsetLeft - width - 50;
    if (isreminder) {
      left -= 15;
    }
  }

  if (isInViewPort.offsetVertical > 0 && inputRef && inputRef.current) {
    top = offsetTop - inputRef.current.children[2].clientHeight;
    if (top < 0) {
      top = 0;
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      ref={el => {
        inputRef.current = el;
        setRefVisible(!!el);
      }}
      aria-labelledby="modal-title"
    >
      <CustomModalBox
        component="form"
        offsetTop={top}
        offsetLeft={left}
        padding={padding}
        backgroundcolor={backgroundcolor}
        width={width}
        noValidate
        issuppliermodal={issuppliermodal}
      >

        {message
                && (
                <CustomMessageBox>
                  <Typography color="primary">
                    {message}
                  </Typography>
                </CustomMessageBox>
                )}
        <CustomTypography color={color} fontWeight={600} fontSize={16} margin={titlemargin} id="modal-title">
          {!isChanged && title}
        </CustomTypography>
        {children}
        {!isChanged
        && (
        <Grid
          container
          sx={{ justifyContent: 'flex-end', mt: 2 }}
        >
          <Grid item xs={3.9} sm={buttonssize || 3} sx={{ marginRight: 1.1 }}>
            <CustomButtonGiveUp
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              {t('Cancel')}
            </CustomButtonGiveUp>
          </Grid>
          <Grid item xs={3.9} sm={buttonssize || 3}>
            <CustomButtonConfirm
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleChange}
              disabled={disabledSubmitButton}
              loading={isLoading}
            >
              {t('Confirm')}
            </CustomButtonConfirm>
          </Grid>
        </Grid>
        )}
      </CustomModalBox>
    </Modal>
  );
}

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  padding: PropTypes.string,
  titlemargin: PropTypes.string,
  buttonssize: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  backgroundcolor: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  offsetTop: PropTypes.number,
  offsetLeft: PropTypes.number,
  isreminder: PropTypes.bool,
  issuppliermodal: PropTypes.bool,
  handleChange: PropTypes.func,
  isChanged: PropTypes.bool,
  disabledSubmitButton: PropTypes.bool,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
};

ModalComponent.defaultProps = {
  width: 350,
  padding: '',
  open: false,
  titlemargin: '',
  backgroundcolor: '',
  buttonssize: 2,
  color: '',
  title: '',
  offsetTop: 0,
  offsetLeft: 0,
  isreminder: false,
  issuppliermodal: false,
  isChanged: false,
  disabledSubmitButton: false,
  message: '',
  isLoading: false,
};

export default ModalComponent;
