/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '300px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    border: '1px solid #ded9d9',
    paddingLeft: '20px',
    width: '100%',
    '& input': {
      fontSize: 12,
      color: '#9c9c9c',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));

function ReminderCalendar({ reminderdate, setReminderDate }) {
  const [value, setValue] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setValue(new Date(reminderdate));
  }, [reminderdate]);

  const dialogProps = {
    sx: {
      '&[data-popper-placement=top]': {
        marginLeft: '-24px!important',
        marginBottom: '-7px!important',
        '& .MuiPaper-root': {
          boxShadow: 'none',
          border: '1px solid #ded9d9',
          borderWidth: '1px 1px 0 1px',
          borderRadius: '10px 10px 0 0',
          '@media (max-width:1200px)': {
            borderWidth: '1px',
            borderRadius: '10px',
          },
          '& > div': {
            display: 'flex',
            flexDirection: 'column-reverse',
            '& .MuiTabs-root': {
              marginTop: '10px',
              borderBottom: '1px solid #ded9d9',
            },
          },
          '& div[role=presentation]': {
            top: '90px',
          },
        },
      },
      '& .MuiClockPicker-arrowSwitcher': {
        display: 'none',
      },
      '& .PrivatePickersSlideTransition-root': {
        minHeight: '200px',
      },
      '&[data-popper-placement=bottom]': {
        marginLeft: '-24px!important',
        marginTop: '-7px!important',
        '& .MuiPaper-root': {
          boxShadow: 'none',
          border: '1px solid #ded9d9',
          borderWidth: '0px 1px 1px 1px',
          borderRadius: '0 0 10px 10px',
          '@media (max-width:1200px)': {
            borderWidth: '1px',
            borderRadius: '10px',
          },
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
          },
          '& div[role=presentation]': {
            top: '35px',
          },
        },
      },
      '& .MuiPaper-root': {
        width: '298px',
        '@media (max-width:1200px)': {
          width: '100%',
        },
        '& .MuiCalendarPicker-root': {
          width: '300px',
          margin: 0,
          '@media (max-width:1200px)': {
            width: '100%',
          },
        },
        '& .MuiCalendarPicker-viewTransitionContainer': {
          '& .Mui-selected': {
            backgroundColor: '#F56F4B',
            color: '#fff',
            '&:focus': {
              backgroundColor: '#F56F4B',
              color: '#fff',
            },
          },
        },
        '& .MuiPickersDay-today': {
          color: '#F56F4B',
          backgroundColor: '#fff',
          border: 'none',
          '&:focus': {
            color: '#F56F4B',
            backgroundColor: '#fff',
          },
        },
        '& .MuiSvgIcon-root': {
          fill: '#3384B9',
        },
        '& div[role=presentation]': {
          position: 'absolute',
          left: '50%',
          color: '#3384B9',
          transform: 'translate(-50%, -50%)',
          '& .MuiButtonBase-root': {
            display: 'none',
          },
        },
        '& .css-1dozdou div:nth-of-type(2)': {
          width: '100%',
          justifyContent: 'space-between',
        },
      },
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDateTimePicker
        renderInput={params => <CustomTextField {...params} />}
        inputVariant="outlined"
        variant="dialog"
        width="100%"
        InputAdornmentProps={{ position: 'start', sx: { marginRight: 0, '& svg': { fill: '#3384B9' } } }}
        inputFormat="d MMM y HH:mm"
        ampm={false}
        value={value}
        PopperProps={dialogProps}
        disableHighlightToday={false}
        KeyboardButtonProps={{
          onFocus: () => {
            setIsOpen(true);
          },
        }}
        PopoverProps={{
          disableRestoreFocus: true,
        }}
        InputProps={{
          onClick: () => {
            setIsOpen(true);
          },
        }}
        onClose={() => setIsOpen(false)}
        disablePast
        open={isOpen}
        onChange={newValue => {
          setValue(newValue);
          setReminderDate(newValue);
        }}
      />
    </LocalizationProvider>
  );
}

ReminderCalendar.propTypes = {
  reminderdate: PropTypes.instanceOf(Date),
  setReminderDate: PropTypes.func,
};

export default ReminderCalendar;
