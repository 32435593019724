/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ChatIcon from '@mui/icons-material/Chat';
import styled from 'styled-components';
import { styled as mstyled } from '@mui/material/styles';
import DeleteNotificationModal from '../Modals/NotifyUsers/DeleteNotification';
import SearchBar from '../Forms/SearchBar/SearchBar';

const CustomWrapperBox = styled.div`
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: -1px 3px 5px 1px #e5e3e3;
    clear: both;
`;

const CustomLabel = mstyled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  letterSpacing: 0.5,
  color: theme.palette.blue.main,
  textAlign: 'center',
}));

const CustomIconBox = mstyled(Box)(() => ({
  backgroundColor: '#fff',
  width: 40,
  height: 40,
  borderRadius: '50%',
  boxShadow: '-1px 1px 4px 0px #d3d3d3',
  border: '1px solid #EAEAEA',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: -20,
  left: -20,
}));

const CustomNotificationBox = mstyled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: '1px solid #f6f6f6',
  borderBottom: '1px solid #f6f6f6',
  padding: '15px 0',
}));

const CustomCheckbox = mstyled(Checkbox)(({ theme }) => ({
  color: theme.palette.blue.main,
  '&.Mui-checked': {
    color: theme.palette.blue.main,
  },
}));

function AllNotificationsComponent({ notifications, refetch }) {
  const { t } = useTranslation();
  const [searchedTerm, setSearchedTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [notificationData, setNotificationsData] = useState([]);

  const onChangeSelectedNotifications = (name, value) => {
    let notificationsIdArray = selectedNotifications;

    if (name === 'selectAllNotifications') {
      setSelectAll(!selectAll);
      notificationsIdArray = !value ? [] : notifications?.map(notification => notification.id);
      setSelectedNotifications(notificationsIdArray);
    } else if (selectedNotifications.includes(value)) {
      setSelectedNotifications(selectedNotifications.filter(notification => notification !== value));
      if (selectedNotifications.length === 1) {
        setSelectAll(false);
      }
    } else {
      notificationsIdArray.push(value);
      setSelectedNotifications([...notificationsIdArray]);
    }
  };

  const datetime = value => {
    let orderDated = '';
    const ordate = new Date(value);
    const day = ordate.getDate();
    const month = ordate.getMonth() + 1;
    const year = ordate.getFullYear();
    if (ordate && day && month && year) {
      orderDated = `${day}.${month}.${year}.`;
    }
    return orderDated;
  };

  const handleChangeNotificationsData = notificationsArr => {
    const notificationArr = notificationsArr && notificationsArr.map(notification => (
      <CustomNotificationBox key={notification.id}>
        <Box display="flex">
          <CustomCheckbox size="small" checked={selectedNotifications.includes(notification.id)} onChange={() => onChangeSelectedNotifications('selectOne', notification.id)} />
          <Box display="flex" flexDirection="column" marginLeft={2}>
            <Typography sx={{
              color: '#F56F4B', fontSize: '14px', fontWeight: 500, wordBreak: 'break-all',
            }}
            >
              {notification.title}
            </Typography>
            <Typography sx={{ color: '#46688a', fontSize: '12px', fontWeight: 500 }}>
              {datetime(notification.datetime)}
            </Typography>
            <Typography sx={{
              color: '#46688a', fontSize: '12px', fontWeight: 500, wordBreak: 'break-all',
            }}
            >
              {notification.message}
            </Typography>
          </Box>
        </Box>
      </CustomNotificationBox>
    ));
    setNotificationsData(notificationArr);
  };

  useEffect(() => {
    const searchedNotifications = searchedTerm !== '' ? notifications?.filter(notification => notification.title.toLowerCase().includes(searchedTerm.toLowerCase())) : notifications;
    handleChangeNotificationsData(searchedNotifications);
  }, [searchedTerm, selectedNotifications, notifications]);

  return (
    <CustomWrapperBox>
      <CustomIconBox>
        <ChatIcon color="blue" sx={{ fontSize: 20 }} />
      </CustomIconBox>
      <CustomLabel>
        {t('Sent notifications list')}
      </CustomLabel>

      <Box display="flex" flexDirection={notificationData?.length === 0 ? 'column' : 'row'} alignItems="center">
        {notificationData?.length !== 0
          && (
            <CustomCheckbox
              size="small"
              checked={selectAll}
              onChange={e => onChangeSelectedNotifications('selectAllNotifications', e.target.checked)}
            />
          )}
        <SearchBar
          name="search-orders"
          placeholder={t('Search notification list')}
          borderradius="30px"
          padding="0 16px 0 20px"
          margin="15px 0 15px"
          backgroundcolor="#fff"
          border="1px solid #ded9d9"
          display="flex"
          direction="row"
          justifycontent="space-between"
          setSearchedTerm={setSearchedTerm}
          searchedTerm={searchedTerm}
        />
        {!notificationData?.length
          ? (
            <Typography color="secondary" fontSize={14} marginTop="15px" textAlign="center">
              {t('No notifications')}
            </Typography>
          ) : (
            <DeleteNotificationModal id={selectedNotifications} refetch={refetch} />
          )}
      </Box>
      {notificationData}
    </CustomWrapperBox>
  );
}

AllNotificationsComponent.propTypes = {
  client: PropTypes.PropTypes.shape({}),
  refetch: PropTypes.func,
};

AllNotificationsComponent.defaultProps = {
  client: {},
};

export default AllNotificationsComponent;
