/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import useGetListOfItems from '../Hooks/items/useGetListOfItems';
import Tabs from '../Components/Tabs/Tabs';
import UpdateLists from '../Components/UpdateLists/UpdateLists';
import SearchBar from '../Components/Forms/SearchBar/SearchBar';
import ItemsTable from '../Components/Tables/ItemsTable';
import AddItemModal from '../Components/Modals/Items/AddItemModal';

function CreateData(code, name, id) {
  return {
    code,
    name,
    editItem: true,
    deleteItem: true,
    id,
  };
}

function ItemsList() {
  const { t } = useTranslation();
  const tabsNames = [t('Current list'), t('Update list')];
  const [searchedTerm, setSearchedTerm] = useState('');
  const [param, setParam] = useState('');
  const [page, setPage] = useState(1);
  let count = 0;
  let limit = 10;
  const {
    status,
    data,
    isFetched,
    refetch,
  } = useGetListOfItems(param);

  useEffect(() => {
    if (searchedTerm) {
      setParam(`?articletitle=${searchedTerm}&page=${page}`);
    } else {
      setParam(`?page=${page}`);
    }
  }, [searchedTerm, page]);

  const itemsList = [];

  const headCells = [
    {
      id: 'code',
      numeric: false,
      disablePadding: false,
      label: t('Item code'),
      sortable: false,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('Item name'),
      sortable: false,
    },
    {
      id: 'edit',
      numeric: false,
      disablePadding: false,
      label: t('Edit'),
      sortable: false,
      width: '60px',
    },
    {
      id: 'delete',
      numeric: false,
      disablePadding: false,
      label: t('Remove'),
      sortable: false,
      width: '60px',
    },
  ];

  if (isFetched) {
    const items = data && data?.data && data?.data.slice(0, 10);
    const ordersNumber = data && data.headers['x-pagination'].split(', ')[0].match(/\d/g).join('');
    limit = data && data.headers['x-pagination'].split(', ')[1].match(/\d/g).join('');
    count = Math.ceil(+ordersNumber / +limit);
    items?.map(item => itemsList?.push(CreateData(item?.suppliercode, item?.articletitle, item?.id)));
  }

  return (
    <Tabs tabsnames={tabsNames}>
      <Grid container>
        <Grid item xs={5.7} sm={4} md={3.2} position="absolute" top="12px" width="100%">
          <SearchBar
            name="search-items"
            placeholder={t('Search items list')}
            borderradius="30px"
            padding="10px 16px 10px 20px"
            margin="15px 0 15px"
            backgroundcolor="#fff"
            border="1px solid #ded9d9"
            display="flex"
            direction="row"
            justifycontent="space-between"
            placeholdercolor="#9c9c9c"
            setSearchedTerm={term => {
              if (page !== 1) {
                setPage(1);
              }
              setSearchedTerm(term);
            }}
            searchedTerm={searchedTerm}
          />
        </Grid>
        <Grid marginBottom={3}>
          <AddItemModal refetch={refetch} />
        </Grid>
        <Grid item xs={12}>
          <ItemsTable
            headCells={headCells}
            rows={itemsList}
            refetch={refetch}
            setPage={setPage}
            statusLoad={status}
            count={count}
            page={page}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <UpdateLists refetch={refetch} />
        </Grid>
      </Grid>
    </Tabs>
  );
}

export default ItemsList;
