/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  composeWithDevTools,
} from 'redux-devtools-extension';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';

const history = createBrowserHistory();
const middlewares = composeWithDevTools(applyMiddleware(
  routerMiddleware(history),
));
const appReducers = combineReducers({
  router: connectRouter(history),
});
const reducers = persistReducer({
  key: 'root',
  storage,
}, appReducers);
const store = createStore(reducers, {}, middlewares);

const persistor = persistStore(store);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 5 * 60 * 1000,
      retry: 3,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  },
});

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <QueryClientProvider client={queryClient}>
    <App store={store} persistor={persistor} history={history} />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root'),
);
