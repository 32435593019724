/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import useAddItem from '../../../Hooks/items/useAddItem';
import StyledAddIconButton from '../../IconButtons/AddIconButton/StyledAddIconButton';
import ModalComponent from '../ModalComponent';
import ItemNameInputField from '../../Forms/InputFields/ItemInputFields/ItemNameInputField';
import ItemCodeInputField from '../../Forms/InputFields/ItemInputFields/ItemCodeInputField';

const CustomButtonConfirm = styled(Button)(() => ({
  background:
        'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  color: '#fff',
}));

function AddItemModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const { mutate: addItem } = useAddItem();
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleOpen = () => {
    setOpen(true);
    setIsAdded(false);
    setName('');
    setCode('');
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (name && code) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [name, code]);

  const handleChange = event => {
    event.preventDefault();
    return addItem(({ name, code }), {
      onSuccess: async () => {
        setIsAdded(true);
        props.refetch();
      },
      onError: async () => {
        setIsAdded(false);
      },
    });
  };

  return (
    <div>
      <StyledAddIconButton
        handleClick={handleOpen}
        ref={(el) => {
          if (!el) return;
          setOffsetTop(el.getBoundingClientRect().top);
          setOffsetLeft(el.getBoundingClientRect().left);
        }}
        width="30px"
        color={open ? '#fff' : '#3384B9'}
        border={open ? 'none' : '1px solid #f6f6f6'}
        boxshadow={open ? 'none' : '-3px 2px 3px 0px #e5e3e3'}
        backgroundcolor={open ? 'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)' : '#F9F9F9'}
        backgroundcolorhover="linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
        borderhover={open ? 'none' : '1px solid #F56F4B'}
        boxshadowhover="none"
        colorhover="#fff"
        iconsize="23px"
        position={open ? 'relative' : 'inherit'}
        padding="10px"
      />
      <ModalComponent
        open={open}
        handleClose={handleClose}
        offsetTop={offsetTop}
        offsetLeft={offsetLeft}
        width={300}
        padding="15px 25px 20px"
        backgroundcolor="#f8f8f8"
        titlemargin="0 10px 10px"
        buttonssize={3}
        color="secondary"
        title={t('Add new article')}
        handleChange={handleChange}
        isChanged={isAdded}
        disabledSubmitButton={isButtonDisabled}
      >
        {
          isAdded
            ? (
              <Box sx={{ margin: '30px 0 0' }}>
                <Typography color="secondary" sx={{ margin: '0 0 30px' }}>{t('Item added')}</Typography>
                <CustomButtonConfirm
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  {t('Confirm')}
                </CustomButtonConfirm>
              </Box>
            )
            : (
              <Grid container spacing={1.1}>
                <ItemNameInputField
                  grid={6}
                  bgcolor="#fff"
                  borderradius={6}
                  border="1px solid #ded9d9"
                  padding="3px 18px"
                  fontsize={12}
                  defaultValue={name}
                  handleChange={setName}
                />
                <ItemCodeInputField
                  grid={6}
                  bgcolor="#fff"
                  borderradius={6}
                  border="1px solid #ded9d9"
                  padding="3px 18px"
                  fontsize={12}
                  defaultValue={code}
                  handleChange={setCode}
                />
              </Grid>
            )
        }
      </ModalComponent>
    </div>
  );
}

export default AddItemModal;
