import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ButtonCancel from '../../Forms/Buttons/ButtonCancel';
import ButtonConfirm from '../../Forms/Buttons/ButtonConfirm';
import CommentTextareaField from '../../Forms/InputFields/Orders/CommentTextareaField';

const CustomModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#F8F8F8',
  borderRadius: 6,
  boxShadow: 24,
  padding: '35px 25px',
  [theme.breakpoints.down('md')]: {
    width: '70%',
  },
}));

const CustomTypography = styled(Typography)(props => ({
  margin: props.margin,
}));

const CustomButton = styled(Button)(props => ({
  margin: props.margin,
  padding: props.padding,
  color: props.fontcolor,
  borderRadius: props.borderradius,
  fontSize: props.textsize,
  fontWeight: props.textweight,
  backgroundColor: props.bgcolor,
  '&:hover': {
    backgroundColor: props.bgcolor,
  },
}));

const CustomButtonConfirm = styled(Button)(() => ({
  background:
        'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  color: '#fff',
}));

function CommentModal({
  buttontitle,
  margin,
  padding,
  color,
  borderradius,
  textsize,
  textweight,
  bgcolor,
  handleChange,
  modaltitle,
  status,
  handleSubmit,
  isConfirmed,
  isRejected,
  refetch,
  confirmedText,
  handleCloseParentModal,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    handleCloseParentModal();
    if (isConfirmed || isRejected) {
      refetch(true);
    }
  };

  return (
    <div>
      <CustomButton
        onClick={handleOpen}
        fullWidth
        margin={margin}
        padding={padding}
        fontcolor={color}
        borderradius={borderradius}
        textsize={textsize}
        textweight={textweight}
        bgcolor={bgcolor}
      >
        {buttontitle}
      </CustomButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomModalBox
          component="form"
        >
          {
            isConfirmed || isRejected
              ? (
                <Box sx={{ margin: '30px 0 0', width: '100%' }}>
                  <Typography color="secondary" sx={{ margin: '0 0 30px' }}>{confirmedText}</Typography>
                  <CustomButtonConfirm
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    {t('Confirm')}
                  </CustomButtonConfirm>
                </Box>
              )
              : (
                <>
                  <CustomTypography color="secondary" fontWeight={600} fontSize={16} margin="0 10px 10px" id="modal-title">
                    {modaltitle}
                  </CustomTypography>
                  <Grid container spacing={1.1}>
                    <CommentTextareaField
                      grid={12}
                      bgcolor="#fff"
                      borderradius={10}
                      rows={0}
                      border="1px solid #ded9d9"
                      padding="15px"
                      handleChange={handleChange}
                      placeholdercolor="#747474"
                      placeholderfontsize="15px"
                      placeholderfontweight={400}
                      placeholder={t('Comment (optional)')}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    sx={{ justifyContent: 'flex-end', mt: 2 }}
                  >
                    <Grid item xs={6} md={3}>
                      <ButtonCancel
                        text={t('Cancel')}
                        color="#747474"
                        borderradius={16}
                        textsize={12}
                        bgcolor="#fff"
                        padding="5px 0"
                        variant="contained"
                        handleClose={handleClose}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <ButtonConfirm
                        text={t('Confirm')}
                        type="submit"
                        color="#fff"
                        borderradius={16}
                        textsize={12}
                        bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
                        padding="5px 0"
                        variant="contained"
                        handleChange={e => handleSubmit(e, status)}
                      />
                    </Grid>
                  </Grid>
                </>
              )
}
        </CustomModalBox>
      </Modal>
    </div>
  );
}

CommentModal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  buttontitle: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string,
  borderradius: PropTypes.number,
  textsize: PropTypes.number,
  textweight: PropTypes.number,
  bgcolor: PropTypes.string,
  modaltitle: PropTypes.string,
  status: PropTypes.string,
  handleSubmit: PropTypes.func,
  isConfirmed: PropTypes.bool,
  isRejected: PropTypes.bool,
  refetch: PropTypes.func,
  confirmedText: PropTypes.string,
  handleCloseParentModal: PropTypes.func,
};

CommentModal.defaultProps = {
  buttontitle: '',
  margin: '0',
  padding: '0',
  color: '#ccc',
  borderradius: 20,
  textsize: 14,
  textweight: 400,
  bgcolor: '#fff',
  modaltitle: '',
  status: '',
  isConfirmed: false,
  isRejected: false,
};

export default CommentModal;
