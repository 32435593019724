/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import EditOrderModal from '../Modals/Orders/EditOrderModal';
import EditReturnModal from '../Modals/Returns/EditReturnModal';
import SortButtons from './SortButtons';
import ViewClientButton from '../IconButtons/ViewClientButton';
import EditItemModal from '../Modals/Items/EditItemModal';
import DeleteItemModal from '../Modals/Items/DeleteItemModal';
import DeleteClientModal from '../Modals/Clients/DeleteClient';
import EditReminderModal from '../Modals/Reminder/EditReminderModal';
import DeleteReminderModal from '../Modals/Reminder/DeleteReminderModal';

const CustomLabel = styled(TableSortLabel)(props => ({
  color: props.color,
  fontWeight: props.fontWeight,
  fontsize: props.fontSize,
  display: 'flex',
  alignItems: 'center',
  cursor: 'default',
  '& .MuiTableSortLabel-icon': {
    display: 'none',
  },
  '&:hover': {
    color: props.color,
  },
}));

const CustomTableCell = styled(TableCell)(props => ({
  padding: props.paddingcell,
  border: 'transparent',
  color: props.colorcell,
  cursor: 'default',
  width: props.width,
  fontWeight: props.fontWeight,
  '&:first-of-type': {
    paddingLeft: 20,
  },
  '&.table-head': {
    maxWidth: '200px',
    minWidth: '200px',
    '&:nth-last-of-type(1)': {
      maxWidth: '60px',
      minWidth: '60px',
    },
    '&:nth-last-of-type(2)': {
      maxWidth: props.customwidth ? props.customwidth : '200px',
      minWidth: props.customwidth ? props.customwidth : '200px',
    },
  },
  '&.comment': {
    maxWidth: '200px',
    minWidth: '200px',
  },
  '&.confirm-quantity': {
    maxWidth: '150px',
    minWidth: '150px',
  },
  '&.children': {
    '&:nth-last-of-type(1)': {
      width: props.lasttypewidth,
    },
    '&:nth-last-of-type(2)': {
      width: props.secondlasttypewidth,
    },
  },
}));

const CustomTableRow = styled(TableRow)(props => ({
  '&:nth-of-type(odd)': {
    backgroundColor: props.backgroundcolor,
    borderBottom: props.border,
  },
  '&:last-of-type': {
    borderRadius: '0 0 12px 12px',
  },
}));

const CustomHeadTableRow = styled(TableRow)(props => ({
  borderBottom: props.border,
  boxShadow: props.boxshadow,
}));

const CustomTableContainer = styled(TableContainer)(({
  boxshadow, borderradius,
}) => ({
  boxShadow: boxshadow,
  borderRadius: borderradius,
  margin: 'auto',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
  },
}));

function TableComponent(props) {
  const {
    margin,
    boxshadowtable,
    borderradiustable,
    boxshadowtablehead,
    border,
    labelcolor,
    headCells,
    paddingcellhead,
    labelfontweight,
    labelfontsize,
    direction,
    requestSort,
    rows,
    backgroundcoloroddrow,
    children,
    colorcell,
    paddingcell,
    lasttypewidth,
    secondlasttypewidth,
    setClient,
    refetch,
    clientTabsRef,
    setIsSeenOrder,
  } = props;

  return (
    <CustomTableContainer
      margin={margin}
      boxshadow={boxshadowtable}
      borderradius={borderradiustable}
    >
      <Table>
        <TableHead>
          <CustomHeadTableRow
            boxshadow={boxshadowtablehead}
            border={border}
            color={labelcolor}
          >
            {headCells.map(
              cell => (
                <CustomTableCell
                  className="table-head"
                  paddingcell={paddingcellhead}
                  key={cell.id}
                  align="left"
                  padding={cell ? 'none' : 'normal'}
                  customwidth={cell.width}
                >
                  <CustomLabel
                    color={labelcolor}
                    fontWeight={labelfontweight}
                    fontSize={labelfontsize}
                  >
                    {cell.label}
                    {cell.sortable && (
                      <SortButtons
                        id={cell.id}
                        direction={direction}
                        requestSort={requestSort}
                      />
                    )}
                  </CustomLabel>
                </CustomTableCell>
              ),
            )}
          </CustomHeadTableRow>
        </TableHead>
        <TableBody>
          {
            rows && rows.map((row, index) => {
              const cellNames = Object.keys(row);
              const fontWeight = row.review === 'New' || (!row.isseen && row.isseen !== undefined) ? 600 : 400;
              const color = row.review === 'New' || (!row.isseen && row.isseen !== undefined) ? '#000' : '#888888';
              return (
                <CustomTableRow
                  key={index}
                  border={border}
                  color={labelcolor}
                  backgroundcolor={backgroundcoloroddrow}
                >
                  {cellNames && cellNames.map((el, index) => {
                    let element = null;
                    switch (el) {
                      case 'view':
                        element = (
                          <ViewClientButton clientId={row.id} setClient={setClient} clientTabsRef={clientTabsRef} />
                        );
                        break;
                      case 'editItem':
                        element = (
                          <EditItemModal
                            backgroundcolor="transparent"
                            backgroundcolorhover="transparent"
                            backgroundcoloropen="linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
                            border="none"
                            boxshadow="none"
                            color="#3384B9"
                            coloropen="#fff"
                            colorhover="#F56F4B"
                            id={row.id}
                            itemName={row.name}
                            itemCode={row.code}
                            refetch={refetch}
                          />
                        );
                        break;
                      case 'deleteItem':
                        element = (
                          <DeleteItemModal id={row.id} refetch={refetch} />
                        );
                        break;
                      case 'viewOrder':
                        element = (
                          <EditOrderModal
                            key={index}
                            orderId={row.id}
                            refetchOrders={refetch}
                            setIsSeenOrder={setIsSeenOrder}
                            seen={row.isseen}
                          >
                            <SearchIcon color="blue" />
                          </EditOrderModal>
                        );
                        break;
                      case 'editOrder':
                        element = (
                          <EditOrderModal
                            key={index}
                            orderId={row.id}
                            editOrder="editOrder"
                            refetchOrders={refetch}
                            setIsSeenOrder={setIsSeenOrder}
                            seen={row.isseen}
                          >
                            <EditIcon color="blue" />
                          </EditOrderModal>
                        );
                        break;
                      case 'editReturn':
                        element = (
                          <EditReturnModal
                            key={index}
                            returnId={row.id}
                            editReturn="editReturn"
                            refetchReturns={refetch}
                            setIsSeenOrder={setIsSeenOrder}
                            seen={row.isseen}
                          >
                            <EditIcon color="blue" />
                          </EditReturnModal>
                        );
                        break;
                      case 'viewReturn':
                        element = (
                          <EditReturnModal
                            key={index}
                            returnId={row.id}
                            refetchReturns={refetch}
                            setIsSeenOrder={setIsSeenOrder}
                            seen={row.isseen}
                          >
                            <SearchIcon color="blue" />
                          </EditReturnModal>
                        );
                        break;
                      case 'deleteClient':
                        element = (
                          <DeleteClientModal clientId={row.id} refetchClients={refetch} />
                        );
                        break;
                      case 'reminderEdit':
                        element = (
                          <EditReminderModal
                            backgroundcolor="transparent"
                            backgroundcolorhover="transparent"
                            backgroundcoloropen="linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
                            border="none"
                            boxshadow="none"
                            color="#3384B9"
                            coloropen="#fff"
                            colorhover="#F56F4B"
                            reminder={row.reminder}
                            clientId={parseInt(row.reminder.accountid, 10)}
                            refetchReminders={refetch}
                          />
                        );
                        break;
                      case 'reminderDelete':
                        element = (
                          <DeleteReminderModal id={parseInt(row.id, 10)} refetch={refetch} />
                        );
                        break;
                      case 'isseen':
                      case 'id':
                      case 'reminder':
                        break;
                      default:
                        return (
                          <CustomTableCell
                            key={index}
                            fontWeight={fontWeight}
                            colorcell={row[el] === 'rejected' ? '#ff0000' : row[el] === 'accepted' ? '#3d75a7' : color}
                            paddingcell={paddingcell}
                          >
                            {row[el]}
                          </CustomTableCell>
                        );
                    }
                    return (
                      element && (
                      <CustomTableCell
                        key={index}
                        colorcell={colorcell}
                        paddingcell={paddingcell}
                        width={secondlasttypewidth}
                      >
                        {element}
                      </CustomTableCell>
                      )
                    );
                  })}
                  {children && Array.isArray(children) && children.map((child, index) => (
                    <CustomTableCell
                      className="children"
                      key={index}
                      colorcell={colorcell}
                      paddingcell={paddingcell}
                      lasttypewidth={lasttypewidth}
                      secondlasttypewidth={secondlasttypewidth}
                    >
                      {child}
                    </CustomTableCell>
                  ))}
                  {children && !Array.isArray(children)
                    && (
                      <CustomTableCell
                        className="children"
                        lasttypewidth={lasttypewidth}
                        colorcell={colorcell}
                        paddingcell={paddingcell}
                      >
                        {children}
                      </CustomTableCell>
                    )}
                </CustomTableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
}

TableComponent.propTypes = {
  requestSort: PropTypes.func,
  direction: PropTypes.string,
  margin: PropTypes.string,
  boxshadowtable: PropTypes.string,
  borderradiustable: PropTypes.number,
  boxshadowtablehead: PropTypes.string,
  border: PropTypes.string,
  labelcolor: PropTypes.string,
  headCells: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    label: PropTypes.string,
    sortable: PropTypes.bool,
    width: PropTypes.string,
  })),
  paddingcellhead: PropTypes.string,
  labelfontweight: PropTypes.number,
  labelfontsize: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.shape()),
  backgroundcoloroddrow: PropTypes.string,
  children: PropTypes.node,
  colorcell: PropTypes.string,
  paddingcell: PropTypes.string,
  lasttypewidth: PropTypes.string,
  secondlasttypewidth: PropTypes.string,
  setClient: PropTypes.func,
  refetch: PropTypes.func,
  clientTabsRef: PropTypes.node,
  setIsSeenOrder: PropTypes.func,
};

TableComponent.defaultProps = {
  direction: '',
  margin: '',
  boxshadowtable: '',
  borderradiustable: 6,
  boxshadowtablehead: '',
  border: '',
  labelcolor: '',
  headCells: [],
  paddingcellhead: '',
  labelfontweight: 400,
  labelfontsize: 12,
  rows: [],
  backgroundcoloroddrow: '',
  colorcell: '',
  paddingcell: '',
  lasttypewidth: '6vw',
  secondlasttypewidth: '6vw',
  children: null,
  setClient: null,
};

export default TableComponent;
