import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useSendEmailNotice from '../../../Hooks/settings/useSendEmailNotice';
import DeleteModal from '../DeleteModal';

function DeleteEmailNoticeModal({
  value, emailsNoticed, refetch, setMessage, setEmailsNoticeArr,
}) {
  const { t } = useTranslation();
  const [isDeleted, setIsDeleted] = useState(false);
  const { mutate: sendEmailNotice } = useSendEmailNotice();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setIsDeleted(false);
  };
  const handleClose = () => setOpen(false);

  const handleChange = event => {
    event.preventDefault();
    const emailsArr = emailsNoticed.filter(email => email !== value);
    const emails = emailsArr.toString('');
    sendEmailNotice(({ emails }), {
      onSuccess: async response => {
        setMessage(response.data.success);
        refetch();
        setIsDeleted(true);
        setEmailsNoticeArr(emailsArr);
      },
      onError: async response => {
        setMessage(response.response.data.error);
        setIsDeleted(false);
      },
    });
  };

  return (
    <DeleteModal
      deletemodaltitle={t('Delete email')}
      deletemodaltext={t('Do you want to delete this email')}
      deletedText={t('Email deleted')}
      handleChange={handleChange}
      isDeleted={isDeleted}
      setIsDeleted={setIsDeleted}
      padding="0"
      buttonColor="#F56F4B"
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
    />
  );
}

DeleteEmailNoticeModal.propTypes = {
  value: PropTypes.string,
  emailsNoticed: PropTypes.arrayOf(PropTypes.string),
  refetch: PropTypes.func,
  setMessage: PropTypes.func,
  setEmailsNoticeArr: PropTypes.func,
};

export default DeleteEmailNoticeModal;
