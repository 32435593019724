/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const postReminder = async (supplierid, accountid, companyname, remindername, reminderdate, reminderrepeat) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'post',
    data: {
      supplierid, accountid, companyname, remindername, reminderdate, reminderrepeat,
    },
    url: '/reminders/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const usePostReminder = () => useMutation(({
  supplierid, accountid, companyname, remindername, reminderdate, reminderrepeat,
}) => postReminder(supplierid, accountid, companyname, remindername, reminderdate, reminderrepeat));

export default usePostReminder;
