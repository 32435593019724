import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled as mstyled } from '@mui/material/styles';
import styled from 'styled-components';
import useGetCities from '../Hooks/useGetCities';
import useRegister from '../Hooks/useRegister';
import emailValidation from '../Validations/email-validation';
import passwordValidation from '../Validations/password-validation';
import pibValidation from '../Validations/pib-validation';
import logo from '../images/logo.png';
import RegisterForm from '../Components/Forms/RegisterForm';
import ImageBox from '../Components/ImageBox';
import LanguageSwitcher from '../Components/LanguageSwitcher';

const PageWrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(63, 116, 166, 1) 0%,
    rgba(63, 116, 166, 1) 0%,
    rgba(49, 167, 203, 1) 100%
  );
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 20px;
  @media (max-width: 1024px) and (min-width: 800px) {
    height: 100%;
    padding: 0 0 20px;
  }
  @media (max-width: 540px) {
    height: 100%;
    padding: 0 0 20px;
  }
`;

const CustomMessageBox = mstyled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto 50px',
  width: '85%',
  borderRadius: 20,
  backgroundColor: '#f56f4bab',
  border: '2px solid #F56F4B',
  padding: 10,
}));

const CustomModalBox = mstyled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
}));

function Register({ language, setLanguage }) {
  const { t } = useTranslation();
  const [city, setCity] = useState(undefined);
  const [suppliername, setSuppliername] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [pib, setPib] = useState('');
  const [websiteurl, setWebsiteurl] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const [message, setMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [pibErrorMessage, setPibErrorMessage] = useState('');
  const [image, setImage] = useState('');
  const [requiredFieldsErros, setRequiredFieldsErrors] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [confirmedPasswordErrorMessage, setConfirmedPasswordErrorMessage] = useState('');
  const {
    data,
    isFetched,
    refetch,
  } = useGetCities();
  const { mutate: register } = useRegister();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (suppliername && city && address && email && password && pib && !pibErrorMessage
      && !emailErrorMessage && !passwordErrorMessage && confirmedPassword
      && !confirmedPasswordErrorMessage) {
      setDisableSubmit(false);
    }
  }, [
    suppliername,
    city,
    address,
    email,
    password,
    pib,
    pibErrorMessage,
    emailErrorMessage,
    passwordErrorMessage,
    confirmedPassword,
    confirmedPasswordErrorMessage,
  ]);

  const imageChanged = event => {
    const { files } = event.target;
    if (files.length !== 0) {
      setImageUrl(URL.createObjectURL(files[0]));
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = e => {
      setImage(e.target.result);
    };
  };

  const handlePib = value => {
    const pibError = pibValidation(value);
    if (pibError) {
      setPibErrorMessage(t('Invalid pib'));
    } else {
      setPibErrorMessage(t(''));
    }
    setPib(value);
  };
  const handleEmail = value => {
    const emailError = emailValidation(value);
    if (emailError) {
      setEmailErrorMessage(t('Invalid email address'));
    } else {
      setEmailErrorMessage(t(''));
    }
    setEmail(value);
  };
  const handlePassword = value => {
    const passwordError = passwordValidation(value);
    if (passwordError) {
      setPasswordErrorMessage(t('Invalid password'));
    } else {
      setPasswordErrorMessage(t(''));
    }
    setPassword(value);
  };

  const confirmPassword = value => {
    setConfirmedPassword(value);
    if (password !== value) {
      setConfirmedPasswordErrorMessage(t('Confirmed password error'));
    } else {
      setConfirmedPasswordErrorMessage('');
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    return register(({
      suppliername, address, city, image, email, password, pib, websiteurl,
    }), {
      onSuccess: async () => {
        setIsChanged(true);
        setCity('');
        setSuppliername('');
        setAddress('');
        setEmail('');
        setPassword('');
        setConfirmedPassword('');
        setImageUrl('');
        setPib('');
        setWebsiteurl('');
        setImage('');
        setMessage(t('Your account has been created'));
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      },
      onError: async res => {
        setIsChanged(false);
        if (res.response.data.detail) {
          setMessage(t('Email error message'));
        }
        if (res.response.data.length !== 0) {
          const errorKeys = Object.keys(res.response.data);
          setRequiredFieldsErrors(Object.keys(errorKeys));
          const errorMessage = [];
          errorKeys.forEach(key => {
            if (key === 'pib') {
              errorMessage.push(t('Pib error message'));
            } else {
              errorMessage.push(res.response.data[key]);
            }
          });
          setMessage(errorMessage.toString());
        }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      },
    });
  };

  return (
    <PageWrapper>
      <LanguageSwitcher
        language={language}
        setLanguage={setLanguage}
      />
      <Container component="main" maxWidth="xs">
        <CustomModalBox>
          <ImageBox
            width="180px"
            height="120px"
            objectFit="contain"
            margin="50px"
            imgSrc={logo}
          />
          {message
           && (
           <CustomMessageBox>
             <Typography color="primary">
               {message}
             </Typography>
           </CustomMessageBox>
           )}
          <RegisterForm
            handleSubmit={handleSubmit}
            setSuppliername={setSuppliername}
            suppliername={suppliername}
            setCity={setCity}
            city={city}
            imageChanged={imageChanged}
            imageUrl={imageUrl}
            setAddress={setAddress}
            address={address}
            data={data}
            isFetched={isFetched}
            email={email}
            setEmail={handleEmail}
            password={password}
            setPassword={handlePassword}
            isChanged={isChanged}
            pib={pib}
            setPib={handlePib}
            setWebsiteurl={setWebsiteurl}
            websiteurl={websiteurl}
            emailErrorMessage={emailErrorMessage}
            passwordErrorMessage={passwordErrorMessage}
            pibErrorMessage={pibErrorMessage}
            requiredFieldsErros={requiredFieldsErros}
            disableSubmit={disableSubmit}
            confirmPassword={confirmPassword}
            confirmedPassword={confirmedPassword}
            confirmedPasswordErrorMessage={confirmedPasswordErrorMessage}
          />
          <Grid item>
            <Link href="/login" variant="body2">
              {t('Login message')}
            </Link>
          </Grid>
        </CustomModalBox>
      </Container>
    </PageWrapper>
  );
}

Register.propTypes = {
  language: PropTypes.string,
  setLanguage: PropTypes.func.isRequired,
};

export default Register;
