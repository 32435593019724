import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getReturn = async (returnId) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: `/returns/${returnId}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetReturn = (returnId) => useQuery(['return', returnId], () => getReturn(returnId), { enabled: false });

export default useGetReturn;
