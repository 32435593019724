import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Pdf from '../../images/officeicon/pdf.png';

const CustomIconButton = styled(IconButton)((props) => ({
  borderRadius: '100%',
  backgroundColor: props.backgroundcolor,
  padding: 0,
  width: props.width,
  maxWidth: props.width,
  minWidth: props.width,
  height: props.height,
  marginRight: props.marginright,
  boxShadow: props.boxshadow,
  '&:hover': {
    backgroundColor: props.backgroundcolorhover,
  },
  '&.active': {
    backgroundColor: props.backgroundcolorhover,
  },
}));

function PdfIconButton({
  width,
  height,
  marginright,
  boxshadow,
  backgroundcolor,
  backgroundcolorhover,
  colorhover,
  handleClick,
}) {
  return (
    <CustomIconButton
      aria-label="download-pdf"
      width={width}
      height={height}
      marginright={marginright}
      boxshadow={boxshadow}
      backgroundcolor={backgroundcolor}
      backgroundcolorhover={backgroundcolorhover}
      colorhover={colorhover}
      onClick={handleClick}
    >
      <img width="90%" height="auto" src={Pdf} alt="pdf-download" />
    </CustomIconButton>
  );
}

PdfIconButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  marginright: PropTypes.number,
  boxshadow: PropTypes.string,
  backgroundcolor: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  colorhover: PropTypes.string,
  handleClick: PropTypes.func,
};
PdfIconButton.defaultProps = {
  width: 42,
  height: 42,
  marginright: 0,
  boxshadow: '',
  backgroundcolor: 'transparent',
  backgroundcolorhover: 'transparent',
  colorhover: 'transparent',

};

export default PdfIconButton;
