/* eslint-disable max-len */
import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const editItem = async (id, name, code) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'put',
    data: { name, code },
    url: `/products/${id}/`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useEditItem = () => useMutation(({ id, name, code }) => editItem(id, name, code));

export default useEditItem;
