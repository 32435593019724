import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import NavItem from './NavItem';

function SupportNavItem(props) {
  const {
    width,
    iconwidth,
    iconheight,
    marginright,
    boxshadow,
    colorhover,
    backgroundcolor,
    backgroundcolorhover,
    color,
  } = props;
  const { t } = useTranslation();
  return (
    <NavItem
      aria-label="support"
      to="/support"
      width={width}
      color={color}
      iconwidth={iconwidth}
      iconheight={iconheight}
      marginright={marginright}
      boxshadow={boxshadow}
      backgroundcolor={backgroundcolor}
      backgroundcolorhover={backgroundcolorhover}
      colorhover={colorhover}
      title={t('Support')}
    >
      <ForumOutlinedIcon color="primary" />
    </NavItem>
  );
}

SupportNavItem.propTypes = {
  width: PropTypes.number,
  iconwidth: PropTypes.number,
  iconheight: PropTypes.number,
  boxshadow: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  colorhover: PropTypes.string,
  backgroundcolor: PropTypes.string,
  marginright: PropTypes.number,
  color: PropTypes.string,
};

SupportNavItem.defaultProps = {
  width: 0,
  iconwidth: 16,
  iconheight: 16,
  backgroundcolor: '',
  backgroundcolorhover: '#fff',
  colorhover: '',
  boxshadow: '',
  marginright: 10,
  color: '',
};

export default SupportNavItem;
