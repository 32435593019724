import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import InputField from '../InputField';

function PasswordInputField({
  grid,
  borderradius,
  border,
  padding,
  bgcolor,
  required,
  name,
  placeholder,
  requiredcolor,
  placeholdercolor,
  handleChange,
  password,
  errorMessage,
  label,
  requiredError,
  margin,
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <InputField
      name={name || 'password'}
      grid={grid}
      required={required}
      type={showPassword ? 'text' : 'password'}
      placeholder={placeholder || t('Password')}
      label={label}
      borderradius={borderradius}
      border={border}
      padding={padding}
      bgcolor={bgcolor}
      placeholdercolor={placeholdercolor}
      handleChange={handleChange}
      defaultValue={password}
      errorMessage={errorMessage}
      requiredError={requiredError}
      margin={margin}
      endAdornment={(
        <InputAdornment position="end" sx={{ px: 2 }}>
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      )}
      requiredcolor={requiredcolor}
    />
  );
}

PasswordInputField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  bgcolor: PropTypes.string,
  border: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  placeholdercolor: PropTypes.string,
  requiredcolor: PropTypes.string,
  handleChange: PropTypes.func,
  password: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  requiredError: PropTypes.bool,
  margin: PropTypes.string,
};

PasswordInputField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  bgcolor: '#fff',
  border: '',
  required: false,
  name: 'password',
  placeholder: 'Password',
  placeholdercolor: '#efefef',
  requiredcolor: '#fff',
  password: '',
  errorMessage: '',
  label: 'Message',
  requiredError: false,
  margin: '0px',
};

export default PasswordInputField;
