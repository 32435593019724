import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export default function OrderDetailsSkeleton() {
  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <Skeleton animation="wave" width="90px" height="145px" sx={{ borderRadius: '10px' }} />
      <Skeleton animation="wave" width="30%" height="35px" />
      <Skeleton animation="wave" width="100%" height="35px" />
      <Skeleton animation="wave" width="100%" height="35px" />
      <Skeleton animation="wave" width="100%" height="35px" />
      <Skeleton animation="wave" width="100%" height="35px" />
      <Skeleton animation="wave" width="100%" height="35px" />
    </Box>
  );
}
