/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import PaginationComponent from '../Pagination/Pagination';
import TableComponent from './TableComponent';
import TableSkeleton from '../Skeletons/TableSkeleton';

function ItemsTable({
  rows, headCells, refetch, setPage, page, count, statusLoad,
}) {
  const { t } = useTranslation();

  return (
    <>
      {' '}
      { statusLoad === 'loading'
        ? (
          <TableSkeleton />
        )
        : rows.length === 0
          ? (
            <Typography color="secondary" fontSize={20}>
              {t('No results')}
            </Typography>
          )
          : (
            <TableComponent
              headCells={headCells}
              rows={rows}
              margin="5px"
              boxshadowtable="0px 3px 10px 4px #f6f6f6"
              boxshadowtablehead="0px 3px 3px 0px #f6f6f6"
              paddingcellhead="14px 0"
              borderradiustable={16}
              labelfontsize={14}
              labelcolor="#3384B9"
              labelfontweight={500}
              border="1px solid #f6f6f678"
              backgroundcoloroddrow="#F9F9F9"
              colorcell="#888888"
              paddingcell="12px 0"
              lasttypewidth="6vw"
              refetch={refetch}
            />
          )}
      {rows.length !== 0
          && (
          <PaginationComponent
            handleChangePage={setPage}
            count={count}
            pageNum={page}
          />
          )}
    </>
  );
}

ItemsTable.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    label: PropTypes.string,
    sortable: PropTypes.bool,
  })),
  rows: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
  })),
  refetch: PropTypes.func,
  setPage: PropTypes.func,
  page: PropTypes.number,
  count: PropTypes.number,
  statusLoad: PropTypes.string,
};

ItemsTable.defaultProps = {
  headCells: [],
  rows: [],
};

export default ItemsTable;
