import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import useEditItem from '../../../Hooks/items/useEditItem';
import ItemNameInputField from '../../Forms/InputFields/ItemInputFields/ItemNameInputField';
import ItemCodeInputField from '../../Forms/InputFields/ItemInputFields/ItemCodeInputField';
import EditModal from '../EditModal';

const CustomButtonConfirm = styled(Button)(() => ({
  background:
        'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  color: '#fff',
}));

function EditItemModal({
  itemName,
  itemCode,
  backgroundcoloropen,
  backgroundcolor,
  border,
  boxshadow,
  color,
  coloropen,
  backgroundcolorhover,
  colorhover,
  id,
  refetch,
}) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [open, setOpen] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const { mutate: editItem, isLoading } = useEditItem();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (name && code) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [name, code]);

  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    setOpen(true);
    setIsEdited(false);
    setCode(itemCode);
    setName(itemName);
  };

  const handleChange = (event) => {
    event.preventDefault();
    return editItem(({ id, name, code }), {
      onSuccess: async () => {
        setIsEdited(true);
        refetch();
      },
      onError: async () => {
      },
    });
  };

  return (
    <EditModal
      modaltitle={t('Edit item')}
      backgroundcoloropen={backgroundcoloropen}
      backgroundcolor={backgroundcolor}
      backgroundcolorhover={backgroundcolorhover}
      border={border}
      boxshadow={boxshadow}
      color={color}
      coloropen={coloropen}
      colorhover={colorhover}
      width={270}
      buttonssize={3.5}
      titlemargin="5px 0"
      handleChange={handleChange}
      open={open}
      isChanged={isEdited}
      handleClose={handleClose}
      handleOpen={handleOpen}
      disabledSubmitButton={isButtonDisabled}
      isLoading={isLoading}
    >
      {
        isEdited
          ? (
            <Box sx={{ margin: '30px 0 0', width: '100%' }}>
              <Typography color="secondary" sx={{ margin: '0 0 30px' }}>{t('Item edited')}</Typography>
              <CustomButtonConfirm
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                {t('Confirm')}
              </CustomButtonConfirm>
            </Box>
          )
          : (
            <Grid container spacing={1.1} marginTop={1}>
              <ItemNameInputField
                grid={6}
                bgcolor="#fff"
                borderradius={6}
                border="1px solid #ded9d9"
                padding="6px 18px"
                defaultValue={name}
                handleChange={setName}
                fontsize={12}
              />
              <ItemCodeInputField
                grid={6}
                bgcolor="#fff"
                borderradius={6}
                border="1px solid #ded9d9"
                padding="6px 18px"
                defaultValue={code}
                handleChange={setCode}
                fontsize={12}
              />
            </Grid>
          )
      }
    </EditModal>
  );
}

EditItemModal.propTypes = {
  itemName: PropTypes.string,
  itemCode: PropTypes.string,
  backgroundcoloropen: PropTypes.string,
  backgroundcolor: PropTypes.string,
  color: PropTypes.string,
  coloropen: PropTypes.string,
  border: PropTypes.string,
  boxshadow: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  colorhover: PropTypes.string,
  id: PropTypes.number,
  refetch: PropTypes.func,
};

EditItemModal.defaultProps = {
  itemName: '',
  itemCode: '',
  backgroundcoloropen: '',
  backgroundcolor: '',
  color: '',
  coloropen: '',
  border: '',
  boxshadow: '',
  backgroundcolorhover: '',
  colorhover: '',
  id: '',
};

export default EditItemModal;
