import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getListOfItems = async param => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: `/products-api/${param}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetListOfItems = param => useQuery(['items', param], () => getListOfItems(param), { enabled: true });

export default useGetListOfItems;
