/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useGetListOfReminders from '../Hooks/reminders/useGetListOfReminders';
import AddReminderModal from '../Components/Modals/Reminder/AddReminderModal';
import RemindersTable from '../Components/Tables/RemindersTable';

function CreateData(name, repeat, id, reminder) {
  return {
    name,
    repeat,
    reminderEdit: true,
    reminderDelete: true,
    id,
    reminder,
  };
}

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.blue.main,
  fontWeight: 600,
  clear: 'both',
  marginBottom: 30,
}));

function OrderReminders() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  let count = 0;
  let limit = 10;
  const {
    status,
    data,
    isFetched,
    refetch,
  } = useGetListOfReminders(`?page=${page}`);

  const [flag, setFlag] = useState(false);
  const remindersList = [];
  const reminderValue = [
    t('Inactive'),
    t('No repeat'),
    t('Daily'),
    t('Weekly'),
    t('Monthly'),
  ];

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('Reminder name'),
      sortable: true,
    },
    {
      id: 'reminder-repeat',
      numeric: true,
      disablePadding: false,
      label: t('Repeat reminder'),
      sortable: false,
    },
    {
      id: 'edit',
      numeric: false,
      disablePadding: false,
      label: t('Edit'),
      sortable: false,
    },
    {
      id: 'delete',
      numeric: false,
      disablePadding: false,
      label: t('Remove'),
      sortable: false,
    },
  ];

  if (isFetched || flag) {
    const reminders = data && data?.data && data?.data.slice(0, 10);
    const ordersNumber = data && data.headers['x-pagination'].split(', ')[0].match(/\d/g).join('');
    limit = data && data.headers['x-pagination'].split(', ')[1].match(/\d/g).join('');
    count = Math.ceil(+ordersNumber / +limit);
    reminders?.map(item => remindersList?.push(CreateData(item?.remindername, reminderValue[item?.reminderrepeat], item?.id, item)));
  }

  return (
    <Grid item xs={12} md={12}>
      <CustomTypography variant="h6" color="blue">{t('Send reminder')}</CustomTypography>
      <Grid marginBottom={3}>
        <AddReminderModal refetch={refetch} setFlag={setFlag} />
      </Grid>
      <RemindersTable
        headCells={headCells}
        rows={remindersList}
        refetch={refetch}
        setPage={setPage}
        statusLoad={status}
        count={count}
        page={page}
      />
    </Grid>
  );
}

export default OrderReminders;
