import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getOrderItemsXls = async (orderId) => {
  const token = localStorage.getItem('token');
  customAxios({
    method: 'get',
    responseType: 'arraybuffer',
    url: `/orders/${orderId}/items/xls`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      if (response && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `order_${orderId}.xls`);
        document.body.appendChild(link);
        link.click();
      }
    });
};

const useGetOrderItemsXls = (orderId) => useQuery(['itemsExcelFile', orderId], () => getOrderItemsXls(orderId), { enabled: false });

export default useGetOrderItemsXls;
