/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TabPanel from './TabPanel';

const CustomTabs = styled(Tabs)(({ theme }) => ({
  clear: 'both',
  '& .MuiTab-root': {
    borderBottom: '1px solid #ded9d9',
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 600,
    padding: '12px 16px 2px',
  },
  '& .MuiTab-root:nth-of-type(1)': {
    alignItems: 'baseline',
    padding: '12px 16px 2px 0',
    minWidth: '75px',
  },
  '& .MuiTab-root.Mui-selected': {
    color: theme.palette.blue.main,
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.secondary.main,
    width: 80,
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: '30px',
  },
}));

function TabsComponent(props) {
  const [active, setActive] = useState(0);
  const [tabsNames, setTabsNames] = useState([]);

  useEffect(() => {
    setTabsNames(props.tabsnames);
    const activeTab = active ? 'completed' : 'new';
    if (props.setTab) {
      props.setTab(activeTab);
    }
  }, [props.tabsnames, active]);

  const handleChange = event => {
    setActive(parseInt(event.target.getAttribute('index'), 10));
    if (props.setPage) {
      props.setPage(1);
    }
  };

  const tabsArray = tabsNames
        && tabsNames.map((tab, index) => (
          <Tab key={index} value={index} label={tab} index={index} />
        ));
  const tabsPanelsArray = tabsNames
    && tabsNames.map((tab, index) => (
      <TabPanel
        key={index}
        value={index}
        index={active}
      >
        {props.children && index === active
                && props.children[active]}
      </TabPanel>
    ));

  return (
    <Box sx={{ width: props.width }}>
      <CustomTabs value={active} onChange={handleChange}>
        {tabsArray}
      </CustomTabs>
      {tabsPanelsArray}
    </Box>
  );
}

export default TabsComponent;
