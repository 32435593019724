/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const CustomIconButton = styled(IconButton)((props) => ({
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: props.backgroundcolor,
  width: props.width,
  maxWidth: props.width,
  minWidth: props.width,
  height: props.height,
  boxShadow: props.boxshadow,
  fontSize: props.fontSize,
  padding: props.padding,
  margin: props.margin,
  '&:hover': {
    backgroundColor: props.backgroundcolorhover,
  },
}));

const CustomArrowIcon = styled(SearchIcon)((props) => ({
  fill: props.iconcolor,
  fontSize: props.iconsize,
}));

function SearchIconButton(props) {
  return (
    <CustomIconButton
      onClick={props.handleClick}
      aria-label="search-icon"
      width={props.width}
      height={props.height}
      boxshadow={props.boxshadow}
      padding={props.padding}
      margin={props.margin}
      fontSize={props.fontSize}
      backgroundcolor={props.backgroundcolor}
      backgroundcolorhover={props.backgroundcolorhover}
      colorhover={props.colorhover}
    >
      <CustomArrowIcon
        iconcolor={props.color}
        iconsize={props.iconsize}
      />
    </CustomIconButton>
  );
}

export default SearchIconButton;
