import { useMutation } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const deleteClient = async (clientId) => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'delete',
    url: `/clients/${clientId}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useDeleteClient = () => useMutation(({ clientId }) => deleteClient(clientId));

export default useDeleteClient;
