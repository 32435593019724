import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

function LanguageSwitcher(props) {
  const {
    language,
    setLanguage,
  } = props;
  const { i18n } = useTranslation();

  const [languageChanged, setLanguageChanged] = useState(false);

  const handleLanguageChange = (event, changed) => {
    event.preventDefault();
    if (changed) {
      i18n.changeLanguage('sr');
      setLanguage('Eng');
      localStorage.setItem('lang', 'sr');
    } else {
      i18n.changeLanguage('en');
      setLanguage('Srb');
      localStorage.setItem('lang', 'en');
    }
    setLanguageChanged(changed);
  };
  return (
    <Box marginTop={2} display="flex" justifyContent="end" width="100%" position="absolute" top="0">
      <Button type="text" color="primary" sx={{ marginRight: '50px' }} onClick={e => handleLanguageChange(e, !languageChanged)}>
        {language}
      </Button>
    </Box>
  );
}

LanguageSwitcher.propTypes = {
  language: PropTypes.string,
  setLanguage: PropTypes.func,
};

LanguageSwitcher.defaultProps = {
  language: '',
};

export default LanguageSwitcher;
