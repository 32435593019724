import { useMutation } from 'react-query';
import axios from 'axios';
import { API_BASE, buff } from '../../utils/custom-authentication';

const register = async (
  suppliername,
  address,
  websiteurl,
  email,
  password,
  city,
  pib,
  image,
) => axios({
  method: 'post',
  url: `${API_BASE}/supplier/registration/`,
  data: {
    suppliername, address, websiteurl, email, password, city_id: city, pib, logo_image: image,
  },
  headers: {
    'X-AppAuth': buff,
  },
});

const useRegister = () => useMutation(({
  suppliername, address, websiteurl, email, password, city, pib, image,
}) => register(suppliername, address, websiteurl, email, password, city, pib, image));

export default useRegister;
