import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputField from '../InputField';

function AddressInputField({
  grid,
  borderradius,
  border,
  padding,
  bgcolor,
  required,
  placeholdercolor,
  requiredcolor,
  address,
  handleChange,
  requiredError,
}) {
  const { t } = useTranslation();
  return (
    <InputField
      name="address"
      grid={grid}
      required={required}
      type="text"
      placeholder={t('Address')}
      label={t('Address')}
      borderradius={borderradius}
      border={border}
      padding={padding}
      bgcolor={bgcolor}
      requiredcolor={requiredcolor}
      placeholdercolor={placeholdercolor}
      defaultValue={address}
      handleChange={handleChange}
      requiredError={requiredError}
    />
  );
}

AddressInputField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  bgcolor: PropTypes.string,
  border: PropTypes.string,
  required: PropTypes.bool,
  placeholdercolor: PropTypes.string,
  requiredcolor: PropTypes.string,
  address: PropTypes.string,
  handleChange: PropTypes.func,
  requiredError: PropTypes.bool,
};

AddressInputField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  bgcolor: '#fff',
  border: '',
  required: false,
  placeholdercolor: '#efefef',
  requiredcolor: '#fff',
  address: '',
  requiredError: false,
};

export default AddressInputField;
