/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import { styled as mstyled } from '@mui/material/styles';
import styled from 'styled-components';

const CustomMenu = mstyled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '& .MuiList-root > div': {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
    },
  },
}));

const MobileMenuWrapper = styled.div`
  @media (min-width: 900px) {
    display: none;
  }
`;

function MobileMenu(props) {
  const {
    anchorEl,
    handleClose,
    children,
    toggleMenu,
  } = props;
  const open = Boolean(anchorEl);
  return (
    <MobileMenuWrapper>
      <IconButton sx={{ marginRight: 1 }} onClick={(e) => toggleMenu(e, open)}>
        <HomeIcon color="blue" />
      </IconButton>
      <CustomMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        open={open}
      >
        {children}
      </CustomMenu>
    </MobileMenuWrapper>
  );
}

MobileMenu.propTypes = {
  anchorEl: PropTypes.shape(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

MobileMenu.defaultProps = {
  anchorEl: {},
};

export default MobileMenu;
