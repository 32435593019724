/* eslint-disable max-len */
import { useQuery } from 'react-query';
import customAxios from '../../../axios/custom-axios';

const getEmailNotice = async () => {
  const token = localStorage.getItem('token');
  return customAxios({
    method: 'get',
    url: '/supplier/notifymails/',
    headers: { Authorization: `Bearer ${token}` },
  });
};

const useGetEmailNotice = () => useQuery('emails', () => getEmailNotice());

export default useGetEmailNotice;
