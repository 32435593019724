import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import EmailInputField from './InputFields/RegisterFields/EmailInputField';
import FullNameInputField from './InputFields/Users/FullNameInputField';
import ButtonConfirm from './Buttons/ButtonConfirm';

const CustomMessageBox = styled(Box)(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '85%',
  borderRadius: 20,
  backgroundColor: props.isError ? '#f56f4bab' : '#429dd9',
  border: props.isError ? '2px solid #F56F4B' : '2px solid #3384B9',
  padding: 10,
}));

function AddNewUser({
  message, username, fullname,
  setUsername, setFullname, isError, emailErrorMessage, handleChange, disabledAddNewUserButton,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Typography fontSize={14} fontWeight={600} color="#3384B9" marginBottom={2} marginTop={4}>{t('Add new user')}</Typography>
      <Grid container spacing={1} display="flex" alignItems="center">
        <EmailInputField
          required
          grid={5}
          borderradius={6}
          border="1px solid #d1d1d1"
          padding="5px 15px"
          placeholdercolor="#9c9c9c"
          handleChange={setUsername}
          email={username}
          errorMessage={emailErrorMessage}
          requiredcolor="#9c9c9c"
        />
        <FullNameInputField
          required
          grid={5}
          borderradius={6}
          border="1px solid #d1d1d1"
          padding="7px 15px"
          placeholdercolor="#9c9c9c"
          handleChange={setFullname}
          fullname={fullname}
          requiredcolor="#9c9c9c"
        />
        <Grid item xs={2} marginTop="20px">
          <ButtonConfirm
            type="submit"
            color="#fff"
            margin="0"
            padding="2px 0"
            borderradius={20}
            textsize={13}
            textweight={500}
            bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
            variant="contained"
            text={t('Add')}
            handleChange={handleChange}
            disabled={disabledAddNewUserButton}
          />
        </Grid>
        <Grid item xs={12}>
          {message
           && (
           <CustomMessageBox isError={isError}>
             <Typography color="primary">
               {message}
             </Typography>
           </CustomMessageBox>
           )}
        </Grid>
      </Grid>
    </>
  );
}

AddNewUser.propTypes = {
  fullname: PropTypes.string,
  username: PropTypes.string,
  message: PropTypes.string,
  setUsername: PropTypes.func,
  setFullname: PropTypes.func,
  handleChange: PropTypes.func,
  isError: PropTypes.bool,
  emailErrorMessage: PropTypes.string,
  disabledAddNewUserButton: PropTypes.bool,
};

AddNewUser.defaultProps = {
  fullname: '',
  username: '',
  disabledAddNewUserButton: true,
};

export default AddNewUser;
