import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ModalComponent from './ModalComponent';
import StyledDeleteIconButton from '../IconButtons/DeleteIconButton/StyledDeleteIconButton';

const CustomTypography = styled(Typography)(() => ({
  margin: 10,
  fontSize: 14,
  textAlign: 'left',
  color: '#747474',
}));

const CustomButtonConfirm = styled(Button)(() => ({
  background:
        'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  color: '#fff',
}));

function DeleteModal({
  deletemodaltext,
  deletemodaltitle,
  handleChange,
  isDeleted,
  deletedText,
  padding,
  buttonColor,
  colorHover,
  open,
  handleClose,
  handleOpen,
}) {
  const { t } = useTranslation();
  const [offsetTop, setOffsetTop] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);

  return (
    <>
      <StyledDeleteIconButton
        handleClick={open ? handleClose : handleOpen}
        ref={(el) => {
          if (!el) return;
          setOffsetTop(el.getBoundingClientRect().top);
          setOffsetLeft(el.getBoundingClientRect().left);
        }}
        width="35px"
        color={open ? '#F56F4B' : buttonColor}
        border={open ? '1px solid #f6f6f6' : 'none'}
        boxshadow={open ? '1px 1px 1px 0px #e5e3e3' : 'none'}
        backgroundcolor={open ? '#f8f8f8' : 'transparent'}
        backgroundcolorhover={open ? '#f8f8f8' : 'transparent'}
        borderhover={open ? '1px solid #f6f6f6' : 'none'}
        boxshadowhover={open ? '1px 1px 1px 0px #e5e3e3' : 'none'}
        position={open ? 'relative' : 'inherit'}
        zindex={open ? 9999 : 0}
        colorhover={open ? '#F56F4B' : colorHover}
        iconsize="25px"
        padding={padding}
      />
      <ModalComponent
        open={open}
        handleClose={handleClose}
        offsetTop={offsetTop}
        offsetLeft={offsetLeft}
        width={270}
        padding="15px 25px 20px"
        backgroundcolor="#f8f8f8"
        titlemargin="0 10px 10px"
        color="secondary"
        title={deletemodaltitle}
        buttonssize={4}
        handleChange={handleChange}
        isChanged={isDeleted}
      >
        {
        isDeleted
          ? (
            <Box sx={{ margin: '30px 0 0' }}>
              <Typography color="secondary" sx={{ margin: '0 0 30px' }}>{deletedText}</Typography>
              <CustomButtonConfirm
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                {t('Close')}
              </CustomButtonConfirm>
            </Box>
          )
          : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTypography>
                  {deletemodaltext}
                </CustomTypography>
              </Grid>
            </Grid>
          )
        }
      </ModalComponent>
    </>
  );
}

DeleteModal.propTypes = {
  deletemodaltext: PropTypes.string,
  deletemodaltitle: PropTypes.string,
  handleChange: PropTypes.func,
  isDeleted: PropTypes.bool,
  deletedText: PropTypes.string,
  padding: PropTypes.string,
  buttonColor: PropTypes.string,
  colorHover: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
};

DeleteModal.defaultProps = {
  deletemodaltext: '',
  deletemodaltitle: '',
  isDeleted: false,
  deletedText: 'Item deleted',
  padding: '10px',
  buttonColor: '#3384B9',
  colorHover: '#F56F4B',
};

export default DeleteModal;
