/* eslint-disable no-useless-escape */
const passwordValidation = password => {
  let errorMessage = false;
  const passwordformat = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+, -./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+, -./:;<=>?@[\]^_`{|}~]{6,}$/;

  if (password.match(passwordformat)) {
    errorMessage = false;
  } else {
    errorMessage = true;
  }
  return errorMessage;
};

export default passwordValidation;
