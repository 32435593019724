/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import usePostReminder from '../../../Hooks/reminders/usePostReminder';
import AddModal from '../AddModal';
import InputField from '../../Forms/InputFields/InputField';
import SearchClients from '../../Forms/SearchClients';
import ReminderCalendar from '../../Calendar/ReminderCalendar/ReminderCalendar';
import RepeatReminder from '../../Reminder/RepeatReminderComponent';

const CustomButtonConfirm = styled(Button)(() => ({
  background:
        'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)',
  borderRadius: 16,
  fontSize: 10,
  padding: '3px 15px',
  color: '#fff',
}));

function AddReminderModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const { mutate: postReminder } = usePostReminder();
  const [reminderRepeat, setReminderRepeat] = useState('1');
  const [reminderDate, setReminderDate] = useState(new Date());
  const [reminderName, setReminderName] = useState('');
  const [selectedClient, setSelectedClient] = useState([]);
  const [requiredFieldsErros, setRequiredFieldsErrors] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleOpen = () => {
    setOpen(true);
    setIsAdded(false);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (reminderRepeat && reminderDate && reminderName && selectedClient.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [reminderRepeat, reminderDate, reminderName, selectedClient]);

  const handleChange = event => {
    event.preventDefault();
    return postReminder(({
      accountid: selectedClient[0],
      companyname: '',
      remindername: reminderName,
      reminderdate: reminderDate || new Date(),
      reminderrepeat: reminderRepeat,
    }), {
      onSuccess: async () => {
        setIsAdded(true);
        setReminderRepeat('1');
        setReminderDate(new Date());
        setReminderName('');
        setSelectedClient([]);
        setRequiredFieldsErrors([]);
        props.setFlag(true);
        props.refetch();
      },
      onError: async response => {
        setIsAdded(false);
        setRequiredFieldsErrors(Object.keys(response.response.data));
      },
    });
  };
  const selectall = { label: t('Select all'), name: 'select-all' };
  return (
    <div>
      <AddModal
        titlemargin="0 0 10px"
        width={600}
        bgcolor="#fff"
        padding="30px 25px 30px 40px"
        modaltitle={t('Add new reminder')}
        buttonssize={1.8}
        isChanged={isAdded}
        handleChange={handleChange}
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        disabledSubmitButton={isButtonDisabled}
      >
        {isAdded
          ? (
            <Box sx={{ margin: '30px 0 0', width: '100%' }}>
              <Typography color="secondary" sx={{ margin: '0 0 30px' }}>{t('Reminder added')}</Typography>
              <CustomButtonConfirm
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                {t('Close')}
              </CustomButtonConfirm>
            </Box>
          )
          : (
            <Grid container spacing={1.1}>
              <Grid item xs={12}>
                <SearchClients
                  selectall={selectall}
                  setSelectedClients={setSelectedClient}
                  selectedClients={selectedClient}
                  isReminder
                  searchplaceholder={t('Search for a company')}
                />
                {requiredFieldsErros.includes('accountid')
                && <Typography color="secondary" fontSize={12}>{t('This field is required')}</Typography>}
              </Grid>
              <InputField
                grid={12}
                bgcolor="#fff"
                name="reminder-name"
                placeholder={t('Reminder name')}
                label={t('Reminder name')}
                borderradius={6}
                border="1px solid #ded9d9"
                padding="10px 18px"
                fontsize={12}
                handleChange={setReminderName}
                defaultValue={reminderName}
              />
              {requiredFieldsErros.includes('remindername')
                && <Typography color="secondary" fontSize={12}>{t('This field is required')}</Typography>}
              <Grid item xs={6}>
                <ReminderCalendar
                  reminderdate={reminderDate}
                  setReminderDate={setReminderDate}
                />
              </Grid>
              <Grid item xs={6}>
                <RepeatReminder
                  reminderrepeat={reminderRepeat}
                  reminderdate={reminderDate}
                  setReminderRepeat={setReminderRepeat}
                />
              </Grid>
            </Grid>
          )}
      </AddModal>
    </div>
  );
}

export default AddReminderModal;
