import { useQuery } from 'react-query';
import axios from 'axios';
import { API_BASE, buff } from '../../utils/custom-authentication';

const getCities = async () => axios({
  method: 'GET',
  url: `${API_BASE}/country/cities/rs`,
  headers: {
    'X-AppAuth': buff,
  },
});
const useGetCities = () => useQuery(['cities'], () => getCities(), { enabled: false });

export default useGetCities;
