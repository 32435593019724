import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useGetCities from '../Hooks/useGetCities';
import useGetSupplier from '../Hooks/settings/useGetSupplier';
import useSendEmailNotice from '../Hooks/settings/useSendEmailNotice';
import useGetEmailNotice from '../Hooks/settings/useGetEmailNotice';
import useChangeSupplierData from '../Hooks/settings/useChangeSupplierData';
import SupplierDataComponent from '../Components/Settings/SupplierDataComponent';
import SupplierChangePasswordModal from '../Components/Modals/SupplierChangePasswordModal/SupplierChangePasswordModal';
import SupplierEditDataModal from '../Components/Modals/SupplierEditDataModal/SupplierEditDataModal';
import MailNotice from '../Components/Forms/MailNotice/MailNotice';
import LogoutModal from '../Components/Modals/LogoutModal';
import emailValidation from '../Validations/email-validation';

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.blue.main,
  fontWeight: 600,
  margin: '0 0 50px',
  clear: 'both',
}));

function Settings({ setToken }) {
  const { t } = useTranslation();
  let supplier = [];
  const [city, setCity] = useState(supplier?.city_id);
  const [emailNotice, setEmailsNotice] = useState('');
  const [emailNoticeArr, setEmailsNoticeArr] = useState([]);
  const [message, setMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const {
    data,
    isFetched,
  } = useGetSupplier();
  const {
    data: emailsData, isFetched: isFetchedEmailsData,
    refetch: refetchEmailsData,
  } = useGetEmailNotice();
  const { mutate: sendEmailNotice } = useSendEmailNotice();
  const { mutate: changeSupplierData } = useChangeSupplierData();
  const [suppliername, setSuppliername] = useState(supplier?.suppliername);
  const [address, setAddress] = useState(supplier?.address);
  const [isChanged, setIsChanged] = useState(false);
  const [cityName, setCityName] = useState(undefined);
  const [image, setImage] = useState('');
  const [isDisabledMailButton, setIsDisabledMailButton] = useState(true);
  const {
    data: cities,
    isFetched: cityFetched,
    refetch,
  } = useGetCities();

  if (isFetched) {
    supplier = data && data?.data;
  }

  useEffect(() => {
    setEmailsNoticeArr(emailsData?.data);
  }, [isFetchedEmailsData]);

  const imageChanged = event => {
    const { files } = event.target;
    setImageUrl(URL.createObjectURL(files[0]));
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = e => {
      setImage(e.target.result);
    };
  };

  const getBase64Image = url => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
    };
    img.src = url;
  };

  useEffect(() => {
    setSuppliername(supplier?.suppliername);
    setAddress(supplier?.address);
    setImageUrl(supplier?.full_logourl);
    setCityName(supplier?.city_name);
    setImage(getBase64Image(supplier?.full_logourl));
    setCity(supplier?.city_id);
  }, [supplier]);

  const handleSubmitEmailNotice = emails => {
    emails.toString('');

    sendEmailNotice(({ emails }), {
      onSuccess: async () => {
        setMessage(t('Notify Mails list successfully updated'));
        refetchEmailsData();
        setIsDisabledMailButton(true);
      },
      onError: async response => {
        setMessage(response.response.data.error);
      },
    });
  };

  const handleEmailNoticeChange = () => {
    const emails = emailNoticeArr;
    emails.push(emailNotice);
    setEmailsNoticeArr(emails);
    setEmailErrorMessage('');
    setEmailsNotice('');
    setIsDisabledMailButton(false);
    handleSubmitEmailNotice(emails);
  };

  const handleEmailNoticeAdd = value => {
    const emailValidError = emailValidation(value);
    const existedEmail = emailNoticeArr.includes(value);
    setEmailsNotice(value);
    if (existedEmail) {
      setEmailErrorMessage(t('This email is already included in the list'));
    } else if (emailValidError && value) {
      setEmailErrorMessage(t('Invalid email address'));
    } else {
      setEmailErrorMessage('');
    }
  };

  const handleSubmitSupplierData = event => {
    event.preventDefault();
    changeSupplierData(({
      suppliername, address, city, image,
    }), {
      onSuccess: async () => {
        setIsChanged(true);
        const selectedCity = cities && cities?.data.find(cityEl => cityEl.id === city);
        setCityName(selectedCity?.name);
      },
      onError: async () => {
        setIsChanged(false);
      },
    });
  };

  return (
    <Grid item xs={12} md={10} lg={6.5} xl={5}>
      <CustomTypography variant="h6" color="blue">{t('Settings')}</CustomTypography>
      <SupplierDataComponent
        supplier={supplier}
        suppliername={suppliername}
        address={address}
        image={imageUrl}
        cityName={cityName}
      />
      <Grid container spacing={1} display="flex" marginTop={1} justifyContent="flex-end">
        <Grid item xs={5} md={3}>
          <SupplierChangePasswordModal />
        </Grid>
        <Grid item xs={5} md={3}>
          <SupplierEditDataModal
            setSuppliername={setSuppliername}
            suppliername={suppliername}
            setCity={setCity}
            city={city}
            imageChanged={imageChanged}
            image={imageUrl}
            setAddress={setAddress}
            address={address}
            handleSubmitSupplierData={handleSubmitSupplierData}
            isChanged={isChanged}
            setCityName={setCityName}
            data={cities}
            isFetched={cityFetched}
            refetch={refetch}
            setIsChanged={setIsChanged}
          />
        </Grid>
        <Grid item xs={5} md={3}>
          <LogoutModal setToken={setToken} />
        </Grid>
      </Grid>
      <MailNotice
        handleChange={handleEmailNoticeChange}
        emailNotice={emailNotice}
        handleSubmitEmailNotice={handleSubmitEmailNotice}
        message={message}
        emailsNoticed={emailNoticeArr}
        emailErrorMessage={emailErrorMessage}
        setEmailsNotice={handleEmailNoticeAdd}
        isDisabledMailButton={isDisabledMailButton}
        refetchEmailsData={refetchEmailsData}
        setMessage={setMessage}
        setEmailsNoticeArr={setEmailsNoticeArr}
      />
    </Grid>
  );
}

Settings.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Settings;
