import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import StyledAddIconButton from '../IconButtons/AddIconButton/StyledAddIconButton';
import ModalComponent from './ModalComponent';

function AddModal({
  children,
  width,
  padding,
  bgcolor,
  titlemargin,
  modaltitle,
  buttonssize,
  open,
  handleOpen,
  handleClose,
  handleChange,
  isChanged,
  disabledSubmitButton,
  message,
}) {
  const [offsetTop, setOffsetTop] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  return (
    <div>
      <StyledAddIconButton
        handleClick={handleOpen}
        ref={(el) => {
          if (!el) return;
          setOffsetTop(el.getBoundingClientRect().top);
          setOffsetLeft(el.getBoundingClientRect().left);
        }}
        width="30px"
        color={open ? '#fff' : '#3384B9'}
        border={open ? 'none' : '1px solid #f6f6f6'}
        boxshadow={open ? 'none' : '-3px 2px 3px 0px #e5e3e3'}
        backgroundcolor={open ? 'linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)' : '#F9F9F9'}
        backgroundcolorhover="linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
        borderhover={open ? 'none' : '1px solid #F56F4B'}
        boxshadowhover="none"
        colorhover="#fff"
        iconsize="23px"
        position={open ? 'relative' : 'inherit'}
        padding="10px"
      />
      <ModalComponent
        open={open}
        handleClose={handleClose}
        offsetTop={offsetTop}
        offsetLeft={offsetLeft}
        width={width || 270}
        padding={padding || '15px 25px 20px'}
        backgroundcolor={bgcolor || '#f8f8f8'}
        titlemargin={titlemargin || '0 10px 10px'}
        buttonssize={buttonssize}
        isaddmodal
        color="secondary"
        title={modaltitle}
        handleChange={handleChange}
        isChanged={isChanged}
        disabledSubmitButton={disabledSubmitButton}
        message={message}
      >
        <Grid container spacing={1.1}>
          {children}
        </Grid>
      </ModalComponent>
    </div>
  );
}

AddModal.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  padding: PropTypes.string,
  bgcolor: PropTypes.string,
  titlemargin: PropTypes.string,
  modaltitle: PropTypes.string,
  buttonssize: PropTypes.number,
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  handleChange: PropTypes.func,
  isChanged: PropTypes.bool,
  disabledSubmitButton: PropTypes.bool,
  message: PropTypes.string,
};

AddModal.defaultProps = {
  width: 350,
  padding: '',
  bgcolor: '#fff',
  titlemargin: '',
  modaltitle: '',
  buttonssize: 2,
  open: false,
  isChanged: false,
  disabledSubmitButton: false,
  message: '',
};

export default AddModal;
