import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)((props) => ({
  margin: props.margin,
  padding: props.padding,
  color: props.fontcolor,
  borderRadius: props.borderradius,
  fontSize: props.textsize,
  fontWeight: props.textweight,
  backgroundColor: props.bgcolor,
  '&:hover': {
    backgroundColor: props.bgcolor,
  },
}));

function ButtonCancel({
  type,
  margin,
  padding,
  color,
  borderradius,
  textsize,
  textweight,
  bgcolor,
  variant,
  handleClose,
  text,
}) {
  return (
    <CustomButton
      type={type}
      fullWidth
      margin={margin}
      padding={padding}
      fontcolor={color}
      borderradius={borderradius}
      textsize={textsize}
      textweight={textweight}
      bgcolor={bgcolor}
      variant={variant}
      onClick={handleClose}
    >
      {text}
    </CustomButton>
  );
}

ButtonCancel.propTypes = {
  type: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string,
  borderradius: PropTypes.number,
  textsize: PropTypes.number,
  textweight: PropTypes.number,
  bgcolor: PropTypes.string,
  variant: PropTypes.string,
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

ButtonCancel.defaultProps = {
  type: 'submit',
  margin: '0',
  padding: '0',
  color: '#ccc',
  borderradius: 20,
  textsize: 14,
  textweight: 400,
  bgcolor: '#fff',
  variant: 'contained',
};

export default ButtonCancel;
