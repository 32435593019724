/* eslint-disable no-useless-escape */
const pibValidation = pib => {
  let errorMessage = false;

  if (pib.length === 9) {
    errorMessage = false;
  } else {
    errorMessage = true;
  }
  return errorMessage;
};

export default pibValidation;
