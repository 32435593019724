import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styled, { keyframes } from 'styled-components';
import InputField from '../InputFields/InputField';

const slidein = keyframes`
 from { width: 52px; padding: 5px 0; }
 to { width: 90%; padding: 5px 17px 5px 22px; }
`;

const slideout = keyframes`
 from { width: 90%; padding: 5px 17px 5px 22px; }
 to { width: 55px; padding: 5px 0; }
`;

const Search = styled.div`
    position: relative;
    display: ${props => props.display};
    flex-direction: ${props => props.direction};
    align-items: center;
    justify-content: ${props => props.justifycontent};
    border-radius: ${props => props.borderradius};
    background-color: ${props => props.backgroundcolor};
    border: ${props => props.border};
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    width: 90%;
    &:hover {
        background-color: ${props => props.backgroundcolorhover};
    }
    @media (max-width: 950px) {
      width: ${props => (!props.expandSearch ? props.mobileWidth : '90%')};
      justify-content: center;
      &.slidein {
        padding: ${props => (props.expandSearch ? props.padding : '0')};
        animation: ${slidein} 0.5s forwards;
        -webkit-animation: ${slidein} 0.5s forwards;
      }
       &.slideout {
        padding: ${props => (props.expandSearch ? props.padding : '5px 17px')};
        animation: ${slideout} 0.5s forwards;
        -webkit-animation: ${slideout} 0.5s forwards;
        & .MuiGrid-item {
          display: ${props => (props.expandSearch ? 'flex' : 'none')};
        }
      }
    }
`;

const SearchIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${props => props.margin};
`;

function SearchBar({
  borderradius,
  backgroundcolor,
  backgroundcolorhover,
  border,
  padding,
  display,
  direction,
  justifycontent,
  name,
  grid,
  placeholder,
  placeholdercolor,
  placeholderfontsize,
  marginicon,
  margin,
  searchedTerm,
  setSearchedTerm,
  mobileWidth,
}) {
  const [expandSearch, setExpandSearch] = useState(false);
  const classname = expandSearch ? 'slidein' : 'slideout';
  return (
    <Search
      borderradius={borderradius}
      backgroundcolor={backgroundcolor}
      border={border}
      padding={padding}
      display={display}
      direction={direction}
      justifycontent={justifycontent}
      margin={margin}
      backgroundcolorhover={backgroundcolorhover}
      expandSearch={expandSearch}
      mobileWidth={mobileWidth}
      className={`${mobileWidth !== '90%' ? classname : ''}`}
    >
      <InputField
        name={name}
        grid={grid}
        type="text"
        defaultValue={searchedTerm}
        placeholder={placeholder}
        placeholdercolor={placeholdercolor}
        placeholderfontsize={placeholderfontsize}
        endAdornment={false}
        handleChange={setSearchedTerm}
      />
      <SearchIconWrapper
        margin={marginicon}
      >
        {!searchedTerm
          && <IconButton onClick={() => setExpandSearch(!expandSearch)}><SearchIcon sx={{ color: '#b7b7b7' }} /></IconButton>}
        {searchedTerm
          && <IconButton onClick={() => { setSearchedTerm(''); }}><HighlightOffIcon sx={{ color: '#b7b7b7' }} /></IconButton>}
      </SearchIconWrapper>
    </Search>
  );
}

SearchBar.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.string,
  backgroundcolor: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  border: PropTypes.string,
  placeholdercolor: PropTypes.string,
  placeholderfontsize: PropTypes.number,
  display: PropTypes.string,
  name: PropTypes.string,
  direction: PropTypes.string,
  placeholder: PropTypes.string,
  justifycontent: PropTypes.string,
  marginicon: PropTypes.string,
  margin: PropTypes.string,
  searchedTerm: PropTypes.string,
  setSearchedTerm: PropTypes.func.isRequired,
  mobileWidth: PropTypes.string,
};

SearchBar.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: '',
  backgroundcolor: '#fff',
  backgroundcolorhover: '#fff',
  border: '',
  placeholdercolor: '#ccc',
  placeholderfontsize: 14,
  display: 'flex',
  name: '',
  direction: '',
  placeholder: '',
  justifycontent: 'center',
  marginicon: '',
  margin: '',
  searchedTerm: '',
  mobileWidth: '55px',
};

export default SearchBar;
