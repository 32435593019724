/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import styled from 'styled-components';
import { styled as mstyled } from '@mui/material/styles';
import DeleteReminderModal from '../Modals/Reminder/DeleteReminderModal';
import EditReminderModal from '../Modals/Reminder/EditReminderModal';
import SearchBar from '../Forms/SearchBar/SearchBar';

const CustomWrapperBox = styled.div`
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: -1px 3px 5px 1px #e5e3e3;
    clear: both;
`;

const CustomLabel = mstyled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  letterSpacing: 0.5,
  color: theme.palette.blue.main,
  textAlign: 'center',
}));

const CustomIconBox = mstyled(Box)(() => ({
  backgroundColor: '#fff',
  width: 40,
  height: 40,
  borderRadius: '50%',
  boxShadow: '-1px 1px 4px 0px #d3d3d3',
  border: '1px solid #EAEAEA',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: -20,
  left: -20,
}));

const CustomReminderBox = mstyled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: '1px solid #f6f6f6',
  borderBottom: '1px solid #f6f6f6',
}));

const CustomCheckbox = mstyled(Checkbox)(({ theme }) => ({
  color: theme.palette.blue.main,
  '&.Mui-checked': {
    color: theme.palette.blue.main,
  },
}));

function AllRemindersComponent({ client, refetch }) {
  const { t } = useTranslation();
  const [searchedTerm, setSearchedTerm] = useState('');
  const { reminders } = client;
  const [selectAll, setSelectAll] = useState(false);
  const [selectedReminders, setSelectedReminders] = useState([]);
  const [remindersArray, setRemindersArray] = useState([]);
  const reminderValue = [
    { name: '0', label: t('None') },
    { name: '1', label: t('No repeat') },
    { name: '2', label: t('Daily') },
    { name: '3', label: t('Weekly') },
    { name: '4', label: t('Monthly') },
  ];

  const onChangeSelectedReminders = (name, value) => {
    let remindersIdArray = selectedReminders;

    if (name === 'selectAllReminders') {
      setSelectAll(!selectAll);
      remindersIdArray = !value ? [] : reminders && reminders.map(reminder => reminder.id);
      setSelectedReminders(remindersIdArray);
    } else if (selectedReminders.includes(value)) {
      setSelectedReminders(selectedReminders.filter(reminder => reminder !== value));
      if (selectedReminders.length === 1) {
        setSelectAll(false);
      }
    } else {
      remindersIdArray.push(value);
      setSelectedReminders([...remindersIdArray]);
    }
  };

  const handleChangeRemindersData = remindersArrData => {
    const remindersArr = remindersArrData && remindersArrData?.map(reminder => (
      <CustomReminderBox key={reminder.id}>
        <Box display="flex">
          <CustomCheckbox size="small" checked={selectedReminders.includes(reminder.id)} onChange={() => onChangeSelectedReminders('selectOne', reminder.id)} />
          <Box display="flex" flexDirection="column" marginLeft={2}>
            <Typography sx={{ color: '#46688a', fontSize: '14px', fontWeight: 500 }}>
              {reminder.remindername}
            </Typography>
            <Typography sx={{ color: '#46688a', fontSize: '12px', fontWeight: 500 }}>
              {reminderValue[reminder.reminderrepeat].label}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <EditReminderModal
            backgroundcolor="transparent"
            backgroundcolorhover="transparent"
            backgroundcoloropen="linear-gradient(90deg, rgba(63,116,166,1) 0%, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
            border="none"
            boxshadow="none"
            color="#3384B9"
            coloropen="#fff"
            reminder={reminder}
            clientId={client.id}
            clientReminder
            refetchReminders={refetch}
          />
        </Box>
      </CustomReminderBox>
    ));
    setRemindersArray(remindersArr);
  };

  useEffect(() => {
    const searchedReminders = reminders?.filter(
      reminder => reminder.remindername.toLowerCase().includes(searchedTerm.toLowerCase()),
    );
    handleChangeRemindersData(searchedReminders);
  }, [searchedTerm, selectedReminders, reminders]);

  return (
    <CustomWrapperBox>
      <CustomIconBox>
        <NotificationsNoneIcon color="blue" sx={{ fontSize: 24 }} />
      </CustomIconBox>
      <CustomLabel>
        {t('Order reminders')}
      </CustomLabel>
      <Box display="flex" flexDirection={remindersArray?.length === 0 ? 'column' : 'row'} alignItems="center">
        {remindersArray?.length !== 0
          && (
            <CustomCheckbox
              size="small"
              checked={selectAll}
              onChange={e => onChangeSelectedReminders('selectAllReminders', e.target.checked)}
            />
          )}
        <SearchBar
          name="search-orders"
          placeholder={t('Search reminders list')}
          borderradius="30px"
          padding="0 16px 0 20px"
          margin="15px 0 15px"
          backgroundcolor="#fff"
          border="1px solid #ded9d9"
          display="flex"
          direction="row"
          justifycontent="space-between"
          setSearchedTerm={setSearchedTerm}
          searchedTerm={searchedTerm}
        />
        {!remindersArray?.length
          ? (
            <Typography width="100%" color="secondary" fontSize={14} marginTop="15px" textAlign="center">
              {t('No reminders')}
            </Typography>
          ) : (
            <DeleteReminderModal
              refetch={refetch}
              id={selectedReminders}
            />
          )}
      </Box>
      {remindersArray}
    </CustomWrapperBox>
  );
}

AllRemindersComponent.propTypes = {
  client: PropTypes.shape({}),
  refetch: PropTypes.func,
};

AllRemindersComponent.defaultProps = {
  client: {},
};

export default AllRemindersComponent;
