/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import ButtonConfirm from '../Buttons/ButtonConfirm';
import SelectField from '../SelectFields/SelectField';

const AssignClientForm = props => {
  const { t } = useTranslation();
  const {
    data, isFetched, handleChange, defaultValue, handleAddUser, disabledAddNewUserButton,
  } = props;
  return (
    <Grid container spacing={1} display="flex" alignItems="center" padding="28px">
      <Grid item xs={10}>
        <SelectField
          name="Delivery address"
          grid={12}
          type="text"
          placeholder={t('Delivery address')}
          label={t('Delivery address')}
          borderradius={10}
          placeholdercolor="#ded9d9"
          border="1px solid #ded9d9"
          data={data?.data?.addresses}
          isFetched={isFetched}
          handleChange={handleChange}
          defaultValue={defaultValue}
          isAddresses
          isClientForm
        />

      </Grid>
      <Grid item xs={2} marginTop="10px">
        <ButtonConfirm
          type="submit"
          color="#fff"
          margin="0"
          padding="2px 0"
          borderradius={20}
          textsize={13}
          textweight={500}
          bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
          variant="contained"
          text={t('Add')}
          handleChange={handleAddUser}
          disabled={disabledAddNewUserButton}
        />
      </Grid>
    </Grid>
  );
};

AssignClientForm.propTypes = {
  isFetched: PropTypes.bool,
  defaultValue: PropTypes.string,
  handleChange: PropTypes.func,
  disabledAddNewUserButton: PropTypes.bool,
};

AssignClientForm.defaultProps = {
  isFetched: false,
  disabledAddNewUserButton: true,
};

export default AssignClientForm;
