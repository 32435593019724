import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputField from '../InputField';

function ItemNameInputField({
  grid,
  defaultValue,
  borderradius,
  border,
  padding,
  bgcolor,
  fontsize,
  handleChange,
}) {
  const { t } = useTranslation();
  return (
    <InputField
      name="item-name"
      type="text"
      grid={grid}
      defaultValue={defaultValue}
      placeholder={t('Item name')}
      borderradius={borderradius}
      border={border}
      padding={padding}
      bgcolor={bgcolor}
      fontsize={fontsize}
      endAdornment={false}
      label={t('Item name')}
      handleChange={handleChange}
    />
  );
}

ItemNameInputField.propTypes = {
  grid: PropTypes.number,
  padding: PropTypes.string,
  borderradius: PropTypes.number,
  fontsize: PropTypes.number,
  bgcolor: PropTypes.string,
  border: PropTypes.string,
  defaultValue: PropTypes.string,
  handleChange: PropTypes.func,
};

ItemNameInputField.defaultProps = {
  grid: 12,
  padding: '',
  borderradius: 0,
  fontsize: 12,
  bgcolor: '#fff',
  border: '',
  defaultValue: '',
};

export default ItemNameInputField;
