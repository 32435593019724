/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)((props) => ({
  margin: props.margin,
  padding: props.padding,
  color: props.fontcolor,
  borderRadius: props.borderradius,
  fontSize: props.textsize,
  fontWeight: props.textweight,
  background: props.bgcolor,
  textTransform: props.texttransform,
  boxShadow: props.boxshadow,
}));

const SupplierChangePasswordButton = (props) => {
  const { t } = useTranslation();
  return (
    <CustomButton
      aria-label="change-password"
      size="small"
      onClick={props.handleClick}
      fullWidth
      texttransform={props.texttransform}
      margin={props.margin}
      padding={props.padding}
      fontcolor={props.color}
      borderradius={props.borderradius}
      textsize={props.textsize}
      textweight={props.textweight}
      boxshadow={props.boxshadow}
      bgcolor={props.bgcolor}
    >
      {t('Change password')}
    </CustomButton>
  );
};

export default SupplierChangePasswordButton;
