import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { styled } from '@mui/material/styles';
import RefreshIconButton from '../IconButtons/RefreshIconButton';

const CustomTextField = styled(TextField, { shouldForwardProp: prop => prop !== 'expandsearch' })(({ theme, expandsearch }) => ({
  width: '90%',
  display: 'flex',
  marginRight: '15px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '40px',
    border: '1px solid #ded9d9',
    paddingLeft: '20px',
    '& input': {
      fontSize: 12,
      color: '#9c9c9c',
      '&::placeholder': {
        color: '#9c9c9c',
        opacity: 1,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '54px',
    '& .MuiOutlinedInput-root': {
      paddingLeft: '19px',
    },
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(expandsearch && {
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: '270px',
    }),
  },
}));

function OrderCalendar({
  backgroundcolor,
  backgroundcolorhover,
  border,
  borderhover,
  date,
  setDate,
}) {
  const { t } = useTranslation();
  const [expandSearch, setExpandSearch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dialogProps = {
    sx: {
      '& .MuiPaper-root': {
        boxShadow: 'none',
        border: '1px solid #ded9d9',
        borderRadius: '10px',
        '& .Mui-selected': {
          backgroundColor: '#F56F4B',
          color: '#fff',
          '&:focus': {
            backgroundColor: '#F56F4B',
            color: '#fff',
          },
        },
        '& .MuiPickersDay-today': {
          color: '#F56F4B',
          backgroundColor: '#fff',
          border: 'none',
          '&:focus': {
            color: '#F56F4B',
            backgroundColor: '#fff',
          },
        },
        '& .PrivatePickersSlideTransition-root': {
          minHeight: '200px',
        },
        '& .MuiSvgIcon-root': {
          fill: '#3384B9',
        },
        '& .css-1dozdou div:nth-of-type(2)': {
          width: '100%',
          justifyContent: 'space-between',
        },
        '& div[role=presentation]': {
          position: 'absolute',
          top: '35px',
          left: '50%',
          color: '#3384B9',
          transform: 'translate(-50%, -50%)',
          '& .MuiButtonBase-root': {
            display: 'none',
          },
        },
      },
    },
  };

  const handleClick = () => {
    setDate(null);
  };

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          renderInput={params => (
            <CustomTextField
              expandsearch={expandSearch}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: t('Select a date'),
              }}
            />
          )}
          inputVariant="outlined"
          variant="dialog"
          InputAdornmentProps={{ position: 'start', sx: { marginRight: 0, '& svg': { fill: '#3384B9' } } }}
          inputFormat="d MMM y"
          mask=""
          PopperProps={dialogProps}
          disableFuture
          value={date}
          disableHighlightToday={false}
          KeyboardButtonProps={{
            onFocus: () => {
              setIsOpen(true);
            },
          }}
          PopoverProps={{
            disableRestoreFocus: true,
          }}
          InputProps={{
            onClick: () => {
              setIsOpen(true);
            },
          }}
          onOpen={() => setExpandSearch(true)}
          onClose={() => setExpandSearch(false)}
          onChange={newValue => {
            setDate(newValue);
            setIsOpen(false);
          }}
          open={isOpen}
        />
      </LocalizationProvider>
      <RefreshIconButton
        handleClick={handleClick}
        width={53}
        height={53}
        padding="10px"
        color="#fff"
        border={border}
        boxshadow="none"
        backgroundcolor={backgroundcolor}
        backgroundcolorhover={backgroundcolorhover}
        borderhover={borderhover}
        boxshadowhover="none"
        colorhover="#fff"
        iconsize={23}
      />
    </Box>
  );
}

OrderCalendar.propTypes = {
  backgroundcolor: PropTypes.string,
  backgroundcolorhover: PropTypes.string,
  border: PropTypes.string,
  borderhover: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  setDate: PropTypes.func,
  setIsDateChanged: PropTypes.func,
};

OrderCalendar.defaultProps = {
  backgroundcolor: '#3384B9',
  backgroundcolorhover: '#3384B9',
  border: '1px solid #3384B9',
  borderhover: '1px solid #3384B9',
  date: '',
};

export default OrderCalendar;
