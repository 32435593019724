/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import theme from './theme';
import MainLayout from './Layout/MainLayout';
import Register from './Pages/Register';
import Login from './Pages/Login';
import ItemsList from './Pages/ItemsList';
import Support from './Pages/Support';
import { NotifyUsers } from './Pages/NotifyUsers';
import Settings from './Pages/Settings';
import OrderReminders from './Pages/OrderReminders';
import { Users } from './Pages/Users';
import Returns from './Pages/Returns';
import Orders from './Pages/Orders';
import { Clients } from './Pages/Clients';

const AppRoot = styled.div`
  font-family: "Roboto", sans-serif;
  font-family: "Roboto Mono", monospace;
  min-height: 100%;
  user-select: none;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background: #fff;
    margin: 0;
    height: 100%;
    min-height: 100%;
    user-select: none;
    caret-color: transparent;
  }
  html, #root {
    height: 100%;
    min-height: 100%;
    user-select: none;
    caret-color: transparent;
  }
  textarea {
    caret-color: grey;
  }
  .button-file {
    cursor: pointer;
  }
`;

function App({ store, persistor, history }) {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [match, setMatch] = useState(true);
  const [language, setLanguage] = useState('Srb');
  const [isSeenOrder, setIsSeenOrder] = useState(false);

  const { pathname } = window.location;

  useEffect(() => {
    if (!token && (pathname !== '/login' && pathname !== '/register')) {
      setIsSeenOrder(false);
      setMatch(false);
    }
    if (pathname === '/login' || pathname === '/register') {
      const lng = localStorage.getItem('lang') === 'sr' ? 'Eng' : 'Srb';
      setLanguage(lng);
    }
  }, [pathname]);

  const UnauthorizedRoutes = (
    <>
      <Route name="Login" path="/login" render={() => <Login setToken={setToken} setLanguage={setLanguage} language={language} />} />
      <Route name="Register" path="/register" render={() => <Register setLanguage={setLanguage} language={language} />} />
      {!match
        && <Redirect name="Login" to="/login" component={Login} />}
    </>
  );

  const AuthorizedRoutes = (
    <MainLayout isSeenOrder={isSeenOrder}>
      <Route name="ItemsList" path="/items" component={ItemsList} />
      <Route name="Support" path="/support" component={Support} />
      <Route name="NotifyUsers" path="/notification" component={NotifyUsers} />
      <Route name="Settings" path="/settings" render={() => <Settings setToken={setToken} />} />
      <Route name="OrderReminders" path="/reminder" component={OrderReminders} />
      <Route name="Users" path="/users" component={Users} />
      <Route name="Returns" path="/returns" render={() => <Returns setIsSeenOrder={setIsSeenOrder} />} />
      <Route path="(/|/login|/register)">
        <Redirect name="Orders" to="/orders" render={() => <Orders setIsSeenOrder={setIsSeenOrder} />} />
      </Route>
      <Route name="Orders" path="/orders" render={() => <Orders setIsSeenOrder={setIsSeenOrder} />} />
      <Route name="Clients" path="/clients" component={Clients} />
    </MainLayout>
  );

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <AppRoot>
            <GlobalStyle />
            <ConnectedRouter history={history}>
              <Switch>
                {token ? AuthorizedRoutes : UnauthorizedRoutes }
              </Switch>
            </ConnectedRouter>
          </AppRoot>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
