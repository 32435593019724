import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@mui/material';
import PaginationItem from '@mui/material/PaginationItem';

function PaginationComponent({
  count,
  handleChangePage,
  pageNum,
}) {
  return (
    <Pagination
      count={count}
      size="medium"
      page={pageNum}
      variant="outlined"
      shape="rounded"
      onChange={(e, value) => handleChangePage(value)}
      renderItem={item => <PaginationItem {...item} />}
    />
  );
}

PaginationComponent.propTypes = {
  count: PropTypes.number,
  handleChangePage: PropTypes.func.isRequired,
  pageNum: PropTypes.number,
};

PaginationComponent.defaultProps = {
  count: 0,
};

export default PaginationComponent;
