/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useForgotPassword from '../../Hooks/useForgotPassword';
import emailValidation from '../../Validations/email-validation';
import EmailInputField from '../Forms/InputFields/RegisterFields/EmailInputField';
import ButtonCancel from '../Forms/Buttons/ButtonCancel';
import ButtonConfirm from '../Forms/Buttons/ButtonConfirm';

const CustomModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#F8F8F8',
  borderRadius: 6,
  boxShadow: 24,
  padding: '35px 25px',
  [theme.breakpoints.down('md')]: {
    width: '70%',
  },
}));

const CustomIconBox = styled(Box)(() => ({
  backgroundColor: '#F8F8F8',
  width: 58,
  height: 58,
  borderRadius: '50%',
  boxShadow: '-7px 3px 15px -6px #302f2f',
  border: '1px solid #EAEAEA',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: -25,
  left: -25,
}));

const CustomTypography = styled(Typography)(() => ({
  color: '#fff',
  fontSize: '13px',
  fontWeight: 600,
  backgroundColor: '#f56f4ba6',
  borderRadius: '10px',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: '#F56F4B',
  padding: '5px 10px',
  marginBottom: '10px',
}));

function PasswordReset({ setPasswordResetMessage }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  const { mutate: forgotPassword } = useForgotPassword();
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const emailError = emailValidation(email);
    if (!emailError) {
      setEmailErrorMessage('');
      return forgotPassword(({ email }), {
        onSuccess: async (data) => {
          setOpen(false);
          setPasswordResetMessage(data.data.message);
        },
        onError: async (data) => {
          setMessage(data.response.data.error);
        },
      });
    }
    return setEmailErrorMessage(t('Invalid email address'));
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: 14,
          textTransform: 'none',
        }}
      >
        <NotificationsNoneIcon color="primary" />
        {' '}
        {t('Forgot Password')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomModalBox
          component="form"
          noValidate
        >
          <CustomIconBox>
            <NotificationsNoneIcon color="secondary" sx={{ fontSize: 28 }} />
          </CustomIconBox>
          { message
            && <CustomTypography>{message}</CustomTypography>}
          <EmailInputField
            grid={12}
            bgcolor="#fff"
            borderradius={20}
            border="1px solid #ded9d9"
            padding="5px 15px"
            placeholdercolor="#747474"
            handleChange={setEmail}
            email={email}
            errorMessage={emailErrorMessage}
          />
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: 'flex-end', mt: 1 }}
          >
            <Grid item xs={6} md={4}>
              <ButtonCancel
                text={t('Cancel')}
                color="#747474"
                borderradius={16}
                textsize={12}
                bgcolor="#fff"
                padding="5px 0"
                variant="contained"
                handleClose={handleClose}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <ButtonConfirm
                text={t('Confirm')}
                disabled={!email}
                type="submit"
                color="#fff"
                borderradius={16}
                textsize={12}
                bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
                padding="5px 0"
                variant="contained"
                handleChange={handleSubmit}
              />
            </Grid>
          </Grid>
        </CustomModalBox>
      </Modal>
    </div>
  );
}

PasswordReset.propTypes = {
  setPasswordResetMessage: PropTypes.func,
};

export default PasswordReset;
