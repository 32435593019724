import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import emailValidation from '../Validations/email-validation';
import usePostUser from '../Hooks/users/usePostUser';
import useGetUsers from '../Hooks/users/useGetUsers';
import AddNewUser from '../Components/Forms/AddNewUser';
import UserComponent from '../Components/Users/UserComponent';

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.blue.main,
  clear: 'both',
  margin: '10px 0',
}));

function Users() {
  const { t } = useTranslation();
  const {
    data,
    isFetched,
  } = useGetUsers();
  const { mutate: postUser } = usePostUser();
  const [username, setUsername] = useState('');
  const [fullname, setFullname] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [disabledAddNewUserButton, setDisabledAddNewUserButton] = useState(true);
  const [supplierLogo, setSupplierLogo] = useState('');

  useEffect(() => {
    const usersArr = data && data?.data && data.data.users.reverse();
    setUsers(usersArr);
    const supplierLogoSrc = data && data?.data && data.data.users[0].logourl;
    setSupplierLogo(supplierLogoSrc);
  }, [isFetched]);

  useEffect(() => {
    if (username && fullname && !emailErrorMessage) {
      setDisabledAddNewUserButton(false);
    } else {
      setDisabledAddNewUserButton(true);
    }
  }, [username, fullname, emailErrorMessage]);

  const handleChangeUsername = value => {
    const emailError = emailValidation(value);
    if (emailError) {
      setEmailErrorMessage(t('Invalid email address'));
    } else {
      setEmailErrorMessage('');
    }
    setUsername(value);
  };

  const handleAddUser = event => {
    event.preventDefault();
    return postUser(({ username, fullname }), {
      onSuccess: async response => {
        setMessage(t('User added'));
        setUsername('');
        setEmailErrorMessage('');
        setFullname('');
        setIsError(false);
        const user = {
          username: response.data.username,
          fullname: response.data.fullname,
          id: response.data.id,
          deliveryaddresses: [],
          logourl: supplierLogo,
        };
        users.unshift(user);
      },
      onError: async () => {
        setMessage(t('Supplier User with this email already exists'));
        setIsError(true);
      },
    });
  };

  return (
    <Grid item xs={12} md={10} lg={7} xl={5.5}>
      <CustomTypography variant="h6" fontWeight={600} color="blue">{t('Users')}</CustomTypography>
      <CustomTypography fontSize={14} fontWeight={400} color="blue">{t('Added users can only see orders and refunds at the addresses assigned to them')}</CustomTypography>
      <CustomTypography fontSize={14} fontWeight={400} color="blue">
        {'{ * } '}
        {t('After creating the code will be sent to the e-mail address')}
      </CustomTypography>
      <AddNewUser
        message={message}
        setUsername={handleChangeUsername}
        setFullname={setFullname}
        username={username}
        fullname={fullname}
        isError={isError}
        emailErrorMessage={emailErrorMessage}
        handleChange={handleAddUser}
        disabledAddNewUserButton={disabledAddNewUserButton}
      />
      {users && users.map(user => (
        <UserComponent
          key={user.id}
          id={user.id}
          username={user.username}
          fullname={user.fullname}
          deliveryaddresses={user.deliveryaddresses}
          logourl={supplierLogo}
          users={users}
          setUsers={setUsers}
        />
      ))}
    </Grid>
  );
}

const mapDispatchToProps = () => ({
});

const mapStateToProps = (state, ownProps = {}) => ({
  ...ownProps,
});

export { Users };
export default connect(mapStateToProps, mapDispatchToProps)(Users);
