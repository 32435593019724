/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled as mstyled } from '@mui/material/styles';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Header from '../Components/Partials/Header/Header';
import Sidebar from '../Components/Partials/Sidebar';

const PageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #fff;
`;

const Main = mstyled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    padding: '30px',
    transition: theme.transitions.create('margin', {
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 20,
    }),
  }),
);

function MainLayout({ children, isSeenOrder }) {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const gridDrawerOpened = {
    sm: { sidebar: 4, main: 8 },
    md: { sidebar: 3, main: 9 },
    lg: { sidebar: 2.5, main: 9.5 },
    xl: { sidebar: 2, main: 10 },
  };
  const gridDrawerClosed = {
    sm: { sidebar: 2, main: 10 },
    md: { sidebar: 1, main: 11 },
    lg: { sidebar: 1, main: 11 },
    xl: { sidebar: 1, main: 11 },
  };

  const [gridSize, setGridSize] = useState(gridDrawerOpened);

  const isReturns = location.pathname === '/returns';

  const sidebarColor = isReturns ? 'linear-gradient(180deg, #f28e26 0%, #fc664d 100%)' : 'linear-gradient(180deg,rgba(63, 116, 166, 1) 0%, rgba(63, 116, 166, 1) 0%, rgba(49, 167, 203, 1) 100%)';

  const linkColor = isReturns ? '#F56F4B' : '#3384B9';

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
    if (!open) {
      setGridSize(gridDrawerClosed);
    } else {
      setGridSize(gridDrawerOpened);
    }
  };

  return (
    <PageWrapper open={open}>
      <Grid container>
        <Grid
          item
          xs={gridSize.sm.sidebar}
          md={gridSize.md.sidebar}
          lg={gridSize.lg.sidebar}
          xl={gridSize.xl.sidebar}
        >
          <Sidebar
            open={open}
            setOpen={setOpen}
            toggleDrawer={toggleDrawer}
            sidebarColor={sidebarColor}
            linkColor={linkColor}
          />
        </Grid>
        <Grid
          item
          xs={gridSize.sm.main}
          md={gridSize.md.main}
          lg={gridSize.lg.main}
          xl={gridSize.xl.main}
        >
          <Main open={open}>
            <Header isSeenOrder={isSeenOrder} />
            {children}
          </Main>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isSeenOrder: PropTypes.bool,
};

export default MainLayout;
