/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { styled as mstyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useUpdateListDocument from '../../Hooks/items/useUpdateListDocument';
// import HiddenInput from '../Forms/InputFields/HiddenInputField';
import ButtonConfirm from '../Forms/Buttons/ButtonConfirm';
import ButtonCancel from '../Forms/Buttons/ButtonCancel';
import AddItemModal from '../Modals/Items/AddItemModal';
import ChooseFile from '../Forms/Buttons/ChooseFile';

const ComponentWrapper = styled.div`
    border: 1px solid #ded9d9;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-width: 1px;
    padding: 25px 25px 15px 0;
    width: 45vw;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const CustomTypography = mstyled(Typography)(props => ({
  fontSize: props.fontSize,
  color: props.color,
  fontWeight: props.fontWeight,
  margin: props.margin,

}));

const CustomBox = mstyled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '30px 0 0px',
  border: '1px solid #ded9d9',
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: 'none',
  padding: '20px 0 0',
}));

function UpdateLists(props) {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [message, setMessage] = useState();
  const { mutate: updateListDocument } = useUpdateListDocument();

  const handleClose = () => {
    setFile();
  };

  const handleInputChange = event => {
    event.preventDefault();
    setFile(event.target.files[0]);
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      return updateListDocument(({ formData }), {
        onSuccess: async () => {
          setMessage(t('Products updated'));
        },
        onError: async () => {
        },
      });
    }
    return message;
  };
  const downloadExampleCsv = () => {
    fetch('example.csv').then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'example.csv';
        alink.click();
      });
    });
  };

  return (
    <ComponentWrapper>
      <CustomTypography fontSize={15} color="#3384B9" fontWeight={600}>{t('Upload document title')}</CustomTypography>
      <CustomTypography fontSize={12} color="#7f7f7f" fontWeight={400} margin="10px 0 10px">{t('Upload document text')}</CustomTypography>
      <Button variant="text" color="secondary" sx={{ textTransform: 'initial', textDecoration: 'underline', '&:hover': { textDecoration: 'underline' } }} onClick={downloadExampleCsv}>
        Example.csv
      </Button>
      <Grid container spacing={1} marginTop={0.4}>
        <Grid item xs={12} md={4}>
          <ChooseFile
            texttypography={file?.name || t('No file chosen')}
            textinput={t('Choose file')}
            setFile={handleInputChange}
            accept=".csv"
          />
        </Grid>
        <Grid item xs={4} md={1.3}>
          <ButtonCancel
            text={t('Cancel')}
            color="#747474"
            margin="0"
            padding="5px 0"
            borderradius={16}
            textsize={10}
            bgcolor="#fff"
            variant="contained"
            handleClose={handleClose}
          />
        </Grid>
        <Grid item xs={4} md={1.3}>
          <ButtonConfirm
            text={t('Send')}
            type="submit"
            color="#fff"
            margin="0"
            padding="5px 0"
            borderradius={16}
            textsize={10}
            bgcolor="linear-gradient(90deg, rgba(245,110,76,1) 0%, rgba(241,135,48,1) 100%)"
            variant="contained"
            handleChange={handleOnSubmit}
          />
        </Grid>
        {message
          && (
          <Grid item xs={12}>
            <CustomTypography fontSize={15} color="secondary" fontWeight={600} margin="0 0 0 10px">{message}</CustomTypography>
          </Grid>
          )}
      </Grid>
      <CustomBox>
        <AddItemModal refetch={props.refetch} />
        <CustomTypography fontSize={15} color="#3384B9" fontWeight={600} margin="0 0 0 10px">{t('Add new article')}</CustomTypography>
      </CustomBox>
      <CustomTypography fontSize={12} color="#7f7f7f" fontWeight={400} margin="10px 0 20px" padding="0 0 30px 0" sx={{ borderBottom: '1px solid #ded9d9' }}>{t('Add new item text')}</CustomTypography>
    </ComponentWrapper>
  );
}

export default UpdateLists;
